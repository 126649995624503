import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Typography,
  Button,
  List,
  Alert,
  Statistic,
  Space,
  Tag,
  Progress,
  Spin,
} from "antd";
import { RootState } from "../../services/store";
import {
  TrophyOutlined,
  FieldTimeOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setMessage, setAuctionState } from "../../services/slices/auctionSlice";
import { fetchAuctionState } from "../../helpers/api";
import { BidLimitDetails } from "../../types";
import styles from "./Auction.module.css";


const { Countdown } = Statistic;
const { Title, Text } = Typography;


const Auction: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { highestBid, bids, remainingTime, message, isActive } =
    useSelector((state: RootState) => state.auction);
  const userData = useSelector((state: RootState) => state.user.userData);
  const userId = userData?.id;
  const availableTokens = userData?.web_tokens || 0;
  const [showAllBids, setShowAllBids] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [pool, setPool] = useState<number>(0);
  const visibleBids = showAllBids ? bids : bids.slice(0, 5);
  const [timerValue, setTimerValue] = useState<number | null>(null);
  const lastBid = bids[0];
  const isLastBidder = lastBid && lastBid.user_id === userId;
  const nextBid = highestBid + 1000;
  const [bidLimitDetails, setBidLimitDetails] = useState<BidLimitDetails | null>(null);

  useEffect(() => {
    const fetchInitialState = async () => {
      try {
        const state = await fetchAuctionState();
        if (state.isActive) {
          dispatch(setAuctionState(state.auction));
          setTimerValue(new Date(state.auction.endTime).getTime());
          setPool(state.auction.bonus_pool);
        }
      } catch (error) {
        console.error('Error fetching auction state:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialState();
  }, [dispatch]);

  useEffect(() => {
    if (remainingTime > 0) {
      setTimerValue(remainingTime);
    }
  }, [remainingTime]);

  const showTimer = timerValue !== null && timerValue > Date.now();

  const placeBid = () => {
    if (!userId) return;

    if (nextBid > availableTokens) {
      dispatch(setMessage("insufficient_funds"));
      return;
    }

    if (isLastBidder) {
      dispatch(setMessage("consecutive_bids_not_allowed"));
      return;
    }

    dispatch({
      type: "websocket/send",
      payload: JSON.stringify({
        type: "bid",
        userId,
      }),
    });
  };

  const formatUserName = (bidUserId: number, firstName?: string) => {
    if (bidUserId === userId) {
      return (
        <Tag color="success" className="truncated-text">
          {t("You")}
        </Tag>
      );
    }
    return (
      <Tag color="error" className="truncated-text">
        {firstName || t("Participant")}
      </Tag>
    );
  };

  const timeProgress = 100 - Math.min(100, (remainingTime / 3600) * 100);

  const handleUserClick = (user_Id: number) => {
    if (user_Id !== userId) {
      navigate(`/partners/${user_Id}`);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        dispatch(setMessage(""));
        if (bidLimitDetails) {
          setBidLimitDetails(null);
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, dispatch, bidLimitDetails]);

  const renderAlert = () => {
    if (!message) return null;

    return (
      <Alert
        message={t(message)}
        type={message === "bid_accepted" ? "success" : "error"}
        showIcon
        className={styles.alertMessage}
      />
    );
  };

  const navigateToWinners = () => {
    navigate("/last-auction-winners");
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card
      title={
        <Space>
          <TrophyOutlined className={styles.titleIcon} />
          <Title level={5} className={styles.titleText}>
            {t("Auction")}
          </Title>
        </Space>
      }
      className={styles.cardContainer}
    >
      {isActive ? (
        <Space direction="vertical" className={styles.fullWidth}>
          <Card
            className={styles.timerCard}
          >
            <Space direction="vertical" className={styles.fullWidth}>
              <Space
                align="center"
                className={styles.timerContainer}
              >
                <FieldTimeOutlined
                  className={styles.timerIcon}
                />
                {showTimer && (
                  <Countdown
                    title={t("Time remaining")}
                    value={timerValue}
                    format="HH:mm:ss"
                    onFinish={() => setTimerValue(59 * 60 * 1000)}
                    className={styles.timerText}
                  />
                )}
              </Space>
              <Progress
                percent={timeProgress}
                showInfo={false}
                status="active"
              />
              <Space className={styles.actionSpace}>
                <Button
                  type="primary"
                  onClick={navigateToWinners}
                  icon={<TrophyOutlined />}
                >
                  {t("Winners")}
                </Button>
              </Space>
            </Space>
          </Card>

          <Card className={styles.bonusCard}>
            <Statistic
              title={t("Bonus pool")}
              value={pool}
              suffix={"Bonus"}
              prefix={"$"}
              precision={0}
              valueStyle={{ color: "#52c41a", fontSize: "24px" }}
            />
          </Card>

          <Card className={styles.bidCard}>
            <Space direction="vertical" className={styles.fullWidth}>
              <Statistic
                title={t("Current bid")}
                value={highestBid}
                suffix="WEB"
                prefix={<img src="./assets/LogoW.png" alt="Logo" width={34} />}
                precision={0}
                valueStyle={{ color: "#52c41a", fontSize: "24px" }}
              />
              {bids.length > 0 && (
                <Space onClick={() => handleUserClick(bids[0].user_id)}>
                  <Text type="secondary">{t("Leader")}:</Text>
                  {formatUserName(
                    bids[0].user_id,
                    bids[0].first_name
                  )}
                </Space>
              )}
            </Space>
          </Card>

          <Button
            type="primary"
            size="large"
            block
            onClick={placeBid}
            disabled={nextBid > availableTokens || isLastBidder}
            className={styles.bidButton}
          >
            {t("Place bid")} +1000 WEB
          </Button>

          {renderAlert()}

          <Text type="secondary" className={styles.availableText}>
            {t("Available")}: {availableTokens} WEB
          </Text>

          <Card
            title={t("Bid history")}
            extra={
              bids.length > 5 && (
                <Button
                  type="text"
                  onClick={() => setShowAllBids(!showAllBids)}
                  icon={showAllBids ? <UpOutlined /> : <DownOutlined />}
                />
              )
            }
            className={styles.historyCard}
          >
            <List
              dataSource={visibleBids}
              renderItem={(item) => (
                <List.Item
                  className={`${styles.bidItem} ${item.user_id === userId ? styles.bidItemUser : styles.bidItemOther}`}
                  onClick={() => handleUserClick(item.user_id)}
                >
                  {formatUserName(item.user_id, item.first_name)}
                  <Space size={4}>
                    <img src="./assets/LogoW.png" alt="Logo" width={16} />
                    <Text strong>{item.amount}</Text>
                    <Text strong>WEB</Text>
                  </Space>
                </List.Item>
              )}
              locale={{ emptyText: t("No bids yet") }}
            />
          </Card>
        </Space>
      ) : (
        <Alert
          message={t("No active auction")}
          description={t("Next auction will start soon")}
          type="info"
          showIcon
          className={styles.infoAlert}
        />
      )}
    </Card>
  );
};

export default Auction;
