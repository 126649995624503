import React, { useState, useEffect } from "react";
import WithdrawalHistory from "./withdrawalHistory";
import { useTonAddress } from "@tonconnect/ui-react";
import { MainButton } from "@twa-dev/sdk/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { getAvailableAmountToWithdraw, withdrawFunds } from "../../helpers/api";
import {
  Form,
  Input,
  Typography,
  message as antMessage,
  Spin,
  InputNumber,
  Checkbox,
  Alert,
  Modal,
  Card,
  Space,
} from "antd";
import { isValidTonAddress } from "../../helpers/functions";
import type { CheckboxProps } from "antd";
import styles from "./Withdrawal.module.css";

const { Title, Text } = Typography;

const WithdrawalPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userFriendlyAddress = useTonAddress();
  const { userData } = useSelector((state: RootState) => state.user);
  const tonPrice = useSelector((state: RootState) => state.ton.tonPrice);
  const [amount, setAmount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tonAmount, setTonAmount] = useState<number | null>(null);
  const [shouldRefreshHistory, setShouldRefreshHistory] =
    useState<boolean>(false);
  const [usePartnerBalance, setUsePartnerBalance] = useState<boolean>(false);
  const [availableAmount, setAvailableAmount] = useState<number | null>(null);
  const [frozen, setFrozen] = useState<number | null>(null);
  const [banner, setBanner] = useState<boolean>(false);
  const [loanIsOpen, setLoanIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (amount && tonPrice) {
      setTonAmount(amount / tonPrice);
    } else {
      setTonAmount(null);
    }
  }, [amount, tonPrice]);

  const fetchAvailableAmount = async () => {
    try {
      const data = await getAvailableAmountToWithdraw();
      if (data.error) {
        antMessage.error(t(data.error));
      } else {
        setAvailableAmount(data.available_withdrawal_amount);
        setFrozen(data.frozen);
        setLoanIsOpen(data.loanIsOpen);
        setBanner(data.banner);
      }
    } catch (error) {
      antMessage.error(t("Error fetching available withdrawal amount"));
    }
  };

  useEffect(() => {
    fetchAvailableAmount();
  }, [userData]);

  const handleSubmit = () => {
    if (usePartnerBalance) {
      Modal.info({
        title: t("partner_withdrawal_title"),
        content: (
          <div className={styles.modalContent}>
            <Text>{t("partner_withdrawal_options")}</Text>
            <Card size="small" className={styles.modalCard}>
              <Text strong>{t("option_1_title")}</Text>
              <br />
              <Text>{t("option_1_description")}</Text>
            </Card>
            <Card size="small" className={styles.modalCard}>
              <Text strong>{t("option_2_title")}</Text>
              <br />
              <Text>{t("option_2_description")}</Text>
            </Card>
            <Text type="secondary">{t("withdrawal_insurance_note")}</Text>
          </div>
        ),
        onOk() {
          navigate("/loan-overnight");
        },
        width: 500,
      });
      return;
    }
    if (!loanIsOpen) {
      antMessage.error(t("You must provide at least 1 loan"));
      return;
    }
    // Validate wallet address
    if (!userFriendlyAddress.trim()) {
      antMessage.error(t("Please connect your wallet address"));
      return;
    }

    // Validate amount
    if (amount === null || amount <= 0) {
      antMessage.error(t("Please enter a valid withdrawal amount"));
      return;
    }

    if (!isValidTonAddress(userFriendlyAddress)) {
      antMessage.error(t("Incorrect wallet address"));
      return;
    }

    const withdrawalData = {
      userFriendlyAddress,
      amount,
      usePartnerBalance,
    };

    setLoading(true);
    withdrawFunds(withdrawalData)
      .then((response) => {
        antMessage.success(t(response.success));
        setAmount(null);
        setShouldRefreshHistory((prev) => !prev);
        fetchAvailableAmount();
      })
      .catch((error) => {
        antMessage.error(t(error.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCheckboxChange: CheckboxProps["onChange"] = (e) => {
    setUsePartnerBalance(e.target.checked);
  };

  if (availableAmount === null) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {banner && (
        <Alert
          message={t("Withdraw_banner")}
          type="error"
          showIcon
          className={styles.alert}
        />
      )}

      <Title level={4} className={styles.title}>
        {t("Withdraw funds")}
      </Title>

      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Card size="small" className={styles.card}>
          <Space direction="vertical" style={{ width: "100%" }} size={12}>
            <div className={styles.balanceRow}>
              <Text>{t("balance")}</Text>
              <Text strong>${userData?.real_balance}</Text>
            </div>
            <div className={styles.balanceRow}>
              <Text>{t("Partner Balance")}</Text>
              <Text strong>${userData?.partner_balance}</Text>
            </div>
            {usePartnerBalance && (
              <div className={styles.balanceRow}>
                <Text>{t("Frozen")}</Text>
                <Text strong>${frozen}</Text>
              </div>
            )}
            {!usePartnerBalance && (
              <div className={styles.balanceRow}>
                <Text>{t("Available amount for withdrawal")}</Text>
                <Text strong>
                  $
                  {availableAmount === 0
                    ? availableAmount
                    : userData?.real_balance}
                </Text>
              </div>
            )}
          </Space>
        </Card>

        <Form layout="vertical" className={styles.form}>
          <Form.Item>
            <Checkbox
              onChange={onCheckboxChange}
              className={styles.checkboxMargin}
            >
              <Text>{t("Withdrawal from the partner balance")}</Text>
            </Checkbox>
          </Form.Item>

          <Form.Item
            label={<Text strong>{t("Wallet Address") + " (TON)"}</Text>}
            required
          >
            <Input
              placeholder={t("Connect your wallet")}
              value={userFriendlyAddress}
              disabled={true}
              className={styles.input}
            />
          </Form.Item>

          <Form.Item
            label={<Text strong>{t("Amount")}</Text>}
            extra={<Text type="secondary">Min. $1</Text>}
            required
          >
            <InputNumber
              placeholder={t("Enter amount to withdraw")}
              value={amount}
              onChange={(value) => setAmount(value)}
              className={styles.input}
              min={1}
              max={usePartnerBalance ? availableAmount : userData?.real_balance}
              disabled={banner}
            />
            {userData && userData.real_balance >= 1 && (
              <div>
                <span
                  className={styles.maxButton}
                  onClick={() => {
                    const maxAmount = usePartnerBalance
                      ? availableAmount || 0
                      : userData?.real_balance || 0;
                    setAmount(maxAmount);
                  }}
                >
                  {t("max")}
                </span>
              </div>
            )}
          </Form.Item>
          {tonAmount && (
            <Text type="secondary" className={styles.tonAmountText}>
              {t("You will receive")} {tonAmount.toFixed(6)} TON
            </Text>
          )}
        </Form>

        <Alert
          message={t("withdrawal_info")}
          type="info"
          showIcon
          className={styles.alert}
        />

        <WithdrawalHistory shouldRefresh={shouldRefreshHistory} />
      </Space>

      <div className={styles.buttonContainer}>
        <MainButton
          text={t("Withdraw")}
          onClick={handleSubmit}
          disabled={loading || banner}
        />
      </div>

      {loading && (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default WithdrawalPage;
