import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Radio, List, Spin } from "antd";
import { fetchCertificates } from "../helpers/api";
import { LockOutlined } from "@ant-design/icons";
import { LoanDetails } from "../types";

const SalesCertificates: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState<LoanDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<"TON" | "USDT">("TON");


  const loadCertificates = async () => {
    setLoading(true);
    try {
      const data = await fetchCertificates(currency);
      setCertificates(data);
    } catch (error) {
      message.error("Ошибка при загрузке сертификатов");
      console.error("Error fetching certificates:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCertificates();
  }, [currency]);

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-semibold">{t("Certificate Exchange")}</h2>
        <Radio.Group
          block
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          options={[
            { label: "TON", value: "TON" },
            { label: "USDT", value: "USDT" },
          ]}
          optionType="button"
        />
      </div>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={certificates}
          renderItem={(certificate) => (
            <List.Item
              onClick={() =>
                navigate(`/certificates/sales/${certificate.loan_id}`)
              }
              style={{ cursor: "pointer", justifyContent: "space-between" }}
            >
              <div className="sales_item">
                <div className="sales_item_info">
                  <div>
                    {certificate.currency === "USDT" ? (
                      <img
                        src="../assets/UsdtLogo.webp"
                        alt="USDT Logo"
                        width={12}
                        style={{ marginRight: 5 }}
                      />
                    ) : (
                      <img
                        src="../assets/tonIcon.png"
                        alt="Logo"
                        width={12}
                        style={{ marginRight: 5 }}
                      />
                    )}
                    {certificate.amount} {currency} / {t("Price")}: {' '}
                    {certificate.price} {currency}
                  </div>
                  <span>
                    {t("Rate")}: {certificate.rate}% / {t("Term")}: {' '}
                    {certificate.term} {t("days")}
                  </span>
                </div>
                <div>
                  <LockOutlined
                    style={{
                      color: certificate.insurance ? "green" : "red",
                      fontSize: "20px",
                    }}
                  />
                </div>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default SalesCertificates;
