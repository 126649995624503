import { useState, useEffect, FC } from "react";
import { Tabs, Card, Avatar, Typography, Tag, Spin, List } from "antd";
import { useTranslation } from "react-i18next";
import {
  CrownOutlined,
  TrophyOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { fetchContestResults, fetchLastWeekWinners } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { ContestParticipant, ContestWinner } from "../../types";
import styles from "./Contest.module.css";

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const Contest: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentWeekResults, setCurrentWeekResults] = useState<
    ContestParticipant[]
  >([]);
  const [lastWeekWinners, setLastWeekWinners] = useState<ContestWinner[]>([]);
  const [userPosition, setUserPosition] = useState<number | null>(null);
  const [userPoints, setUserPoints] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state: RootState) => state.user.userData?.id);

  const activityPoints = [
    { activity: t("Loan issuance"), points: 50 },
    { activity: t("Loan receiving"), points: 50 },
    { activity: t("Partner invitation"), points: 50 },
    { activity: t("Certificate trading"), points: 20 },
    { activity: t("Post creation"), points: 10 },
    { activity: t("Comment"), points: 5 },
    { activity: t("Task completion"), points: 5 },
    { activity: t("Subscription"), points: 5 },
    { activity: t("Like"), points: 2 },
  ];

  const rewards = [
    { place: 1, amount: "500 USD + 1 TON" },
    { place: 2, amount: "300 USD + 1 TON" },
    { place: 3, amount: "200 USD + 1 TON" },
    { place: 4, amount: "150 USD + 1 TON" },
    { place: 5, amount: "100 USD + 1 TON" },
    { place: 6, amount: "90 USD + 1 TON" },
    { place: 7, amount: "80 USD + 1 TON" },
    { place: 8, amount: "70 USD + 1 TON" },
    { place: 9, amount: "60 USD + 1 TON" },
    { place: 10, amount: "50 USD + 1 TON" },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const results = await fetchContestResults();
        setCurrentWeekResults(results.top10);
        if (userId) {
          setUserPosition(results.userPosition);
          setUserPoints(results.userPoints);
        }
        const winners = await fetchLastWeekWinners();
        setLastWeekWinners(winners);
      } catch (error) {
        console.error("Error fetching contest data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [userId]);

  const renderParticipantCard = (
    participant: ContestParticipant,
    index: number
  ) => (
    <Card
      key={participant.user_id}
      className={`${styles.participantCard} ${index < 1 ? styles.topParticipantCard : ''}`}
    >
      <div
        className={styles.participantContainer}
        onClick={() => navigate(`/partners/${participant.user_id}`)}
      >
        <div
          className={`${styles.positionNumber} ${index < 1 ? styles.topPositionNumber : styles.regularPositionNumber}`}
        >
          {index + 1}
        </div>
        <Avatar
          size={40}
          src={participant.avatar_url}
          className={index < 1 ? styles.topAvatar : ''}
        />
        <div className={styles.userInfo}>
          <Text strong>
            {participant.first_name} {participant.last_name}
          </Text>
          <div>
            <Tag color="blue">
              {participant.total_points} {t("points")}
            </Tag>
          </div>
        </div>
        {index < 1 && (
          <CrownOutlined className={styles.crownIcon} />
        )}
      </div>
    </Card>
  );

  const renderWinnerCard = (winner: ContestWinner, index: number) => (
    <Card
      key={winner.user_id}
      className={styles.winnerCard}
    >
      <div className={styles.winnerContainer}>
        <TrophyOutlined className={styles.trophyIcon} />
        <Avatar size={40} src={winner.avatar_url} onClick={() => navigate(`/partners/${winner.user_id}`)} />
        <div className={styles.userInfo}>
          <Text strong>
            {winner.first_name} {winner.last_name}
          </Text>
          <div>
            <Tag color="gold">
              {winner.points} {t("points")}
            </Tag>
            {winner.transaction_hash && (
              <a
                href={`https://tonviewer.com/transaction/${winner.transaction_hash}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.transactionLink}
              >
                <Tag color="green">{t("View Transaction")}</Tag>
              </a>
            )}
          </div>
        </div>
      </div>
    </Card>
  );

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Title level={4}>{t("Activity Contest")}</Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Current Week")} key="current">
          {userId && userPosition && (
            <Card
              className={styles.userPositionCard}
            >
              <Text>{t("Your position")}: </Text>
              <Tag color="blue">{userPosition}</Tag>
              <Text style={{ marginLeft: 5 }}>{t("Your points")}: </Text>
              <Tag color="blue">{userPoints}</Tag>
            </Card>
          )}
          {currentWeekResults.map((participant, index) =>
            renderParticipantCard(participant, index)
          )}
        </TabPane>
        <TabPane tab={t("About Contest")} key="about">
          <Card className={styles.aboutCard}>
            <Paragraph className={styles.aboutParagraph}>
              <InfoCircleOutlined className={styles.infoIcon} />
              {t("contest.description")}
            </Paragraph>

            <Tabs defaultActiveKey="points">
              <TabPane tab={t("Activity Points")} key="points">
                <List
                  size="small"
                  dataSource={activityPoints}
                  renderItem={(item) => (
                    <List.Item>
                      <Text>{item.activity}</Text>
                      <Tag color="blue">+{item.points}</Tag>
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab={t("Rewards")} key="rewards">
                <List
                  size="small"
                  dataSource={rewards}
                  renderItem={(item) => (
                    <List.Item>
                      <Text>
                        {t("Place")} {item.place}
                      </Text>
                      <Tag color="gold">{item.amount}</Tag>
                    </List.Item>
                  )}
                />
              </TabPane>
            </Tabs>
          </Card>
        </TabPane>
        <TabPane tab={t("Last Week Winners")} key="winners">
          {lastWeekWinners.map((winner, index) =>
            renderWinnerCard(winner, index)
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Contest;
