import { useState, useEffect, FC } from "react";
import {
  Tabs,
  Card,
  Avatar,
  Typography,
  Tag,
  Spin,
  List,
  Pagination,
  Empty,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  CrownOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import {
  fetchReferralContestResults,
  fetchLastDayReferralWinners,
} from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { ReferralContestParticipant, ReferralContestWinner } from "../../types";
import styles from "./Contest.module.css";

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const ReferralContest: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentDayResults, setCurrentDayResults] = useState<
    ReferralContestParticipant[]
  >([]);
  const [userPosition, setUserPosition] = useState<number | null>(null);
  const [userAmount, setUserAmount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [winnersByDate, setWinnersByDate] = useState<{
    [key: string]: ReferralContestWinner[];
  }>({});
  const pageSize = 5;
  const userId = useSelector((state: RootState) => state.user.userData?.id);

  const rewards = [
    { place: 1, amount: "5 TON" },
    { place: 2, amount: "3 TON" },
    { place: 3, amount: "2 TON" },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const results = await fetchReferralContestResults();
        setCurrentDayResults(results.top10);
        if (userId) {
          setUserPosition(results.userPosition);
          setUserAmount(results.userAmount);
        }

        loadWinners(currentPage);
      } catch (error) {
        console.error("Error fetching referral contest data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [userId]);

  const loadWinners = async (page: number) => {
    try {
      const winners = await fetchLastDayReferralWinners(page, pageSize);
      setTotalPages(winners.totalPages);

      const grouped = winners.data.reduce(
        (
          acc: { [key: string]: ReferralContestWinner[] },
          winner: ReferralContestWinner
        ) => {
          const date = winner.date;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(winner);
          return acc;
        },
        {}
      );

      setWinnersByDate(grouped);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching winners:", error);
    }
  };

  const handlePageChange = (page: number) => {
    setLoading(true);
    loadWinners(page);
  };

  const renderParticipantCard = (
    participant: ReferralContestParticipant,
    index: number
  ) => (
    <Card
      key={participant.user_id}
      className={`${styles.participantCard} ${index < 3 ? styles.topParticipantCard : ''}`}
    >
      <div
        className={styles.participantContainer}
        onClick={() => navigate(`/partners/${participant.user_id}`)}
      >
        <div
          className={`${styles.positionNumber} ${index < 3 ? styles.topPositionNumber : styles.regularPositionNumber}`}
        >
          {index + 1}
        </div>
        <Avatar
          size={40}
          src={participant.avatar_url}
          className={index < 3 ? styles.topAvatar : ''}
        />
        <div className={styles.userInfo}>
          <Text strong>
            {participant.first_name} {participant.last_name}
          </Text>
          <div>
            <Tag color="gold">${participant.total_amount}</Tag>
          </div>
        </div>
        {index < 3 && (
          <CrownOutlined className={styles.crownIcon} />
        )}
      </div>
    </Card>
  );

  const renderWinnerCard = (winner: ReferralContestWinner, index: number) => (
    <Card key={winner.user_id} className={styles.winnerCard}>
      <div className={styles.winnerContainer}>
        <div
          className={`${styles.positionNumber} ${winner.place <= 3 ? styles.topPositionNumber : styles.regularPositionNumber}`}
        >
          {winner.place}
        </div>
        <Avatar
          size={40}
          src={winner.avatar_url}
          className={styles.winnerAvatarContainer}
          onClick={() => navigate(`/partners/${winner.user_id}`)}
        />
        <div className={styles.userInfo}>
          <Text strong>
            {winner.first_name} {winner.last_name}
          </Text>
          <div>
            <Tag color="gold">${winner.amount}</Tag>
            {winner.transaction_hash && (
              <a
                href={`https://tonviewer.com/transaction/${winner.transaction_hash}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.transactionLink}
              >
                <Tag color="green">{t("View Transaction")}</Tag>
              </a>
            )}
          </div>
        </div>
      </div>
    </Card>
  );

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Title level={4}>{t("Invest Contest")}</Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Ranking")} key="current">
          {userId && userPosition && (
            <Card
              className={styles.userPositionCard}
            >
              <Text>{t("Your position")}: </Text>
              <Tag color="blue">{userPosition}</Tag>
              <Text style={{ marginLeft: 5 }}>
                {t("Your referred amount")}:{" "}
              </Text>
              <Tag color="blue">${userAmount}</Tag>
            </Card>
          )}
          {currentDayResults.length > 0 ? (
            currentDayResults.map((participant, index) =>
              renderParticipantCard(participant, index)
            )
          ) : (
            <Empty description={t("No participants yet")} />
          )}
        </TabPane>
        <TabPane tab={t("About Contest")} key="about">
          <Card className={styles.aboutCard}>
            <Paragraph className={styles.aboutParagraph}>
              <div dangerouslySetInnerHTML={{ __html: t("referralContest.description") }} />
            </Paragraph>

            <Tabs defaultActiveKey="rewards">
              <TabPane tab={t("Rewards")} key="rewards">
                  <List
                    size="small"
                    dataSource={rewards}
                    renderItem={(item) => (
                    <List.Item>
                      <Text>
                        {t("Place")} {item.place}
                      </Text>
                      <Tag color="gold">{item.amount}</Tag>
                    </List.Item>
                    )}
                  />
              </TabPane>
            </Tabs>
          </Card>
        </TabPane>
        <TabPane tab={t("Winners")} key="winners">
          {Object.keys(winnersByDate).length > 0 ? (
            <>
              {Object.entries(winnersByDate).map(([date, winners]) => (
                <div key={date}>
                  <Title level={5} className={styles.dateTitleContainer}>
                    {new Date(date).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Title>
                  {winners
                    .sort((a, b) => a.place - b.place)
                    .map((winner, index) => renderWinnerCard(winner, index))}
                </div>
              ))}
              <div className={styles.paginationContainer}>
                <Pagination
                  current={currentPage}
                  total={totalPages * pageSize}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </div>
            </>
          ) : (
            <Empty description={t("No winners yet")} />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ReferralContest;
