import { FC, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Avatar,
  Typography,
  Image,
  Space,
  message,
  Modal,
  Input,
  Button,
  Dropdown,
  Tag,
} from "antd";
import {
  EyeIcon,
  HandThumbUpIcon,
  TrashIcon,
  InformationCircleIcon,
  PencilIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/solid";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { PostProps } from "../../types";
import { useNavigate } from "react-router-dom";
import PollComponent from "../Poll";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { formatViewCount } from "../../helpers/functions";
import { updateBonusBalance } from "../../services/slices/userSlice";
import { AppDispatch } from "../../services/store";
import type { MenuProps } from "antd";
import Comments from "../Comments";
import styles from "./post.module.css";
import { sanitize } from 'isomorphic-dompurify';

const { Paragraph, Text } = Typography;
const { Meta } = Card;

const Post: FC<PostProps> = ({
  first_name,
  last_name,
  avatarUrl,
  content,
  imageUrl,
  likes,
  comments_count,
  postId,
  postTime,
  user_id,
  userId,
  poll,
  liked_by_user,
  view_count,
  onDelete,
  is_premium_user,
}) => {
  const [likeCount, setLikeCount] = useState(likes);
  const [liked, setLiked] = useState(liked_by_user);
  const [viewCount, setViewCount] = useState<number>(view_count);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(content);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const postRef = useRef<HTMLDivElement>(null);
  const hasIncremented = useRef(false);
  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false);
  const [isLikeAnimating, setIsLikeAnimating] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [expandText, setExpandText] = useState(false);

  useEffect(() => {
    const incrementViewCount = async () => {
      try {
        await axios.post("/api/account/increment_post_view.php", {
          post_id: postId,
        });
        hasIncremented.current = true;
        setViewCount(viewCount + 1);
      } catch (error) {
        console.error("Failed to increment view count:", error);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasIncremented.current) {
            incrementViewCount();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [postId, viewCount]);

  const handleDelete = async (postId: number) => {
    Modal.confirm({
      title: t("Are you sure you want to delete this post?"),
      content: t("Once deleted, you will not be able to recover this post!"),
      okText: t("Delete"),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk: async () => {
        try {
          await axios.post("/api/account/delete_post.php", {
            post_id: postId,
          });

          if (onDelete) {
            onDelete(postId);
          }

          message.success(t("The post was successfully deleted!"));
        } catch (error) {
          console.error("Failed to delete post:", error);
          message.error(t("Failed to delete post"));
        }
      },
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.post("/api/account/edit_post.php", {
        post_id: postId,
        content: sanitize(editContent),
      });

      if (response.data.success) {
        message.success(t("Post successfully updated"));
        setIsEditing(false);
      } else {
        message.error(t("Failed to update post"));
      }
    } catch (error) {
      console.error("Error updating post:", error);
      message.error(t("Error updating post"));
    }
  };

  const handleLike = async () => {
    try {
      setIsLikeAnimating(true);
      const response = await axios.post("/api/account/like_post.php", {
        user_id: userId,
        post_id: postId,
      });
      if (response.data.action === "liked") {
        setLikeCount(likeCount + 1);
        setLiked(true);
        dispatch(updateBonusBalance(response.data.bonus_added));
      } else {
        setLikeCount(likeCount - 1);
        setLiked(false);
      }

      setTimeout(() => {
        setIsLikeAnimating(false);
      }, 500);
    } catch (error) {
      console.error("Failed to like/unlike post:", error);
      setIsLikeAnimating(false);
    }
  };

  const handleReport = async (postId: number) => {
    try {
      await axios.post("/api/account/report_post.php", {
        user_id: userId,
        post_id: postId,
      });
      message.success(t("Report has been send"));
    } catch (error) {
      console.error("Failed to report post:", error);
    }
  };

  const handleVote = async (voteAnswer: string) => {
    if (voteAnswer === null) {
      return;
    }
    try {
      await axios.post("/api/account/vote_poll.php", {
        user_id: userId,
        post_id: postId,
        option_index: voteAnswer,
      });
      message.success(t("Your vote has been submitted"));
    } catch (error) {
      console.error("Failed to submit vote:", error);
      message.error(t("Failed to submit vote"));
    }
  };

  const ownerMenu: MenuProps = {
    items: [
      {
        key: "edit",
        label: <Text onClick={handleEdit}>{t("Edit")}</Text>,
        icon: <PencilIcon width={16} />,
      },
      {
        key: "delete",
        label: <Text onClick={() => handleDelete(postId)}>{t("Delete")}</Text>,
        icon: <TrashIcon style={{ color: "red" }} width={16} />,
      },
    ],
  };

  const reportMenu: MenuProps = {
    items: [
      {
        key: "report",
        label: <Text onClick={() => handleReport(postId)}>{t("Report")}</Text>,
        icon: <InformationCircleIcon width={16} />,
      },
    ],
  };

  const handleCommentsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCommentsModalVisible(true);
  };

  const toggleImageExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedImage(!expandedImage);
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.target as HTMLImageElement;
    const height = img.naturalHeight;
    const width = img.naturalWidth;

    setShowExpandButton(height > 500 && height / width > 1.5);
  };

  const handleExpandText = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandText(!expandText);
  };

  const shouldTruncateText =
    content && (content.length > 300 || content.split("\n\n").length > 2);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;

    const lastSpace = text.lastIndexOf(" ", maxLength);
    if (lastSpace === -1) return text.slice(0, maxLength) + "...";

    return text.slice(0, lastSpace) + "...";
  };

  return (
    <div ref={postRef}>
      {is_premium_user && (
        <Tag color="#d4af37" className={styles.premiumTag}>
          ✨ {t("Premium")}
        </Tag>
      )}
      <Card
        className={styles.postCard}
        hoverable
        actions={[
          <Space
            onClick={handleLike}
            key="like"
            className={`${styles.likeButton} ${liked ? styles.liked : ""} ${
              isLikeAnimating ? styles.animating : ""
            }`}
            style={{
              transform: isLikeAnimating ? "scale(1.2)" : "scale(1)",
            }}
          >
            <HandThumbUpIcon
              style={{
                color: liked ? "#ff4d4f" : "inherit",
                display: "flex",
              }}
              width={16}
            />
            <Text>{formatViewCount(likeCount)}</Text>
          </Space>,
          <Space
            key="comments"
            onClick={handleCommentsClick}
            className={styles.commentsButton}
          >
            <ChatBubbleOvalLeftEllipsisIcon
              width={14}
              className={styles.commentsIcon}
            />
            <Text>{formatViewCount(comments_count)}</Text>
          </Space>,
          <Dropdown
            menu={user_id === userId ? ownerMenu : reportMenu}
            key="more"
          >
            <Space>
              <EllipsisOutlined />
            </Space>
          </Dropdown>,
        ]}
        cover={
          imageUrl && (
            <div
              className={`${styles.postImageContainer} ${
                expandedImage ? styles.fullSizeContainer : ""
              }`}
              ref={imageContainerRef}
            >
              <Image
                src={imageUrl}
                alt="Post image"
                className={expandedImage ? styles.fullSizeImage : ""}
                preview={{
                  mask: (
                    <div className={styles.imagePreviewMask}>
                      <EyeIcon width={24} className={styles.imagePreviewIcon} />{" "}
                      {t("View")}
                    </div>
                  ),
                }}
                onLoad={handleImageLoad}
              />
              {showExpandButton &&
                (!expandedImage ? (
                  <div
                    className={styles.expandImageButton}
                    onClick={toggleImageExpand}
                  >
                    <ArrowDownIcon width={16} /> {t("Show more")}
                  </div>
                ) : (
                  <div
                    className={styles.expandImageButton}
                    onClick={toggleImageExpand}
                  >
                    <ArrowUpIcon width={16} /> {t("Show less")}
                  </div>
                ))}
            </div>
          )
        }
      >
        {isEditing ? (
          <div>
            <Input.TextArea
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              rows={4}
              autoSize={{ minRows: 4, maxRows: 8 }}
              className={styles.editTextArea}
            />
            <Space>
              <Button
                onClick={handleSaveEdit}
                type="primary"
                className={styles.saveButton}
              >
                {t("Save")}
              </Button>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  setEditContent(content);
                }}
                className={styles.cancelButton}
              >
                {t("Cancel")}
              </Button>
            </Space>
          </div>
        ) : (
          <div className={styles.postContent}>
            {shouldTruncateText && !expandText ? (
              <>
                <Paragraph>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          href={props.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.children}
                        </a>
                      ),
                      p: ({ node, ...props }) => <p>{props.children}</p>,
                      strong: ({ node, ...props }) => (
                        <strong>{props.children}</strong>
                      ),
                    }}
                  >
                    {sanitize(truncateText(editContent, 300))}
                  </ReactMarkdown>
                </Paragraph>
                <Button
                  type="link"
                  onClick={handleExpandText}
                  className={styles.expandTextButton}
                >
                  {t("Show more")}
                </Button>
              </>
            ) : (
              <>
                <Paragraph>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          href={props.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.children}
                        </a>
                      ),
                      p: ({ node, ...props }) => <p>{props.children}</p>,
                      strong: ({ node, ...props }) => (
                        <strong>{props.children}</strong>
                      ),
                    }}
                  >
                    {sanitize(editContent)}
                  </ReactMarkdown>
                </Paragraph>
                {shouldTruncateText && expandText && (
                  <Button
                    type="link"
                    onClick={handleExpandText}
                    className={styles.expandTextButton}
                  >
                    {t("Show less")}
                  </Button>
                )}
              </>
            )}
          </div>
        )}

        {poll && poll.options && poll.options.length > 0 && (
          <PollComponent poll={poll} onVote={handleVote} />
        )}

        <Meta
          avatar={
            <Avatar
              src={avatarUrl}
              size="large"
              shape="square"
              onClick={() => navigate(`/partners/${user_id}`)}
              className={styles.userAvatar}
            />
          }
          title={
            <div
              className={styles.info}
              onClick={() => navigate(`/post/${postId}`)}
            >
              <Text strong className={styles.userName}>
                {first_name} {last_name}
              </Text>
              <div className={styles.postBottom}>
                <span className={styles.timeStamp}>
                  {moment.utc(postTime).fromNow()}
                </span>
                <div className={styles.postCount}>
                  <EyeIcon width={14} className={styles.viewIcon} />
                  <span>{formatViewCount(viewCount)}</span>
                </div>
              </div>
            </div>
          }
        />
      </Card>

      <Modal
        open={isCommentsModalVisible}
        onCancel={() => setIsCommentsModalVisible(false)}
        footer={null}
        width={600}
        className={styles.commentsModal}
      >
        <div className={styles.commentsContainer}>
          <div className={styles.commentsContent}>
            <Comments postId={postId} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Post;
