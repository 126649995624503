import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getJettonWalletAddress } from '../../helpers/api';
import { RootState } from '../store';

interface Price {
  TON: number;
  USD: number;
}

interface USDTState {
  balance: number | null;
  price: Price | null;
  wallet_address: string | null;
  image: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: USDTState = {
  balance: null,
  price: null,
  wallet_address: null,
  image: null,
  isLoading: false,
  error: null,
};

export const fetchUSDTData = createAsyncThunk(
  'usdt/fetchUSDTData',
  async (userFriendlyAddress: string, { getState, rejectWithValue }) => {
    try {
      const jettonMasterAddress = 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs';
      const data = await getJettonWalletAddress(userFriendlyAddress, jettonMasterAddress);

      const state = getState() as RootState;
      const tonPrice = state.ton.tonPrice;

      return {
        balance: Number(data.balance) / 1000000,
        wallet_address: data.wallet_address.address,
        tonPrice
      };
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An error occurred');
    }
  }
);

const usdtSlice = createSlice({
  name: 'usdt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUSDTData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUSDTData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.balance = action.payload.balance;
        state.wallet_address = action.payload.wallet_address;
      })
      .addCase(fetchUSDTData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default usdtSlice.reducer;
