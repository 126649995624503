import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchUserLoans } from "../helpers/api";
import { getStatusColor } from "../helpers/functions";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import {
  List,
  Typography,
  Spin,
  Select,
  Tag,
  Pagination,
  Row,
  Col,
  Space,
  Card,
  Empty,
  Flex
} from "antd";
import { Loan } from "../types";

const { Text } = Typography;

type LoanType = 'all' | 'personal' | 'bonus' | 'partner';
type DemandType = 'all' | 'regular' | 'demand';

const Loans: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useSelector((state: RootState) => state.user);
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loanType, setLoanType] = useState<LoanType | undefined>(undefined);
  const [demandType, setDemandType] = useState<DemandType | undefined>(undefined);
  const itemsPerPage = 10;

  const loadLoans = async (
    currentPage: number, 
    currentStatus?: string,
    currentLoanType?: LoanType,
    currentDemandType?: DemandType
  ) => {
    setLoading(true);
    try {
      const isOnDemand = currentDemandType === 'demand' ? true : 
                        currentDemandType === 'regular' ? false : undefined;
      const data = await fetchUserLoans(
        currentPage,
        itemsPerPage,
        currentStatus,
        isOnDemand,
        currentLoanType || undefined
      );
      setLoans(data.loans);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error loading loans:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const currentStatus = searchParams.get("status") || undefined;
    const currentLoanType = searchParams.get("type") as LoanType | undefined;
    const currentDemandType = searchParams.get("demand") as DemandType | undefined;

    setPage(currentPage);
    setStatus(currentStatus);
    setLoanType(currentLoanType);
    setDemandType(currentDemandType);
  }, [searchParams]);

  useEffect(() => {
    if (userData) {
      loadLoans(page, status, loanType, demandType);
    }
  }, [userData, page, status, loanType, demandType]);

  const updateSearchParams = (params: Record<string, string>) => {
    const newSearchParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });
    setSearchParams(newSearchParams);
  };

  return (
    <div>
      <Typography.Title level={4} style={{ marginBottom: 24 }}>{t("Loans")}</Typography.Title>

      <Card
        style={{
          marginBottom: 24,
          borderRadius: 12,
        }}
      >
        <Flex vertical gap={12} style={{ width: '100%' }}>
          <Select
            value={status}
            onChange={(value) => updateSearchParams({ status: value, page: '1' })}
            allowClear
            placeholder={t("Filter by status")}
            dropdownStyle={{ borderRadius: 8 }}
            className="tg-select"
            style={{ width: '100%' }}
          >
            <Select.Option value="active">{t("loan_status.active")}</Select.Option>
            <Select.Option value="completed">{t("loan_status.completed")}</Select.Option>
            <Select.Option value="pending">{t("loan_status.pending")}</Select.Option>
            <Select.Option value="for_sale">{t("loan_status.for_sale")}</Select.Option>
            <Select.Option value="waiting_confirmation">{t("loan_status.waiting_confirmation")}</Select.Option>
          </Select>

          <Select
            value={demandType}
            onChange={(value) => updateSearchParams({ demand: value, page: '1' })}
            allowClear
            placeholder={t("Loan type")}
            dropdownStyle={{ borderRadius: 8 }}
            className="tg-select"
            style={{ width: '100%' }}
          >
            <Select.Option value="regular">{t("Regular loans")}</Select.Option>
            <Select.Option value="demand">{t("Overnight on demand")}</Select.Option>
          </Select>

          <Select
            value={loanType}
            onChange={(value) => updateSearchParams({ type: value, page: '1' })}
            allowClear
            placeholder={t("Source")}
            dropdownStyle={{ borderRadius: 8 }}
            className="tg-select"
            style={{ width: '100%' }}
          >
            <Select.Option value="personal">{t("personal")}</Select.Option>
            <Select.Option value="bonus">{t("bonus")}</Select.Option>
            <Select.Option value="partner">{t("partner")}</Select.Option>
          </Select>
        </Flex>
      </Card>

      {loading ? (
        <div style={{ textAlign: 'center', padding: '40px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {loans.length === 0 ? (
            <Empty
              description={t("You don't have any loans yet")}
              style={{ margin: '40px 0' }}
            />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={loans}
              renderItem={(loan) => (
                <Card
                  onClick={() => navigate(`/loan/${loan.loan_id}?page=${page}&status=${status || ""}`)}
                  style={{ 
                    cursor: "pointer", 
                    marginBottom: 16,
                    borderRadius: 8,
                    transition: 'all 0.3s ease'
                  }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Space align="center" style={{ justifyContent: 'space-between', width: "100%" }}>
                      <Space>
                        {loan.used_bonus ? (
                          <img src="./assets/dollarIcon.png" alt="Dollar Icon" width={24} />
                        ) : loan.currency === "USDT" ? (
                          <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={20} />
                        ) : (
                          <img src="./assets/tonIcon.png" alt="Logo" width={20} />
                        )}
                        <Text strong style={{ fontSize: 16 }}>{loan.amount}</Text>
                        <Text type="secondary">{loan.currency}</Text>
                      </Space>
                      <Tag color={getStatusColor(loan.status)}>
                        {t(`loan_status.${loan.status}`)}
                      </Tag>
                    </Space>

                    <Space wrap>
                      <Text type="secondary">
                        {t("Rate")}: <Text strong>{loan.rate}%</Text>
                      </Text>
                      <Text type="secondary">
                        {t("Profit")}: <Text strong style={{ color: '#52c41a' }}>
                          +{(loan.profit - loan.insurance_pool_contribution).toFixed(2)}
                        </Text>
                      </Text>
                    </Space>
                  </Space>
                </Card>
              )}
            />
          )}

          {loans.length > 0 && (
            <Row justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
              <Col>
                <Pagination
                  current={page}
                  total={totalPages * itemsPerPage}
                  pageSize={itemsPerPage}
                  onChange={(newPage) => updateSearchParams({ page: newPage.toString() })}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default Loans;
