import React, { useState } from "react";
import { Card, Progress, Typography, Space, Tag, Button, message } from "antd";
import { TrophyOutlined, StarOutlined, GiftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { achievementsApi } from "../../helpers/api";
import { AchievementsCardProps } from "../../types";
import { updateWebTokens } from "../../services/slices/userSlice";

const { Text, Title } = Typography;

const AchievementsCard: React.FC<AchievementsCardProps> = ({
  achievement,
  onRewardClaimed,
}) => {
  const { t } = useTranslation();
  const [claiming, setClaiming] = useState(false);
  const dispatch = useDispatch();

  const displayProgress = Math.min(
    achievement.current_progress,
    achievement.target_progress
  );
  const progress = (displayProgress / achievement.target_progress) * 100;

  const getStatusColor = () => {
    switch (achievement.status) {
      case "completed":
        return "success";
      case "in_progress":
        return "processing";
      default:
        return "default";
    }
  };

  const getStatusText = () => {
    const status = achievement.status || "locked";
    return t(`achievements.status.${status}`);
  };

  const handleClaimReward = async () => {
    if (claiming) return;
    try {
      setClaiming(true);
      const { success } = await achievementsApi.claimReward(achievement.id);
      if (success) {
        message.success(t("achievements.reward_claimed"));
        dispatch(updateWebTokens(Number(achievement.reward)));
        setTimeout(() => {
          onRewardClaimed?.();
        }, 100);
      }
    } catch (error) {
      console.error("Error claiming reward:", error);
      message.error(t("error.general"));
    } finally {
      setClaiming(false);
    }
  };

  return (
    <Card className="achievement-card" size="small">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space
          align="center"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <Space>
            <TrophyOutlined style={{ fontSize: "20px", color: "#faad14" }} />
            <Title level={5} style={{ margin: 0 }}>
              {t(`achievements.types.${achievement.achievement_type}.title`)}
            </Title>
          </Space>
          <Tag color={getStatusColor()}>
            {getStatusText()}
          </Tag>
        </Space>

        <Text type="secondary">
          {t(`achievements.types.${achievement.achievement_type}.description`, {
            target: achievement.target_progress,
          })}
        </Text>

        <Progress
          percent={progress}
          status="active"
          format={() => `${displayProgress}/${achievement.target_progress}`}
        />

        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <Space>
            <StarOutlined style={{ color: "#faad14" }} />
            <Text>
              {t("achievements.reward")}: {achievement.reward} WEB
            </Text>
          </Space>
        </Space>
        {achievement.status === "completed" && !achievement.claimed && (
          <Button
            type="primary"
            icon={<GiftOutlined />}
            size="small"
            loading={claiming}
            onClick={handleClaimReward}
          >
            {t("achievements.claim_reward")}
          </Button>
        )}
      </Space>
    </Card>
  );
};

export default AchievementsCard;

