import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Bid {
  user_id: number;
  amount: number;
  first_name: string;
}

interface AuctionState {
  highestBid: number;
  endTime: string;
  winnerId: number | null;
  bids: Bid[];
  remainingTime: number;
  message: string;
  isActive: boolean;
}

const initialState: AuctionState = {
  highestBid: 0,
  endTime: "",
  winnerId: null,
  bids: [],
  remainingTime: 0,
  message: "",
  isActive: false,
};

const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    setAuctionState: (state, action: PayloadAction<{
      highestBid: number;
      endTime: string;
      winnerId: number | null;
      remainingTime: number;
      bids?: Bid[];
    }>) => {
      state.highestBid = action.payload.highestBid;
      state.endTime = action.payload.endTime;
      state.winnerId = action.payload.winnerId;
      state.remainingTime = action.payload.remainingTime;
      if (action.payload.bids) {
        state.bids = action.payload.bids;
      }
      state.isActive = true;
    },
    addBid: (state, action: PayloadAction<Bid>) => {
      state.bids.unshift(action.payload);
      state.highestBid = action.payload.amount;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    updateRemainingTime: (state, action: PayloadAction<number>) => {
      state.remainingTime = action.payload;
    },
  },
});

export const { setAuctionState, addBid, setMessage, updateRemainingTime } = auctionSlice.actions;
export default auctionSlice.reducer;