import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Space, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import AchievementsCard from './AchievementsCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { achievementsApi } from '../../helpers/api';
import { Achievement } from '../../types';

const { Title } = Typography;

const Achievements: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const { userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const loadAchievements = async () => {
      if (!userData?.id) return;

      try {
        setLoading(true);
        const data = await achievementsApi.getUserAchievements(userData.id);
        setAchievements(data);
      } catch (error) {
        console.error('Error loading achievements:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAchievements();
  }, [userData?.id]);

  const handleRewardClaimed = async () => {
    try {
      setLoading(true);
      if(userData) {
        const data = await achievementsApi.getUserAchievements(userData.id);
        setAchievements(data);
      }
    } catch (error) {
      console.error('Error updating achievements:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div style={{ padding: '16px' }}>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Title level={2}>{t('achievements.title')}</Title>
        {achievements.length > 0 ? (
          <Row gutter={[16, 16]}>
            {achievements.map((achievement) => (
              <Col xs={24} sm={12} md={8} key={achievement.id}>
                <AchievementsCard 
                  achievement={{
                    ...achievement,
                    current_progress: achievement.current_progress,
                    target_progress: achievement.target_progress
                  }} 
                  onRewardClaimed={handleRewardClaimed}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('achievements.no_available')}
          />
        )}
      </Space>
    </div>
  );
};

export default Achievements; 