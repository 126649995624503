import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Pagination,
  Avatar,
  Empty,
  Button,
  Result,
  Select,
  Flex,
} from "antd";
import {
  DollarOutlined,
  TeamOutlined,
  UserOutlined,
  LockOutlined,
  CrownOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { fetchPartnersByLevel } from "../../helpers/api";
import { PartnerByLevel } from "../../types";
import styles from "./Partners.module.css";

const { Title, Text } = Typography;
const { Option } = Select;

const PartnersLevelList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { level } = useParams<{ level: string }>();
  const currentLevel = parseInt(level || "1", 10);

  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState<PartnerByLevel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPartners, setTotalPartners] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [premiumRequired, setPremiumRequired] = useState(false);
  const [sortBy, setSortBy] = useState<string>("total_loans");

  const itemsPerPage = 20;

  useEffect(() => {
    const loadPartners = async () => {
      setIsLoading(true);
      setError(null);
      setPremiumRequired(false);

      try {
        const response = await fetchPartnersByLevel(
          currentLevel,
          currentPage,
          itemsPerPage,
          sortBy
        );

        if ("error" in response) {
          if (response.premium_required) {
            setPremiumRequired(true);
          } else {
            setError(response.error);
          }
          setPartners([]);
          setTotalPartners(0);
        } else {
          setPartners(response.partners);
          setTotalPartners(response.total);
        }
      } catch (error) {
        setError("Failed to load partners");
        setPartners([]);
        setTotalPartners(0);
      } finally {
        setIsLoading(false);
      }
    };

    loadPartners();
  }, [currentLevel, currentPage, sortBy]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
    setCurrentPage(1); // Сбрасываем на первую страницу при изменении сортировки
  };

  const viewPartnerDetails = (userId: number) => {
    navigate(`/partners/${userId}`);
  };

  // Функция для расчета общей суммы займов
  const calculateTotalLoans = () => {
    if (!partners || partners.length === 0) return 0;
    return partners.reduce(
      (sum, partner) => sum + Number(partner.total_loans || 0),
      0
    );
  };

  // Функция для расчета общего количества младших партнеров
  const calculateTotalReferrals = () => {
    if (!partners || partners.length === 0) return 0;
    return partners.reduce(
      (sum, partner) => sum + Number(partner.referral_count || 0),
      0
    );
  };

  if (premiumRequired) {
    return (
      <Result
        icon={<LockOutlined style={{ color: "#007aff" }} />}
        title={t("Premium Feature")}
        subTitle={t(
          "Detailed statistics by level is available only for premium users"
        )}
        extra={
          <Button
            type="primary"
            icon={<CrownOutlined />}
            onClick={() => navigate("/premium")}
            className={styles.notificationButton}
          >
            {t("Get Premium")}
          </Button>
        }
      />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title={t("Error")}
        subTitle={error}
        extra={
          <Button
            type="primary"
            onClick={() => navigate("/partners/statistics")}
          >
            {t("Back to Statistics")}
          </Button>
        }
      />
    );
  }

  return (
    <div className={styles.container}>
      <Flex justify="space-between" align="center" vertical style={{ marginBottom: "24px" }}>
        <Title level={3} className={styles.levelTitle}>
          {t("Partners on Level")} {currentLevel}
        </Title>
        <Select
          value={sortBy}
          className={styles.sortSelect}
          onChange={handleSortChange}
          suffixIcon={<SortAscendingOutlined />}
          loading={isLoading}
        >
          <Option value="total_loans">{t("Sort by Loan Amount")}</Option>
          <Option value="referral_count">{t("Sort by Referrals")}</Option>
        </Select>
      </Flex>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col xs={24} sm={12} md={8}>
          <Card
            className={styles.statsCard}
          >
            <Statistic
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <DollarOutlined style={{ marginRight: "8px" }} />{" "}
                  {t("Total Active Loans")}
                </div>
              }
              value={calculateTotalLoans().toFixed(2)}
              prefix="$"
              valueStyle={{ color: "#007aff" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card
            className={styles.statsCard}
          >
            <Statistic
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TeamOutlined style={{ marginRight: "8px" }} />{" "}
                  {t("Total Referrals")}
                </div>
              }
              value={calculateTotalReferrals()}
              valueStyle={{ color: "#007aff" }}
            />
          </Card>
        </Col>
      </Row>

      {partners.length === 0 ? (
        <Empty description={t("No partners found")} />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {partners.map((partner) => (
              <Col xs={24} sm={12} key={partner.user_id}>
                <Card
                  hoverable
                  className={styles.partnerCard}
                  onClick={() => viewPartnerDetails(partner.user_id)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Avatar
                      size={40}
                      icon={<UserOutlined />}
                      src={partner.avatar_url}
                      className={styles.avatar}
                    />
                    <div
                      className={styles.partnerInfo}
                    >
                      <Text strong className={styles.partnerName}>
                        {partner.first_name} {partner.last_name}
                      </Text>
                      <div>
                        <Text type="secondary">@{partner.username}</Text>
                      </div>
                    </div>
                    <div className={styles.partnerStats}>
                      <Text
                        strong
                        className={styles.loanAmount}
                      >
                        ${Number(partner.total_loans).toFixed(2)}
                      </Text>
                      <Flex align="center" justify="end" className={styles.referralCount}>
                        <TeamOutlined className={styles.referralIcon} />
                        <Text style={{ color: "#52c41a" }}>
                          {partner.referral_count}
                        </Text>
                      </Flex>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <div className={styles.paginationContainer}>
            <Pagination
              current={currentPage}
              total={totalPartners}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PartnersLevelList;
