import { FC, useState, useEffect, useRef } from "react";
import {
  Typography,
  Card,
  message,
  Badge,
  Spin,
  InputNumber,
  Row,
  Col,
  Modal,
  Button,
  List,
  Pagination,
  Select,
  Form,
  Input,
  Space,
  Tooltip,
  Tag,
} from "antd";
import WebApp from "@twa-dev/sdk";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../services/store";
import { updateWebTokens } from "../../services/slices/userSlice";
import {
  QuestionCircleTwoTone,
  FlagOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MessageOutlined,
  GlobalOutlined,
  YoutubeOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { Task } from "../../types";
import { fetchTasks, verifyTaskCode, reportTask } from "../../helpers/api";

const { Title } = Typography;
const { TextArea } = Input;

const SocialIcon: FC<{ link: string; socialType?: string }> = ({ link, socialType }) => {
  const { t } = useTranslation();
  const linkLower = link.toLowerCase();

  if (socialType) {
    switch (socialType) {
      case "telegram":
        return (
          <Tag color="blue">
            <MessageOutlined /> Telegram
          </Tag>
        );
      case "vk":
        return (
          <Tag color="blue">
            <MessageOutlined /> VK
          </Tag>
        );
      case "x":
        return (
          <Tag color="black">
            <TwitterOutlined /> X
          </Tag>
        );
      case "facebook":
        return (
          <Tag color="blue">
            <FacebookOutlined /> Facebook
          </Tag>
        );
      case "linkedin":
        return (
          <Tag color="blue">
            <LinkedinOutlined /> LinkedIn
          </Tag>
        );
      case "medium":
        return (
          <Tag color="black">
            <GlobalOutlined /> Medium
          </Tag>
        );
      case "discord":
        return (
          <Tag color="purple">
            <MessageOutlined /> Discord
          </Tag>
        );
      case "youtube":
        return (
          <Tag color="red">
            <YoutubeOutlined /> YouTube
          </Tag>
        );
      case "miniapps":
        return (
          <Tag color="cyan">
            <AppstoreOutlined /> Mini Apps
          </Tag>
        );
      default:
        return (
          <Tag color="default">
            <GlobalOutlined /> {t("Other")}
          </Tag>
        );
    }
  }

  if (linkLower.includes("t.me/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> Telegram
      </Tag>
    );
  } else if (linkLower.includes("vk.com/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> VK
      </Tag>
    );
  } else if (linkLower.includes("x.com/")) {
    return (
      <Tag color="black">
        <TwitterOutlined /> X
      </Tag>
    );
  } else if (linkLower.includes("facebook.com/")) {
    return (
      <Tag color="blue">
        <FacebookOutlined /> Facebook
      </Tag>
    );
  } else if (linkLower.includes("linkedin.com/")) {
    return (
      <Tag color="blue">
        <LinkedinOutlined /> LinkedIn
      </Tag>
    );
  } else if (linkLower.includes("medium.com/")) {
    return (
      <Tag color="black">
        <GlobalOutlined /> Medium
      </Tag>
    );
  } else if (linkLower.includes("discord.gg/")) {
    return (
      <Tag color="purple">
        <MessageOutlined /> Discord
      </Tag>
    );
  } else if (linkLower.includes("youtube.com/")) {
    return (
      <Tag color="red">
        <YoutubeOutlined /> YouTube
      </Tag>
    );
  }

  return (
    <Tag color="default">
      <GlobalOutlined /> {t("Other")}
    </Tag>
  );
};

const Tasks: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [codeInput, setCodeInput] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [reportReason, setReportReason] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [selectedSocialType, setSelectedSocialType] = useState<string>("all");
  const inputRef = useRef<HTMLInputElement>(null);
  const [form] = Form.useForm();

  const socialMediaTypes = [
    { value: "all", label: t("All") },
    { value: "telegram", label: "Telegram" },
    { value: "vk", label: "VK" },
    { value: "x", label: "X" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
    { value: "medium", label: "Medium" },
    { value: "discord", label: "Discord" },
  ];

  useEffect(() => {
    console.log(
      `useEffect triggered: page=${currentPage}, pageSize=${pageSize}, socialType=${selectedSocialType}`
    );
    fetchTasksData();
  }, [currentPage, pageSize, selectedSocialType, t]);

  const fetchTasksData = async () => {
    setIsLoading(true);
    try {
      console.log(
        `Fetching tasks: page=${currentPage}, pageSize=${pageSize}, socialType=${selectedSocialType}`
      );
      const response = await fetchTasks(
        currentPage,
        pageSize,
        selectedSocialType
      );
      setTasks(response.tasks || []);
      setFilteredTasks(response.tasks || []);
      setTotalTasks(response.pagination?.total_records || 0);
    } catch (error) {
      message.error(t("tasksFetchError"));
    }
    setIsLoading(false);
  };

  const handleSubmitCode = async () => {
    if (!codeInput) {
      message.error(t("codeInputEmptyError"));
      return;
    }

    if (selectedTaskId === null) {
      message.error(t("taskNotSelectedError"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await verifyTaskCode(selectedTaskId, codeInput);

      if (response) {
        const { status, message: responseMessage } = response;
        if (status === "success") {
          dispatch(updateWebTokens(10000));
          message.success(t(responseMessage));

          setTasks((prevTasks) =>
            prevTasks.filter((task) => task.id !== selectedTaskId)
          );

          setFilteredTasks((prevFilteredTasks) =>
            prevFilteredTasks.filter((task) => task.id !== selectedTaskId)
          );
        } else {
          message.error(t(responseMessage));
        }
      } else {
        message.error(t("codeSubmitError"));
      }
    } catch (error: unknown) {
      console.error("Error submitting code:", error);
      message.error(t("codeSubmitError"));
    } finally {
      setIsLoading(false);
      setCodeInput("");
      setIsModalVisible(false);
    }
  };

  const showCodeModal = (taskId: number) => {
    setSelectedTaskId(taskId);
    setIsModalVisible(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCodeInput("");
  };

  const showDescriptionModal = () => {
    setIsDescriptionModalVisible(true);
  };

  const handleCancelDescriptionModal = () => {
    setIsDescriptionModalVisible(false);
  };

  const showReportModal = (taskId: number) => {
    setSelectedTaskId(taskId);
    setIsReportModalVisible(true);
  };

  const handleCancelReportModal = () => {
    setIsReportModalVisible(false);
    setReportReason("");
    form.resetFields();
  };

  const handleSubmitReport = async () => {
    if (!reportReason) {
      message.error(t("reportReasonRequired"));
      return;
    }

    if (selectedTaskId === null) {
      message.error(t("taskNotSelectedError"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await reportTask(selectedTaskId, reportReason);

      if (response && response.status === "success") {
        message.success(t("reportSubmitted"));
        setIsReportModalVisible(false);
        setReportReason("");
        form.resetFields();
      } else {
        message.error(t("reportError"));
      }
    } catch (error) {
      console.error("Error reporting task:", error);
      message.error(t("reportError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = (value: string) => {
    if (codeInput.length < 10) {
      setCodeInput(codeInput + value);
    }
  };

  const handleClear = () => {
    setCodeInput("");
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSocialTypeChange = (value: string) => {
    console.log(`Changing social type to ${value}`);
    setSelectedSocialType(value);
    setCurrentPage(1);
  };

  const handleOpenLink = (task: Task) => {
    const link = task.social_media_link;
    const socialType = task.social_type || "other";

    if (socialType === "telegram" || socialType === "miniapps" || link.includes("t.me/")) {
      WebApp.openTelegramLink(link);
    } else {
      WebApp.openLink(link);
    }
  };

  if (isLoading && tasks.length === 0) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Button
        type="link"
        icon={<QuestionCircleTwoTone />}
        onClick={showDescriptionModal}
        style={{ float: "right" }}
      />
      <Title level={3}>{t("tasksTitle")}</Title>

      <div style={{ marginBottom: 16 }}>
        <Row gutter={16} align="middle">
          <Col xs={24} sm={12} md={8}>
            <Select
              style={{ width: "100%" }}
              value={selectedSocialType}
              onChange={handleSocialTypeChange}
              options={socialMediaTypes}
              placeholder={t("Select social media type")}
              loading={isLoading}
            />
          </Col>
        </Row>
      </div>

      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={filteredTasks}
        loading={isLoading}
        renderItem={(task) => (
          <List.Item key={task.id}>
            <Badge.Ribbon text="+10 000 $WEB">
              <Card
                className="buttons"
                actions={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 16px",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <Typography.Text
                        key="check"
                        onClick={() => showCodeModal(task.id)}
                      >
                        {t("Check")}
                      </Typography.Text>
                    </div>
                    <div>
                      <Tooltip title={t("Report problem")}>
                        <Typography.Text
                          key="report"
                          type="danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            showReportModal(task.id);
                          }}
                          style={{ fontSize: "16px", marginLeft: "8px" }}
                        >
                          <FlagOutlined />
                        </Typography.Text>
                      </Tooltip>
                    </div>
                  </div>,
                ]}
              >
                <div className="card">
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <SocialIcon link={task.social_media_link} socialType={task.social_type} />
                    <Typography.Link
                      strong
                      onClick={() => handleOpenLink(task)}
                    >
                      {task.task_name}
                    </Typography.Link>
                  </Space>
                </div>
              </Card>
            </Badge.Ribbon>
          </List.Item>
        )}
        locale={{
          emptyText: (
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              {selectedSocialType !== "all" ? (
                <>
                  <Typography.Title level={5}>
                    {t("No tasks found")}
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    {t(
                      "No tasks available for this social network. Try another filter or check back later."
                    )}
                  </Typography.Text>
                </>
              ) : (
                <>
                  <Typography.Title level={5}>
                    {t("All tasks completed")}
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    {t(
                      "You have completed all available tasks. Check back later for new tasks."
                    )}
                  </Typography.Text>
                </>
              )}
            </div>
          ),
        }}
      />

      {totalTasks > pageSize && (
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalTasks}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={(current, size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
          />
        </div>
      )}

      <Modal
        title={t("verify code")}
        open={isModalVisible}
        onOk={handleSubmitCode}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <InputNumber
          placeholder={t("enter code")}
          value={codeInput}
          onChange={(value) => setCodeInput(value?.toString() || "")}
          style={{ width: "100%", marginBottom: 16 }}
        />

        <Row gutter={[16, 16]} justify={"center"}>
          {[...Array(10)].map((_, i) => (
            <Col key={i}>
              <Button onClick={() => handleButtonClick(i.toString())}>
                {i}
              </Button>
            </Col>
          ))}
          <Col>
            <Button onClick={handleClear}>{t("Clear")}</Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={t("Task Instructions")}
        open={isDescriptionModalVisible}
        onOk={handleCancelDescriptionModal}
        onCancel={handleCancelDescriptionModal}
        footer={[
          <Button key="back" onClick={handleCancelDescriptionModal}>
            {t("Close")}
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          {" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("TaskDescription"),
            }}
          />
        </Typography.Paragraph>
      </Modal>

      <Modal
        title={t("Report Task Problem")}
        open={isReportModalVisible}
        onOk={handleSubmitReport}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={handleCancelReportModal}
        confirmLoading={isLoading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="reason"
            label={t("Problem description")}
            rules={[
              { required: true, message: t("Please describe the problem") },
            ]}
          >
            <TextArea
              rows={4}
              placeholder={t("Describe the issue with this task")}
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Tasks;
