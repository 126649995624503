import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Typography, Space,  Spin } from "antd";
import TonBalance from "./TonBalance";
import { useTonWallet } from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";
import { fetchTonData } from "../services/slices/tonSlice";
import { RootState, AppDispatch } from "../services/store";
import { WalletOutlined, GiftOutlined, TeamOutlined } from "@ant-design/icons";
import { fetchWebPrice } from '../services/slices/webPriceSlice';

const { Text } = Typography;

const Balances: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const wallet = useTonWallet();
  const navigate = useNavigate();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const { currentPrice, isLoading: priceLoading } = useSelector((state: RootState) => state.webPrice);

  useEffect(() => {
    dispatch(fetchTonData(null));
    dispatch(fetchWebPrice());
  }, [dispatch]);

  const calculateWebValue = () => {
    if (!userData?.web_tokens || !currentPrice) return 0;
    return (userData.web_tokens * currentPrice).toFixed(2);
  };

  return (
    <Space direction="vertical" size={8} style={{ width: "100%"}}>
      <Text strong style={{ fontSize: "16px" }}>
        {t("real balance")}
      </Text>

      {wallet && <TonBalance />}

      <Card
        size="small"
        onClick={() => navigate("/loan-issuance", { state: { useBonus: true } })}
        style={{
          background: "#f8fafc",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        className="hover-card"
      >
        <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space size={4}>
            <GiftOutlined style={{ fontSize: "16px", color: "#1890ff" }} />
            <Text style={{ fontSize: "13px" }}>{t("bonus balance")}</Text>
          </Space>
          <Text strong style={{ fontSize: "13px" }}>
            {isLoading ? <Spin size="small" /> : `$${userData?.bonus_balance ?? 0}`}
          </Text>
        </Space>
      </Card>

      <Card
        size="small"
        onClick={() => navigate("/withdraw")}
        style={{
          background: "#f8fafc",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        className="hover-card"
      >
        <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space>
            <WalletOutlined style={{ fontSize: "16px", color: "#52c41a" }} />
            <Text style={{ fontSize: "13px" }}>{t("balance")}</Text>
          </Space>
          <Text strong style={{ fontSize: "13px" }}>
            {isLoading ? <Spin size="small" /> : `$${userData?.real_balance ?? 0}`}
          </Text>
        </Space>
      </Card>

      <Card
        size="small"
        onClick={() => navigate("/partners")}
        style={{
          background: "#f8fafc",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        className="hover-card"
      >
        <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space>
            <TeamOutlined style={{ fontSize: "16px", color: "#722ed1" }} />
            <Text style={{ fontSize: "13px" }}>{t("Partner Balance")}</Text>
          </Space>
          <Text strong style={{ fontSize: "13px" }}>
            {isLoading ? <Spin size="small" /> : `$${userData?.partner_balance ?? 0}`}
          </Text>
        </Space>
      </Card>

        <Card
          size="small"
          onClick={() => navigate("/web-tokens")}
          style={{
            background: "#f8fafc",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          className="hover-card"
        >
          <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
            <Space size={4}>
              <Space size={4}>
                <img src="./assets/LogoW.png" alt="Logo" width={16} style={{ marginRight: 4 }} />
                <Text style={{ fontSize: "13px" }}>$WEB</Text>
              </Space>
            </Space>
            <Text strong style={{ fontSize: "13px" }}>
              {isLoading ? (
                <Spin size="small" />
              ) : (
                <>
                  {userData?.web_tokens ?? 0}
                  {!priceLoading && currentPrice > 0 && (
                    <Text type="secondary" style={{ fontSize: "11px", marginLeft: 4 }}>
                      (≈${calculateWebValue()})
                    </Text>
                  )}
                </>
              )}
            </Text>
          </Space>
        </Card>
    </Space>
  );
};

// Обновим стили для hover эффекта
const styleSheet = document.createElement("style");
styleSheet.innerText = `
  .hover-card:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  }
`;
document.head.appendChild(styleSheet);

export default Balances;
