import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WebSocketState {
  connected: boolean;
  lastPing: number | null;
}

const initialState: WebSocketState = {
  connected: false,
  lastPing: null,
};

const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    updateLastPing: (state) => {
      state.lastPing = Date.now();
    },
    sendMessage: (state, action: PayloadAction<string>) => {
      // Этот reducer может быть пустым, так как обработка происходит в middleware
    },
  },
});

export const { setConnected, updateLastPing } = websocketSlice.actions;
export default websocketSlice.reducer;