import { useEffect, ReactNode } from "react";
import { TonConnectButton } from "@tonconnect/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../services/store";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { fetchWebPrice } from "../services/slices/webPriceSlice";
import "./Page.css";

interface PageProps {
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const activeUsers = useSelector((state: RootState) => state.app.activeUsers);
  const { currentPrice } = useSelector((state: RootState) => state.webPrice);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchWebPrice());
  }, [location, dispatch]);

  return (
    <div className="page-container">
      <div>
        <div className="header-container">
          <img
            src="/assets/logo.png"
            alt="Logo"
            width={150}
            onClick={() => navigate("/")}
          />
          <TonConnectButton />
        </div>

        {children}
      </div>

      <div className="footer-stats">
        <Space size={10}>
          <Space>
            <UsergroupAddOutlined className="stats-icon" />
            <span>{activeUsers}</span>
          </Space>
          <Space>
            <img src="/assets/LogoW.png" alt="Logo" className="stats-icon" />
            <span>~${currentPrice ? parseFloat(currentPrice.toFixed(7)).toString() : "0.0000000"}</span>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default Page;
