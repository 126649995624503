import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  activeUsers: number;
}

const initialState: AppState = {
  activeUsers: 0,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveUsers(state, action: PayloadAction<number>) {
      state.activeUsers = action.payload;
    },
  },
});

export const { setActiveUsers } = appSlice.actions;
export default appSlice.reducer; 