import { FC } from "react";
import { Typography, Card, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CreateTaskForm from "./CreateTaskForm";
import styles from "./UserTasks.module.css";

const { Title } = Typography;

const CreateTask: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTaskCreated = () => {
    navigate("/user-tasks");
  };


  return (
    <div className={styles["create-task-page"]}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div className={styles["page-header"]}>
          <Title level={3}>{t("createNewTask")}</Title>
        </div>

        <Card className={styles["create-task-card"]}>
          <CreateTaskForm onTaskCreated={handleTaskCreated} />
        </Card>
      </Space>
    </div>
  );
};

export default CreateTask;