import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Select,
  message,
  Card,
  Flex,
  Spin,
} from "antd";
import {
  DollarOutlined,
  CalendarOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { getAvailableAmountToInvest, InvestPartners } from "../../helpers/api";
import { updatePartnerBalance } from "../../services/slices/userSlice";
import { RootState, AppDispatch } from "../../services/store";
import styles from "./Partners.module.css";

const { Title, Text } = Typography;

const InvestPartnerBalance: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const [amount, setAmount] = useState<number>(0);
  const [term, setTerm] = useState<number>(3);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAvailableAmount = async () => {
    setLoading(true);
    try {
      const data = await getAvailableAmountToInvest();
      setAmount(data.available_withdrawal_amount);
    } catch (error) {
      console.error("Error fetching available amount", error);
      message.error(t("Error fetching available amount"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableAmount();
  }, []);

  const handleButton = () => {
    if (amount <= 0) {
      message.warning(t("No funds available to invest"));
      return;
    }

    setDisabled(true);
    const loanData = {
      amount,
      term,
    };

    InvestPartners(loanData)
      .then((response) => {
        setDisabled(false);
        if (response.error) {
          message.error(t(response.error));
        } else {
          message.success(t("Loan issued successfully"));
          setAmount(0);
          dispatch(updatePartnerBalance(amount));
        }
      })
      .catch((error) => {
        setDisabled(false);
        message.error(t("Error issuing loan"));
      });
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.investContainer}>
      <Card className={styles.investCard}>
        <Title level={5} className={styles.investTitle}>
          {t("Invest Partner Balance In Margin Loans")}
        </Title>

        <div className={styles.balanceItem}>
          <Flex align="center">
            <PercentageOutlined
              className={styles.blueIcon}
              style={{ marginRight: 8 }}
            />
            <Text className={styles.balanceLabel}>{t("Rate")}</Text>
          </Flex>
          <Text className={styles.balanceValue}>
            {userData?.settings.margin_rate}%
          </Text>
        </div>

        <div className={styles.balanceItem}>
          <Flex align="center">
            <DollarOutlined
              className={styles.blueIcon}
              style={{ marginRight: 8 }}
            />
            <Text className={styles.balanceLabel}>{t("Available amount")}</Text>
          </Flex>
          <Text className={styles.balanceValue}>${amount}</Text>
        </div>

        <Flex align="center" style={{ marginTop: 24 }}>
          <CalendarOutlined
            className={styles.blueIcon}
            style={{ marginRight: 8 }}
          />
          <Text className={styles.balanceLabel}>{t("Term")}</Text>
        </Flex>

        <Select
          defaultValue={3}
          options={[
            { value: 3, label: <span>3 {t("days")}</span> },
            { value: 7, label: <span>7 {t("days")}</span> },
          ]}
          onChange={(value) => setTerm(Number(value))}
          className={styles.termSelect}
        />

        <Button
          type="primary"
          onClick={handleButton}
          disabled={disabled || amount <= 0}
          className={styles.investButton}
          icon={<DollarOutlined />}
        >
          {t("Invest")} ${amount}
        </Button>

      </Card>
    </div>
  );
};

export default InvestPartnerBalance;
