import axios from "axios";
import {
  LoanFormValues,
  UserResponse,
  WithdrawalData,
  PaymentWalletResponse,
  partnerLoanData,
  PartnerTransactionResponse,
  LoanDetails,
  Winner,
  MessageResponse,
  ChatsResponse,
  PartnerDetailsResponse,
  PostsResponse,
  Achievement,
  ClaimRewardResponse,
  PartnersByLevelResponse,
  PremiumDataResponse,
  UserTasksResponse,
  TaskCompletionResponse,
  CreateTaskFormValues,
  TaskCreationResponse,
  UserTask,
  TasksResponse,
  TaskVerificationResponse,
  TaskReportResponse,
} from "../types";

export const validateHash = async (hash: string): Promise<boolean> => {
  try {
    await axios.post("/api/validate-hash.php", { hash });
    return true;
  } catch (error) {
    console.error("Error validating hash:", error);
    return false;
  }
};

export const addUser = async (
  userData: any,
  startParam: string,
  initData: string
): Promise<UserResponse> => {
  const response = await axios.post("/api/add-user.php", {
    ...userData,
    startParam,
    initData,
  });
  return response.data;
};

export const saveWalletToDatabase = async (walletData: any) => {
  try {
    const response = await axios.post("/api/saveTonWallet.php", walletData);
    if (response.status !== 200) {
      console.error("Failed to save wallet");
    }
  } catch (error) {
    console.error("Error saving wallet:", error);
  }
};

export const getTransactions = async (page: number, pageSize: number) => {
  try {
    const response = await axios.post("/api/webTokens/getTransactions.php", {
      page: page,
      pageSize: pageSize,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

// Функция для получения займов
export const fetchLoans = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post(`/api/loans/loans.php`, {
      page: page,
      itemsPerPage: itemsPerPage,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching loans", error);
    throw error;
  }
};

// Функция для создания займа
export const createLoan = async (data: LoanFormValues) => {
  try {
    const response = await axios.post("/api/loans/create_loan.php", data);
    return response.data;
  } catch (error) {
    console.error("Error submitting loan", error);
    throw error;
  }
};
// Функция для отмены займа
export const cancelLoan = async (loanId: number) => {
  try {
    const response = await axios.post(
      "/api/loans/cancel_loan.php",
      {
        loanId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error cancelling loan");
  }
};

export const createInvoice = async (wallet: string, amount: number) => {
  try {
    const response = await axios.post("/api/nft/getLinkToPay.php", {
      wallet,
      amount,
    });

    if (response.data.status === "success") {
      return response.data.invoice_url;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Failed to create invoice");
  }
};

export async function getJettonBalance(address: string) {
  const response = await fetch(
    `https://tonapi.io/v2/accounts/${address}/jettons/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs`
  );
  const data = await response.json();
  return data.balance;
}

export const fetchUserLoans = async (
  page: number,
  pageSize: number,
  status?: string,
  isOnDemand?: boolean,
  type?: string
) => {
  const response = await axios.post("/api/dashboard/user-loans.php", {
    page,
    itemsPerPage: pageSize,
    status,
    isOnDemand,
    type,
  });
  return response.data;
};

export const fetchUserTransactions = async (
  page: number,
  itemsPerPage: number,
  type?: string
) => {
  try {
    const response = await axios.post("/api/dashboard/user-transactions.php", {
      page,
      itemsPerPage,
      type,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching user transactions");
  }
};

export const changeLoanRate = async (loanId: number, newRate: number) => {
  try {
    const response = await axios.post("/api/change-loan-rate.php", {
      loanId,
      newRate,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error changing loan rate");
  }
};

export const fetchWithdrawals = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post("/api/withdraw/get-withdrawals.php", {
      page,
      itemsPerPage,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching withdrawals:", error);
    throw error;
  }
};

export const getBalance = async (address: string): Promise<number | null> => {
  const response = await fetch(
    `https://toncenter.com/api/v3/account?address=${address}`
  );
  if (!response.ok) {
    return null;
  }
  const data = await response.json();
  const nanotonBalance = parseInt(data.balance, 10);
  return nanotonBalance / 1e9;
};

export const getTonPrice = async (): Promise<number> => {
  const response = await fetch(
    "https://tonapi.io/v2/rates?tokens=ton&currencies=usd"
  );
  if (!response.ok) {
    throw new Error(`Failed to fetch`);
  }
  const data = await response.json();
  if (data.rates && data.rates.TON) {
    return data.rates.TON.prices.USD;
  }
  throw new Error("Failed to fetch TON price");
};

export const fetchLoansData = async ({
  tab,
  page,
  pageSize,
  amount,
  rating,
  insured,
}: {
  tab: "take" | "give";
  page: number;
  pageSize: number;
  amount?: number;
  rating?: number;
  insured?: boolean;
}) => {
  try {
    const response = await axios.post("/api/loans/fetch_pending_loans.php", {
      tab,
      page,
      pageSize,
      amount,
      rating,
      insured,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching user loans");
  }
};

export const getAvailableAmount = async () => {
  try {
    const response = await axios.post("/api/withdraw/get-available-amount.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const withdrawFunds = async (withdrawalData: WithdrawalData) => {
  try {
    const response = await axios.post(
      "/api/withdraw/withdraw-funds.php",
      withdrawalData
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const fetchPartners = async (page: number, itemsPerPage: number) => {
  try {
    const response = await axios.post("/api/partners/get-partners.php", {
      page,
      itemsPerPage,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching withdrawals:", error);
    throw error;
  }
};

export const fetchTaps = async () => {
  try {
    const response = await axios.post("/api/game/taps.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching taps");
  }
};

export const getNextAvailableTapTime = async (userId: number) => {
  try {
    const response = await axios.get(`/api/game/getNextAvailableTapTime.php`, {
      params: { userId },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching taps");
  }
};

export const fetchStatistics = async () => {
  try {
    const response = await axios.post("/api/statistics/get-statistics.php");
    return response.data;
  } catch (error) {
    console.error("Error fetching statistics:", error);
    throw error;
  }
};

export async function getJettonWalletAddress(
  userWalletAddress: string,
  jettonMasterAddress: string
) {
  try {
    const url = `https://tonapi.io/v2/accounts/${userWalletAddress}/jettons/${jettonMasterAddress}?currencies=ton,usd`;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(`Error fetching wallet address: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error fetching wallet address:", error);
  }
}

export const getPaymentWallet = async (
  walletAddress: string
): Promise<PaymentWalletResponse> => {
  try {
    const response = await axios.post<PaymentWalletResponse>(
      "/api/payments/get-payment-wallet.php",
      { current_wallet: walletAddress }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching payment wallet:", error);
    throw error;
  }
};

export const getAvailableAmountToInvest = async () => {
  try {
    const response = await axios.post(
      "/api/partners/get-available-amount-to-invest.php"
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const getAvailableAmountToWithdraw = async () => {
  try {
    const response = await axios.post(
      "/api/withdraw/get-available-amount-to-withdraw.php"
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const InvestPartners = async (partnerLoanData: partnerLoanData) => {
  try {
    const response = await axios.post(
      "/api/loans/invest_partners_loans.php",
      partnerLoanData
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const getNotifications = async (
  page: number = 1,
  limit: number = 10
) => {
  try {
    const response = await axios.post(
      "/api/notifications/get_notifications.php",
      { page, limit }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const getReadNotifications = async (
  page: number = 1,
  limit: number = 10,
  timeRange: string = "month"
) => {
  try {
    const response = await axios.post(
      "/api/notifications/get_read_notifications.php",
      { page, limit, timeRange }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching read notifications:", error);
    throw error;
  }
};

export const notificationsAsRead = async (notificationIds: number[] = []) => {
  try {
    const response = await axios.post(
      "/api/notifications/notifications_as_read.php",
      { notification_ids: notificationIds }
    );
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

export const getPendingAmount = async () => {
  try {
    const response = await axios.post("/api/withdraw/get-pending-amount.php");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching");
  }
};

export const fetchLoanDetails = async (loanId: number) => {
  try {
    const response = await axios.get(`/node/api/loans/${loanId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching loan details:", error);
    throw error;
  }
};

export const fetchCertificateDetails = async (loanId: number) => {
  try {
    const response = await axios.post(`/api/certificates/get_certificate.php`, {
      loanId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching loan details:", error);
    throw error;
  }
};

export const fetchSellLoans = async (loanId: number, price: number | null) => {
  try {
    const response = await axios.post(`/api/loans/sell_loan.php`, {
      loanId,
      price,
    });
    return response.data;
  } catch (error) {
    console.error("Error sell loan:", error);
    throw error;
  }
};

export const fetchRemoveSellLoans = async (loanId: number) => {
  try {
    const response = await axios.post(`/api/loans/remove_sell_loan.php`, {
      loanId,
    });
    return response.data;
  } catch (error) {
    console.error("Error sell loan:", error);
    throw error;
  }
};

export const getMessageId = async (userId: number) => {
  try {
    const response = await axios.post("/node/api/telegram/saveMessage", {
      userId,
    });
    return response.data.messageId;
  } catch (error) {
    console.error("Error fetching message ID");
    throw error;
  }
};

export const sendRewardForSharing = async () => {
  try {
    const response = await axios.post("/api/partners/sendRewardForSharing.php");
    return response.data;
  } catch (error) {
    console.error("Error sending reward:", error);
    return { success: false };
  }
};

export const fetchPartnersTransactions = async (
  page: number,
  perPage: number
): Promise<PartnerTransactionResponse> => {
  const response = await axios.get<PartnerTransactionResponse>(
    `/api/partners/get-partner-overnight.php`,
    {
      params: {
        page,
        per_page: perPage,
      },
    }
  );
  return response.data;
};

export const fetchCertificates = async (currency: "TON" | "USDT") => {
  const response = await axios.get<{ certificates: LoanDetails[] }>(
    "/api/certificates/get_sales_certificates.php",
    {
      params: { currency },
    }
  );
  return response.data.certificates;
};

export const getSellerWallet = async (loanId: number) => {
  const response = await axios.get(`/api/certificates/get-seller-wallet.php`, {
    params: { loanId },
  });
  return response.data;
};

export const confirmPayment = async (
  loanId: number,
  userFriendlyAddress: string,
  hash: string
) => {
  const response = await axios.post(`/api/certificates/confirm-payment.php`, {
    loanId,
    userFriendlyAddress,
    hash,
  });
  return response.data;
};

export const fetchOvernightVolume = async (period: "7d" | "30d" = "7d") => {
  try {
    const response = await axios.get("/node/api/overnight-volume", {
      params: { period },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching overnight volume:", error);
    throw error;
  }
};

export const returnDemandLoan = async (loanId: number) => {
  try {
    const response = await axios.post("/api/loans/return-demand-loan.php", {
      loanId,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error returning demand loan");
  }
};

export const fetchLastAuctionWinners = async (): Promise<Winner[]> => {
  try {
    const response = await axios.get("/node/api/auction/last-winners");
    return response.data.winners;
  } catch (error) {
    console.error("Error fetching last auction winners:", error);
    throw new Error("Failed to fetch last auction winners");
  }
};

export const fetchAuctionState = async () => {
  try {
    const response = await axios.get("/node/api/auction/state");
    return response.data;
  } catch (error) {
    console.error("Error fetching auction state:", error);
    throw error;
  }
};

export const fetchContestResults = async () => {
  const response = await axios.get("/api/contest/get-results.php");
  return response.data;
};

export const fetchLastWeekWinners = async () => {
  const response = await axios.get("/api/contest/get-last-winners.php");
  return response.data;
};

export const fetchUserChats = async (): Promise<ChatsResponse> => {
  try {
    const response = await axios.get("/api/messages/get-chats.php");
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch chats");
  }
};

export const fetchMessages = async (
  userId: string
): Promise<MessageResponse> => {
  try {
    const response = await axios.get(`/api/messages/get-messages.php`, {
      params: { user_id: userId },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch messages");
  }
};

export const sendMessage = async (
  receiverId: string,
  encryptedMessage: string,
  iv: string
): Promise<void> => {
  try {
    await axios.post("/api/messages/send-message.php", {
      receiver_id: receiverId,
      content: encryptedMessage,
      iv: iv,
    });
  } catch (error) {
    throw new Error("Failed to send message");
  }
};

export const fetchPartnerDetails = async (
  userId: string
): Promise<PartnerDetailsResponse> => {
  try {
    const response = await axios.post("/api/partners/get-partner-details.php", {
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching partner details:", error);
    throw error;
  }
};

export const subscribeToPartner = async (
  userId: string,
  action: "subscribe" | "unsubscribe"
) => {
  try {
    const response = await axios.post("/api/partners/subscribe.php", {
      user_id: userId,
      action: action,
    });
    return response.data;
  } catch (error) {
    console.error(`Error trying to ${action}:`, error);
    throw error;
  }
};

export const fetchUserPosts = async (
  userId: number,
  offset: number,
  limit: number
): Promise<PostsResponse> => {
  try {
    const response = await axios.get(
      `/api/account/get_posts.php?user_id=${userId}&offset=${offset}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    throw error;
  }
};

export const getPostMessageId = async (
  userId: number,
  postId: number
): Promise<string> => {
  try {
    const response = await axios.post("/node/api/telegram/savePost", {
      userId,
      postId,
    });
    return response.data.message_id;
  } catch (error) {
    console.error("Error getting message id:", error);
    throw error;
  }
};

export const fetchPremiumData = async (): Promise<PremiumDataResponse> => {
  try {
    const response = await axios.get("/api/premium/get_premium_data.php");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch premium data:", error);
    throw error;
  }
};

export const activateTrialPremium = async (): Promise<{
  success: boolean;
  message: string;
}> => {
  try {
    const response = await axios.post(
      "/api/premium/activate_trial_premium.php"
    );
    return response.data;
  } catch (error) {
    console.error("Failed to activate trial premium:", error);
    throw error;
  }
};

export const fetchPartnerStatistics = async () => {
  try {
    const response = await axios.post(
      "/api/partners/get-partners-statistics.php"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching statistics:", error);
    throw error;
  }
};

export const fetchPartnersByLevel = async (
  level: number,
  page: number = 1,
  itemsPerPage: number = 20,
  sortBy: string = "total_loans"
): Promise<
  PartnersByLevelResponse | { error: string; premium_required?: boolean }
> => {
  try {
    const response = await axios.post(
      "/api/partners/get-partners-by-level.php",
      {
        level,
        page,
        itemsPerPage,
        sortBy,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching partners by level:", error);
    if (axios.isAxiosError(error) && error.response) {
      return {
        error: error.response.data.error || "Failed to fetch partners by level",
        premium_required: error.response.data.premium_required || false,
      };
    }
    return { error: "Failed to fetch partners by level" };
  }
};

export const notifyPartnerChildren = async () => {
  try {
    const response = await axios.post("/api/partners/notify-children.php");
    return response.data;
  } catch (error) {
    console.error("Error sending notification:", error);
    throw error;
  }
};

export const achievementsApi = {
  getUserAchievements: async (userId: number): Promise<Achievement[]> => {
    const { data } = await axios.post<Achievement[]>(
      "/api/achievements/get_user_achievements.php"
    );
    return data;
  },

  claimReward: async (achievementId: number): Promise<ClaimRewardResponse> => {
    const { data } = await axios.post<ClaimRewardResponse>(
      "/api/achievements/claim_reward.php",
      { achievementId }
    );
    return data;
  },
};

export const changeSeniorPartner = async (newSeniorPartnerId: number) => {
  try {
    const response = await axios.post(
      "/api/partners/change-senior-partner.php",
      {
        new_senior_partner_id: newSeniorPartnerId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing senior partner:", error);
    throw error;
  }
};

export const fetchTopSeniorPartners = async () => {
  try {
    const response = await axios.post(
      "/api/partners/get-top-senior-partners.php"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching top senior partners:", error);
    throw error;
  }
};

export const fetchUserTasks = async (
  page: number = 1,
  pageSize: number = 10,
  status?: string,
  socialType?: string
): Promise<UserTasksResponse> => {
  try {
    const params = new URLSearchParams();
    params.append("action", "list");
    if (page) params.append("page", page.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (status) params.append("status", status);
    if (socialType && socialType !== "all")
      params.append("socialType", socialType);

    const response = await axios.get(
      `/api/tasks/user_tasks.php?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user tasks:", error);
    throw error;
  }
};

export const completeUserTask = async (
  taskId: number,
  verificationCode?: string
): Promise<TaskCompletionResponse> => {
  const response = await axios.post(
    `/api/tasks/user_tasks.php?action=complete`,
    {
      task_id: taskId,
      verification_code: verificationCode,
    }
  );
  return response.data;
};

export const createUserTask = async (
  taskData: CreateTaskFormValues
): Promise<TaskCreationResponse> => {
  const response = await axios.post(
    `/api/tasks/user_tasks.php?action=create`,
    taskData
  );
  return response.data;
};

export const fetchCreatedTasks = async (
  page: number = 1,
  pageSize: number = 10,
  status?: string,
  socialType?: string
): Promise<UserTasksResponse> => {
  try {
    const params = new URLSearchParams();
    params.append("action", "created_tasks");
    if (page) params.append("page", page.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (status) params.append("status", status);
    if (socialType && socialType !== "all")
      params.append("socialType", socialType);

    const response = await axios.get(
      `/api/tasks/user_tasks.php?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching created tasks:", error);
    throw error;
  }
};

export const fetchTaskDetails = async (taskId: number): Promise<UserTask> => {
  try {
    const response = await axios.get(
      `/api/tasks/user_tasks.php?action=task_details&task_id=${taskId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching task details:", error);
    throw error;
  }
};

export const moderateTask = async (
  taskId: number,
  action: "approve" | "reject",
  reason?: string
): Promise<{ success: boolean; message: string }> => {
  const response = await axios.post(
    `/api/tasks/user_tasks.php?action=moderate`,
    {
      task_id: taskId,
      action,
      reason,
    }
  );
  return response.data;
};

export const fetchReferralContestResults = async () => {
  try {
    const response = await axios.post(
      "/api/contest/referral-contest.php?action=current"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching referral contest results:", error);
    throw error;
  }
};

export const fetchLastDayReferralWinners = async (page = 1, pageSize = 5) => {
  try {
    const response = await axios.post(
      "/api/contest/referral-contest.php?action=winners",
      {
        page,
        pageSize,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching referral winners:", error);
    throw error;
  }
};

export const fetchTasks = async (
  page: number = 1,
  pageSize: number = 10,
  socialType: string = "all"
): Promise<TasksResponse> => {
  const response = await axios.post(`/api/tasks/tasks.php?action=list`, {
    page,
    pageSize,
    socialType,
  });
  return response.data;
};

export const verifyTaskCode = async (
  taskId: number,
  code: string
): Promise<TaskVerificationResponse> => {
  const response = await axios.post("/api/tasks/tasks.php?action=check_code", {
    task_id: taskId,
    code,
  });
  return response.data;
};

export const reportTask = async (
  taskId: number,
  reason: string
): Promise<TaskReportResponse> => {
  const response = await axios.post("/api/tasks/tasks.php?action=report_task", {
    task_id: taskId,
    reason,
  });
  return response.data;
};

export const confirmSellerPayment = async (loanId: number): Promise<void> => {
  try {
    const response = await axios.post("/api/certificates/confirm-seller.php", {
      loanId,
    });

    if (response.data.status !== "success") {
      throw new Error(response.data.message || "Failed to confirm payment");
    }
  } catch (error) {
    console.error("Error confirming seller payment:", error);
    throw error;
  }
};

export const reportPaymentIssue = async (
  loanId: number,
  reason: string
): Promise<void> => {
  try {
    const response = await axios.post("/api/certificates/report-payment.php", {
      loanId,
      reason,
    });

    if (response.data.status !== "success") {
      throw new Error(
        response.data.message || "Failed to report payment issue"
      );
    }
  } catch (error) {
    console.error("Error reporting payment issue:", error);
    throw error;
  }
};
