import {FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spin, Button, message } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import Post from "./Post/Post";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { PostType } from "../types";
import { useTranslation } from "react-i18next";
import { getPostMessageId } from "../helpers/api";
import WebApp from "@twa-dev/sdk";

const SinglePost: FC = () => {
  const { t } = useTranslation();
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<PostType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const { userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/account/get-single-post.php",
          {
            post_id: postId,
          }
        );
        setPost(response.data.post);
      } catch (error) {
        console.error("Failed to fetch post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const handleShare = async () => {
    try {
      if (WebApp.initDataUnsafe?.user?.id && post) {
        setShareLoading(true);
        const userId = WebApp.initDataUnsafe.user.id;
        const messageId = await getPostMessageId(userId, post.id);

        WebApp.shareMessage(messageId, async (success) => {
          if (success) {
            message.success(t("Successfully shared the post!"));
          } else {
            message.error(t("Failed to share the message."));
          }
          setShareLoading(false);
        });
      }
    } catch (error) {
      message.error(t("Error sharing message"));
      setShareLoading(false);
    }
  };

  if (loading) {
    return <div className="loader-container"><Spin size="large" /></div>;
  }

  if (!post) {
    return <p>Post not found</p>;
  }

  return (
    <>
      <Post
        key={post.id}
        first_name={post.first_name}
        last_name={post.last_name}
        avatarUrl={post.avatar_url}
        content={post.content}
        imageUrl={post.image_url}
        likes={post.likes}
        comments_count={post.comments_count}
        postId={post.id}
        postTime={post.created_at}
        user_id={post.user_id}
        userId={userData!.id}
        poll={post.poll}
        liked_by_user={post.liked_by_user}
        view_count={post.view_count}
      />
      <Button
        type="primary"
        icon={<ShareAltOutlined />}
        loading={shareLoading}
        onClick={handleShare}
        style={{ marginTop: 16, width: '100%' }}
      >
        {t("Share Post")}
      </Button>
    </>
  );
};

export default SinglePost;
