import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  List,
  Avatar,
  Typography,
  Spin,
  Empty,
  Card,
  Tabs,
  Pagination,
  Button,
} from "antd";
import { useDispatch } from "react-redux";
import { updateUnreadNotifications } from "../../services/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../services/store";
import {
  getNotifications,
  getReadNotifications,
  notificationsAsRead,
} from "../../helpers/api";
import {
  UserOutlined,
  RightOutlined,
  BellOutlined,
  HistoryOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Notification, PaginationData } from "../../types";
import styles from "./Notifications.module.css";

const { Paragraph, Title, Link } = Typography;
const { TabPane } = Tabs;

const Notifications: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [readNotifications, setReadNotifications] = useState<Notification[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [readLoading, setReadLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    total_pages: 0,
    current_page: 1,
    limit: 10,
  });
  const [readPagination, setReadPagination] = useState<PaginationData>({
    total: 0,
    total_pages: 0,
    current_page: 1,
    limit: 10,
  });
  const [markingAsRead, setMarkingAsRead] = useState(false);

  const fetchNotificationsData = async (page: number = 1) => {
    try {
      setLoading(true);
      const response = await getNotifications(page);
      setNotifications(response.notifications);
      setPagination(response.pagination);

      dispatch(updateUnreadNotifications(response.pagination.total));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReadNotificationsData = async (page: number = 1) => {
    try {
      setReadLoading(true);
      const response = await getReadNotifications(page);
      setReadNotifications(response.notifications);
      setReadPagination(response.pagination);
    } catch (error) {
      console.error("Error fetching read notifications:", error);
    } finally {
      setReadLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationsData();
  }, [dispatch]);

  useEffect(() => {
    if (activeTab === "2") {
      fetchReadNotificationsData();
    }
  }, [activeTab]);

  const handleNotificationClick = (notification: Notification) => {
    if (!notification.is_read) {
      markNotificationAsRead(notification.id);
    }

    switch (notification.type) {
      case "new_message":
        navigate(`/chat/${notification.sender?.id}`);
        break;
      case "new_comment":
        if (notification.post) {
          navigate(`/post/${notification.post.id}`);
        }
        break;
      case "new_subscription":
        if (notification.follower) {
          navigate(`/partners/${notification.follower.id}`);
        }
        break;
      case "new_junior_partner":
        navigate(`/partners/${notification.reference_id}`);
        break;
      case "transfer_junior_partner":
        navigate(`/partners/${notification.reference_id}`);
        break;
    }
  };

  const markNotificationAsRead = async (notificationId: number) => {
    try {
      await notificationsAsRead([notificationId]);

      // Обновляем локальное состояние
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );

      // Обновляем счетчик непрочитанных уведомлений
      dispatch(updateUnreadNotifications(pagination.total - 1));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markAllAsRead = async () => {
    if (notifications.length === 0 || markingAsRead) return;

    try {
      setMarkingAsRead(true);

      // Получаем ID всех непрочитанных уведомлений
      const unreadIds = notifications
        .filter((notification) => !notification.is_read)
        .map((notification) => notification.id);

      if (unreadIds.length === 0) return;

      await notificationsAsRead(unreadIds);

      // Обновляем локальное состояние
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          is_read: true,
        }))
      );

      // Обновляем счетчик непрочитанных уведомлений
      dispatch(updateUnreadNotifications(0));

      // Обновляем данные
      fetchNotificationsData();
      if (activeTab === "2") {
        fetchReadNotificationsData();
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    } finally {
      setMarkingAsRead(false);
    }
  };

  const handlePageChange = (page: number) => {
    if (activeTab === "1") {
      fetchNotificationsData(page);
    } else {
      fetchReadNotificationsData(page);
    }
  };

  const renderNotificationList = (
    notificationList: Notification[],
    isRead: boolean = false,
    paginationData: PaginationData
  ) => {
    if (notificationList.length === 0) {
      return (
        <Empty
          description={t("No notifications yet")}
          className={styles.emptyContainer}
        />
      );
    }

    return (
      <>
        <List
          dataSource={notificationList}
          renderItem={(notification) => {
            return (
              <Card
                className={`${styles.notificationCard} ${
                  isRead || notification.is_read ? styles.readNotification : ""
                }`}
                onClick={() => handleNotificationClick(notification)}
              >
                <div className={styles.cardContent}>
                  <div className={styles.userInfo}>
                    <Avatar
                      icon={<UserOutlined />}
                      size={40}
                      src={notification.avatar_url}
                      className={styles.avatar}
                    />
                    <div className={styles.textContent}>
                      <Typography.Text strong>
                        {t(`notification_type.${notification.type}`)}
                      </Typography.Text>
                      <Paragraph
                        type="secondary"
                        className={styles.notificationText}
                        ellipsis={{ rows: 2, expandable: false }}
                      >
                        {notification.content}
                      </Paragraph>
                      <Typography.Text
                        type="secondary"
                        className={styles.timestamp}
                      >
                        {moment.utc(notification.created_at).fromNow()}
                      </Typography.Text>
                    </div>
                  </div>
                  <RightOutlined className={styles.arrowIcon} />
                </div>
              </Card>
            );
          }}
        />
        {paginationData.total_pages > 1 && (
          <div className={styles.paginationContainer}>
            <Pagination
              current={paginationData.current_page}
              total={paginationData.total}
              pageSize={paginationData.limit}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        )}
      </>
    );
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const renderTabContent = () => {
    if (activeTab === "1") {
      if (loading) {
        return (
          <div className={styles.loadingContainer}>
            <Spin size="large" />
          </div>
        );
      }
      return (
        <>
          {notifications.length > 0 && !loading && (
            <div className={styles.markAllReadContainer}>
              <Link
                onClick={markAllAsRead}
                disabled={notifications.every((n) => n.is_read)}
              >
                {" "}
                {t("Mark all as read")}
              </Link>
            </div>
          )}
          {renderNotificationList(notifications, false, pagination)}
        </>
      );
    } else {
      if (readLoading) {
        return (
          <div className={styles.loadingContainer}>
            <Spin size="large" />
          </div>
        );
      }
      return renderNotificationList(readNotifications, true, readPagination);
    }
  };

  return (
    <div className={styles.container}>
      <Title level={4} className={styles.title}>
        {t("notifications")}
      </Title>

      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        className={styles.tabsContainer}
      >
        <TabPane
          tab={
            <span>
              <BellOutlined className={styles.tabIcon} />
              {t("New")} {pagination.total > 0 && `(${pagination.total})`}
            </span>
          }
          key="1"
        />
        <TabPane
          tab={
            <span>
              <HistoryOutlined className={styles.tabIcon} />
              {t("Archive")}
            </span>
          }
          key="2"
        />
      </Tabs>

      {renderTabContent()}
    </div>
  );
};

export default Notifications;
