import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/dashboard";
import Balances from "../components/balances";
import { useSelector } from "react-redux";
import {
  SettingTwoTone,
  BellTwoTone,
} from "@ant-design/icons";
import {  Badge } from "antd";
import { RootState } from "../services/store";

const Home: FC = () => {
  const navigate = useNavigate();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);


  return (
    userData && (
      <>
        <div className="header">
            <Badge count={!isLoading && userData.unread_notifications}>
              <BellTwoTone
                onClick={() => navigate("/notifications")}
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Badge>
            <SettingTwoTone
              onClick={() => navigate("/settings")}
              style={{ fontSize: "24px", cursor: "pointer", marginLeft: 10 }}
            />
        </div>
        <Balances />
        <Dashboard />
      </>
    )
  );
};

export default Home;
