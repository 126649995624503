import React, { FC, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  message,
  Divider,
  Alert,
  Typography,
  Collapse,
} from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { createUserTask } from "../../helpers/api";
import { CreateTaskFormValues } from "../../types";
import {
  MessageOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GlobalOutlined,
  YoutubeOutlined,
  AppstoreOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

interface CreateTaskFormProps {
  onTaskCreated: () => void;
}

const CreateTaskForm: FC<CreateTaskFormProps> = ({ onTaskCreated }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state: RootState) => state.user.userData);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [selectedSocialType, setSelectedSocialType] = useState<string>("telegram");

  const socialMediaTypes = [
    { value: "telegram", label: "Telegram", icon: <MessageOutlined /> },
    { value: "vk", label: "VK", icon: <MessageOutlined /> },
    { value: "x", label: "X", icon: <TwitterOutlined /> },
    { value: "facebook", label: "Facebook", icon: <FacebookOutlined /> },
    { value: "linkedin", label: "LinkedIn", icon: <LinkedinOutlined /> },
    { value: "youtube", label: "YouTube", icon: <YoutubeOutlined /> },
    { value: "medium", label: "Medium", icon: <GlobalOutlined /> },
    { value: "discord", label: "Discord", icon: <MessageOutlined /> },
    { value: "miniapps", label: "Mini Apps", icon: <AppstoreOutlined /> },
    { value: "other", label: t("Other"), icon: <GlobalOutlined /> },
  ];

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (
      changedValues.reward !== undefined ||
      changedValues.max_participants !== undefined
    ) {
      const reward = allValues.reward || 0;
      const maxParticipants = allValues.max_participants || 0;
      setTotalCost(reward * maxParticipants);
    }

    if (changedValues.social_type !== undefined) {
      setSelectedSocialType(changedValues.social_type);
      // Сбрасываем значение ссылки при изменении типа социальной сети
      form.setFieldsValue({ link: "" });
    }
  };

  // Функция для получения паттерна валидации ссылки в зависимости от выбранной социальной сети
  const getLinkValidationPattern = () => {
    switch (selectedSocialType) {
      case "telegram":
        return /^(https?:\/\/(t\.me|telegram\.me)\/[a-zA-Z0-9_]+(\/.+)?|https?:\/\/(t\.me|telegram\.me)\/[a-zA-Z0-9_]+\/\d+)$/;
      case "miniapps":
        return /^(https?:\/\/(t\.me|telegram\.me)\/[a-zA-Z0-9_]+(\/.+)?|https?:\/\/(t\.me|telegram\.me)\/[a-zA-Z0-9_]+\/\d+)$/;
      case "vk":
        return /^https?:\/\/(vk\.com|m\.vk\.com)\/[a-zA-Z0-9_.\-]+/;
      case "x":
        return /^https?:\/\/(x\.com|twitter\.com)\/[a-zA-Z0-9_]+/;
      case "facebook":
        return /^https?:\/\/(www\.)?(facebook|fb)\.com\/[a-zA-Z0-9_.\-]+/;
      case "linkedin":
        return /^https?:\/\/(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_.\-]+/;
      case "youtube":
        return /^https?:\/\/(www\.)?youtube\.com\/(channel|c|user|watch|playlist|shorts)\/[a-zA-Z0-9_.\-?=&]+/;
      case "medium":
        return /^https?:\/\/(www\.)?medium\.com\/(@[a-zA-Z0-9_.\-]+|[a-zA-Z0-9_.\-]+)/;
      case "discord":
        return /^https?:\/\/(www\.)?(discord\.gg|discord\.com\/invite)\/[a-zA-Z0-9_.\-]+/;
      default:
        return /^https?:\/\/.+/;
    }
  };

  // Функция для получения сообщения об ошибке валидации ссылки
  const getLinkValidationMessage = () => {
    switch (selectedSocialType) {
      case "telegram":
        return t("invalidTelegramLink");
      case "vk":
        return t("invalidVkLink");
      case "x":
        return t("invalidXLink");
      case "facebook":
        return t("invalidFacebookLink");
      case "linkedin":
        return t("invalidLinkedinLink");
      case "youtube":
        return t("invalidYoutubeLink");
      case "medium":
        return t("invalidMediumLink");
      case "discord":
        return t("invalidDiscordLink");
      case "miniapps":
        return t("invalidMiniAppsLink");
      default:
        return t("invalidLink");
    }
  };

  // Функция для получения плейсхолдера для поля ссылки
  const getLinkPlaceholder = () => {
    switch (selectedSocialType) {
      case "telegram":
        return "https://t.me/example";
      case "vk":
        return "https://vk.com/example";
      case "x":
        return "https://x.com/example";
      case "facebook":
        return "https://facebook.com/example";
      case "linkedin":
        return "https://linkedin.com/in/example";
      case "youtube":
        return "https://youtube.com/channel/example";
      case "medium":
        return "https://medium.com/@example";
      case "discord":
        return "https://discord.gg/example";
      case "miniapps":
        return "https://t.me/example/app";
      default:
        return "https://example.com";
    }
  };

  const handleSubmit = async (values: CreateTaskFormValues) => {
    if (!userData || userData.web_tokens < totalCost) {
      message.error(t("insufficientFunds"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await createUserTask(values);
      if (response.success) {
        form.resetFields();
        onTaskCreated();
      } else {
        message.error(response.message || t("taskCreationError"));
      }
    } catch (error) {
      console.error("Error creating task:", error);
      message.error(t("taskCreationError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={handleValuesChange}
      initialValues={{
        type: "channel",
        social_type: "telegram",
      }}
    >
      <Collapse 
        ghost 
        defaultActiveKey={[]} 
        style={{ marginBottom: 16 }}
      >
        <Collapse.Panel 
          header={
            <span>
              <InfoCircleOutlined style={{ marginRight: 8 }} />
              {t("taskCreationInfo")}
            </span>
          } 
          key="1"
        >
          <Alert
            message={t("taskCreationInfo")}
            description={t("taskCreationDescription")}
            type="info"
            style={{ textAlign: "left" }}
            showIcon
          />
        </Collapse.Panel>
      </Collapse>

      <Form.Item
        name="title"
        label={t("taskTitle")}
        rules={[
          { required: true, message: t("pleaseEnterTaskTitle") },
          { max: 100, message: t("titleTooLong") },
        ]}
      >
        <Input placeholder={t("enterTaskTitle")} />
      </Form.Item>

      <Form.Item
        name="description"
        label={t("taskDescription")}
        rules={[
          { required: true, message: t("pleaseEnterTaskDescription") },
          { max: 200, message: t("descriptionTooLong") },
        ]}
      >
        <TextArea
          placeholder={t("enterTaskDescription")}
          rows={4}
          showCount
          maxLength={200}
        />
      </Form.Item>

      <Form.Item
        name="social_type"
        label={t("socialMediaType")}
        rules={[{ required: true, message: t("pleaseSelectSocialMediaType") }]}
      >
        <Select placeholder={t("selectSocialMediaType")}>
          {socialMediaTypes.map((type) => (
            <Option key={type.value} value={type.value}>
                {type.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="link"
        label={t("channelOrGroupLink")}
        rules={[
          { required: true, message: t("pleaseEnterLink") },
          {
            pattern: getLinkValidationPattern(),
            message: getLinkValidationMessage(),
          },
        ]}
      >
        <Input placeholder={getLinkPlaceholder()} />
      </Form.Item>

      <Form.Item
        name="verification_code"
        label={t("verificationCode")}
        rules={[
          { required: true, message: t("pleaseEnterVerificationCode") },
          {
            pattern: /^\d{4}$/,
            message: t("verificationCodeMustBe4Digits"),
          },
        ]}
        tooltip={t("verificationCodeTooltip")}
      >
        <Input placeholder="1234" maxLength={4} />
      </Form.Item>

      <Form.Item
        name="type"
        label={t("taskType")}
        rules={[{ required: true, message: t("pleaseSelectTaskType") }]}
      >
        <Select placeholder={t("selectTaskType")}>
          <Option value="channel">{t("channel")}</Option>
          <Option value="group">{t("group")}</Option>
          <Option value="post">{t("post")}</Option>
          <Option value="miniapps">{t("miniapps")}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="reward"
        label={t("rewardPerUser")}
        rules={[
          { required: true, message: t("pleaseEnterReward") },
          { type: "number", min: 100, message: t("minimumReward") },
          { type: "number", max: 100000000, message: t("maximumReward") },
        ]}
      >
        <InputNumber
          max={100000000}
          style={{ width: "100%" }}
          addonAfter="$WEB"
        />
      </Form.Item>

      <Form.Item
        name="max_participants"
        label={t("maxParticipants")}
        rules={[
          { required: true, message: t("pleaseEnterMaxParticipants") },
          { type: "number", min: 1, message: t("minimumParticipants") },
          { type: "number", max: 1000000, message: t("maximumParticipants") },
        ]}
      >
        <InputNumber min={1} max={1000000} style={{ width: "100%" }} />
      </Form.Item>

      <Divider />

      <div className="task-cost-summary">
        <Text strong>{t("totalCost")}: </Text>
        <Text strong>{totalCost} $WEB</Text>
      </div>

      <div className="task-balance-info">
        <Text>{t("yourBalance")}: </Text>
        <Text>{userData?.web_tokens || 0} $WEB</Text>
      </div>

      {userData && totalCost > userData.web_tokens && (
        <Alert
          message={t("insufficientFunds")}
          description={t("insufficientFundsDescription")}
          type="error"
          showIcon
          style={{ marginTop: 16, marginBottom: 16 }}
        />
      )}

      <Form.Item style={{ marginTop: 24 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={!userData || totalCost > userData.web_tokens}
          block
        >
          {t("createTask")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTaskForm;
