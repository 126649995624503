import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Space, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { RightCircleTwoTone } from "@ant-design/icons";

const { Title } = Typography;

const Settings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  return (
    <div style={{ padding: "20px", backgroundColor: "#fff" }}>
      <Title level={3}>{t("settings")}</Title>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Card
          hoverable
          className="buttons"
          onClick={() => navigate("/settings/language")}
        >
          <div className="card">
            <Typography.Text strong>{t("language")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card>
        {/* <Card
          hoverable
          className="buttons"
          onClick={() => navigate("/settings/notifications")}
        >
          <div className="card">
            <Typography.Text strong>{t("notifications")}</Typography.Text>
            <RightCircleTwoTone style={{ fontSize: "24px" }} />
          </div>
        </Card> */}
      </Space>
    </div>
  );
};

export default Settings;
