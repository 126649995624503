import { FC, useEffect, useState } from "react";
import {
  List,
  Typography,
  Pagination,
  Row,
  Tag,
  Spin,
  Card,
  Space,
  Empty,
  Alert,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchPartnersTransactions } from "../../helpers/api";
import { PartnerTransaction } from "../../types";
import { shortenHash } from "../../helpers/functions";
import styles from "./Overnight.module.css";
import {
  UserOutlined,
  LinkOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  GiftOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const OvernightPartners: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<PartnerTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const loadTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetchPartnersTransactions(page, itemsPerPage);
        setTransactions(response.data);
        setTotalPages(response.pagination.total_pages);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTransactions();
  }, [page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Alert
        message={
          <Space>
            <GiftOutlined style={{ fontSize: "16px" }} />
            <Text strong>{t("For the first month, you get 30% from new partners")}</Text>
          </Space>
        }
        type="info"
        showIcon={false}
        style={{ margin: "16px 16px 0", borderRadius: "8px" }}
      />
      <Card
        title={
          <Space>
            <UserOutlined className={styles.iconStyle} />
            <Title level={4} style={{ margin: 0 }}>
              {t("Accrual history")}
            </Title>
          </Space>
        }
        className={styles.partnerCard}
      >
        {loading ? (
          <Row justify="center" align="middle" className={styles.spinContainer}>
            <Spin size="large" />
          </Row>
        ) : transactions.length > 0 ? (
          <>
            <List
              itemLayout="horizontal"
              dataSource={transactions}
              renderItem={(transaction) => (
                <List.Item
                  className={`${styles.listItemStyle} ${styles.listItemHover}`}
                  onClick={() =>
                    navigate(`/partners/${transaction.referred_user_id}`)
                  }
                >
                  <List.Item.Meta
                    title={
                      <Text strong>
                        {transaction.referred_user_name}{" "}
                        {transaction.referred_last_name}
                      </Text>
                    }
                    description={
                      <Space direction="vertical" size="small">
                        <Tag
                          icon={
                            transaction.is_paid ? (
                              <CheckCircleOutlined style={{ fontSize: "12px" }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: "12px" }} />
                            )
                          }
                          color={transaction.is_paid ? "success" : "warning"}
                          className={styles.tagStyle}
                        >
                          {transaction.is_paid ? t("paid") : t("not_paid")}
                        </Tag>
                        <Text type="secondary">
                          {t("Amount")}: <Text strong>{transaction.amount || 0} TON</Text>
                        </Text>
                        {transaction.is_paid && transaction.transaction_hash ? (
                          <Space size="small">
                            <LinkOutlined style={{ color: "#1890ff" }} />
                            <a
                              href={`https://tonviewer.com/transaction/${transaction.transaction_hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              style={{ color: "#1890ff" }}
                            >
                              {shortenHash(transaction.transaction_hash)}
                            </a>
                          </Space>
                        ) : null}
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
            <Row justify="center" style={{ marginTop: "24px" }}>
              <Pagination
                current={page}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Row>
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("No transactions found")}
            className={styles.emptyStyle}
          />
        )}
      </Card>
    </>
  );
};

export default OvernightPartners;
