import { Middleware } from "@reduxjs/toolkit";
import { setConnected, updateLastPing } from "../slices/websocketSlice";
import { setAuctionState, addBid, setMessage, updateRemainingTime } from "../slices/auctionSlice";
import { setActiveUsers } from "../slices/appSlice";

let ws: WebSocket | null = null;
const PING_INTERVAL = 30000;

export const websocketMiddleware: Middleware =
  (store) => (next) => (action: unknown) => {
    if (
      typeof action === "object" &&
      action !== null &&
      "type" in action
    ) {
      if (action.type === "websocket/connect") {
        if (ws) ws.close();

        ws = new WebSocket(process.env.REACT_APP_WS_URL || "wss://webtransfer.app/node");

        ws.onopen = () => {
          store.dispatch(setConnected(true));

          setInterval(() => {
            if (ws?.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: "ping" }));
            }
          }, PING_INTERVAL);
        };

        ws.onclose = () => {
          store.dispatch(setConnected(false));
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);

          switch (data.type) {
            case "pong":
              store.dispatch(updateLastPing());
              break;
            case "bid_accepted":
              if (data.userId === store.getState().user.userData?.id) {
                store.dispatch(setMessage("bid_accepted"));
              }
              store.dispatch(addBid({
                user_id: data.userId,
                amount: data.amount,
                first_name: data.first_name
              }));
              break;
            case "auction_state":
              store.dispatch(setAuctionState({
                highestBid: data.auction.highestBid,
                endTime: data.auction.endTime,
                winnerId: data.auction.winnerId,
                remainingTime: Math.floor(data.auction.remainingTime / 1000),
                bids: data.auction.bids
              }));
              break;
            case "countdown":
              const endTime = Date.now() + data.remainingTime;
              store.dispatch(updateRemainingTime(endTime));
              break;
            case "activeUsers":
              store.dispatch(setActiveUsers(data.count));
              break;
            case "error":
              store.dispatch(setMessage(data.message));
              break;
          }
        };
      }

      if (action.type === "websocket/send" && "payload" in action) {
        if (ws?.readyState === WebSocket.OPEN) {
          ws.send(action.payload as string);
        }
      }
    }

    return next(action);
  };
