import { FC, useState, useEffect } from "react";
import { List } from "antd";
import axios from "axios";
import AddPost from "./Post/AddPost";
import Post from "./Post/Post";
import { PostType } from "../types";

const Feed: FC<{ userId: number }> = ({ userId }) => {
  const [posts, setPosts] = useState<PostType[]>([]);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`/api/account/get_posts.php?user_id=${userId}`);
      setPosts(response.data.posts);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);


  const handleDelete = (postId: number) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  return (
    <div>
      <AddPost onPostAdded={fetchPosts} />
      <List
        dataSource={posts}
        renderItem={(post) => (
          <Post
            key={post.id}
            first_name={post.first_name}
            last_name={post.last_name}
            avatarUrl={post.avatar_url}
            content={post.content}
            imageUrl={post.image_url}
            likes={post.likes}
            comments_count={post.comments_count}
            postId={post.id}
            postTime={post.created_at}
            user_id={post.user_id}
            userId={userId}
            onDelete={handleDelete}
            poll={post.poll}
            liked_by_user={post.liked_by_user}
            view_count={post.view_count}
          />
        )}
      />
    </div>
  );
};

export default Feed;
