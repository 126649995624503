import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import {
  Button,
  Spin,
  Typography,
  Row,
  Col,
  Card,
  Modal,
  Collapse,
  Tag,
  message,
  Flex,
} from "antd";
import {
  CopyTwoTone,
  QuestionCircleTwoTone,
  PieChartTwoTone,
  ShareAltOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import PartnerList from "./PartnerList";
import {
  fetchPartners,
  getMessageId,
  sendRewardForSharing,
} from "../../helpers/api";
import { Partner } from "../../types";
import WebApp from "@twa-dev/sdk";
import styles from "./Partners.module.css";
import { Avatar } from 'antd';


const { Title, Text } = Typography;
const { Panel } = Collapse;


const Partners: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalPartners, setTotalPartners] = useState<number>(0);
  const [totalAllPartners, setTotalAllPartners] = useState<number>(0);
  const [totalPartnersSubscribed, setTotalPartnersSubscribed] =
    useState<number>(0);
  const [partnerLevel, setPartnerLevel] = useState<number>(0);
  const [isPartnerVIP, setIsPartnerVIP] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [seniorPartner, setSeniorPartner] = useState<{
    avatar_url: string;
    user_id: number;
    first_name: string;
    last_name: string;
    username: string;
  } | null>(null);
  const itemsPerPage = 10;

  useEffect(() => {
    setLoading(true);

    fetchPartners(page, itemsPerPage).then((data) => {
      setPartners(data.partners);
      setTotalPages(data.totalPages);
      setTotalPartners(data.totalPartners);
      setTotalAllPartners(data.totalAllPartners);
      setSeniorPartner(data.seniorPartner);
      setPartnerLevel(data.partnerLevel);
      setIsPartnerVIP(data.vipStatus);
      setTotalPartnersSubscribed(data.subscribedReferralsCount);
    });
    setLoading(false);
  }, [userData, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const faqItems = [
    {
      key: "1",
      label: t("Partners.Question1.label"),
      content: t("Partners.Question1.content"),
    },
    {
      key: "2",
      label: t("Partners.Question2.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question2.content"),
          }}
        />
      ),
    },
    {
      key: "3",
      label: t("Partners.Question3.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question3.content"),
          }}
        />
      ),
    },
    {
      key: "4",
      label: t("Partners.Question4.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question4.content"),
          }}
        />
      ),
    },
    {
      key: "5",
      label: t("Partners.Question5.label"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("Partners.Question5.content"),
          }}
        />
      ),
    },
  ];

  const modalContent = (
    <Collapse accordion>
      {faqItems.map((faq) => (
        <Panel key={faq.key} header={faq.label}>
          <p>{faq.content}</p>
        </Panel>
      ))}
    </Collapse>
  );

  return (
    <div className={styles.container}>
      <Flex justify="space-between" align="center" className={styles.header}>
        <Title level={3} className={styles.title}>
          {t("Partners")}
        </Title>
        <Button
          type="text"
          icon={<QuestionCircleTwoTone twoToneColor="#007aff" />}
          onClick={() => setIsModalVisible(true)}
        >
          FAQ
        </Button>
      </Flex>

      <Flex gap="small" justify="space-between" className={styles.iconLinks}>
        <PieChartTwoTone
          twoToneColor="#007aff"
          onClick={() => navigate("/partners/statistics")}
          className={styles.icon}
        />
        <CrownOutlined
          style={{ color: "#007aff", fontSize: 24, cursor: "pointer" }}
          onClick={() => navigate("/partners/top-senior")}
        />
        <Text
          copyable={{
            text: `https://t.me/Webtransfer_apps_bot/webtransfer?startapp=${userData!.id}`,
            icon: <CopyTwoTone twoToneColor="#007aff" className={styles.icon} />,
          }}
        />
      </Flex>

      <Card
        title={
          <Flex align="center" gap="small" justify="center">
            <ShareAltOutlined className={styles.blueIcon} />
            {t("Earn $1 by sharing your affiliate link")}
          </Flex>
        }
        className={styles.shareCard}
      >
        <Button
          type="primary"
          icon={<ShareAltOutlined />}
          loading={shareLoading}
          onClick={async () => {
            try {
              if (WebApp.initDataUnsafe?.user?.id) {
                setShareLoading(true);
                const userId = WebApp.initDataUnsafe.user.id;
                const messageId = await getMessageId(userId);

                WebApp.shareMessage(messageId, async (success) => {
                  if (success) {
                    try {
                      const rewardResponse = await sendRewardForSharing();
                      if (rewardResponse.success) {
                        message.success({
                          content: t("You have earned $1 for sharing!"),
                          style: { marginTop: '64px' }
                        });
                      } else if (rewardResponse.error) {
                        message.error({
                          content: t(rewardResponse.error),
                          style: { marginTop: '64px' }
                        });
                      }
                    } catch (error) {
                      message.error({
                        content: t("Failed to send reward. Please try again."),
                        style: { marginTop: '64px' }
                      });
                    }
                  }
                  setShareLoading(false);
                });
              }
            } catch (error) {
              message.error({
                content: t("Error sharing message"),
                style: { marginTop: '64px' }
              });
              setShareLoading(false);
            }
          }}
          className={styles.shareButton}
        >
          {t("Share your link")}
        </Button>
      </Card>

      {loading ? (
        <div className={styles.loaderContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]}>
          {seniorPartner ? (
              <Col span={24}>
                <Card
                  className={styles.seniorPartnerCard}
                >
                  <Flex align="center" gap="middle" justify="space-between">
                    <Flex align="center" gap="middle">
                      <Avatar src={seniorPartner.avatar_url} size={40} icon={<UserOutlined />} onClick={() => navigate(`/partners/${seniorPartner.user_id}`)}/>
                      <div>
                        <Text strong>{t("Your senior partner")}</Text>
                        <div>
                          <Tag color="#007aff">{`${seniorPartner.first_name} ${seniorPartner.last_name}`}</Tag>
                        </div>
                      </div>
                    </Flex>
                  </Flex>
                    <Text type="secondary" className={styles.selectNewSeniorPartner} onClick={() => navigate("/partners/top-senior")}>{t("Select new senior partner")}</Text>
                </Card>
              </Col>
            ):(
              <Col span={24}>
                <Card className={styles.card}>
                  <Button type="primary" onClick={() => navigate("/partners/top-senior")}>{t("Select senior partner")}</Button>
                </Card>
              </Col>
            )}

            <Col xs={24} md={12}>
              <Card className={styles.card}>
                <Flex align="center" gap="middle" justify="center">
                  <TrophyOutlined className={styles.blueIcon} />
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Text strong>{t("Available levels")}</Text>
                    <div>
                      <Tag
                        color="#007aff"
                        className={styles.levelTag}
                        onClick={() => navigate("/invest/marketing")}
                      >
                        {isPartnerVIP ? "PREMIUM" : partnerLevel}
                      </Tag>
                    </div>
                  </div>
                </Flex>
              </Card>
            </Col>

            <Col xs={24} md={12}>
              <Card className={styles.card}>
                <Flex align="center" gap="middle" justify="center">
                  <TeamOutlined className={styles.blueIcon} />
                  <div>
                    <Text strong>{t("Total Partners")}</Text>
                    <div>
                      <Text style={{ fontSize: "16px", color: "#007aff" }}>
                        {totalPartners} / {totalAllPartners}
                      </Text>
                      <div>
                        <Text type="secondary" style={{ fontSize: "12px" }}>
                          {t("Total subscribed")}: {totalPartnersSubscribed}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Flex>
              </Card>
            </Col>

            <Col span={24}>
              <Card
                className={styles.partnerBalanceCard}
                actions={[
                  <Button
                    type="link"
                    onClick={() => navigate("/invest-partners-balance")}
                    style={{ color: "#007aff" }}
                  >
                    {t("Invest")}
                  </Button>
                ]}
              >
                <Flex align="center" gap="middle">
                  <Text strong>{t("Partner Balance")}</Text>
                  <Text style={{ fontSize: "18px", color: "#007aff" }}>
                    ${userData?.partner_balance}
                  </Text>
                </Flex>
              </Card>
            </Col>
          </Row>

          <Card className={styles.partnerListCard}>
            <PartnerList
              partners={partners}
              page={page}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </Card>
        </>
      )}

      <Modal
        title={<Text strong>{t("FAQ")}</Text>}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={styles.modalTop}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default Partners;
