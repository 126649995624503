import { FC } from "react";
import { List, Pagination, Typography, Steps, Col } from "antd";
import { useTranslation } from "react-i18next";
import { PartnerListProps } from "../../types";
import { Link } from "react-router-dom";
import styles from "./Partners.module.css";

const { Text } = Typography;
const { Step } = Steps;

const PartnerList: FC<PartnerListProps> = ({
  partners,
  page,
  totalPages,
  itemsPerPage,
  onPageChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <List
        dataSource={partners}
        itemLayout="horizontal"
        renderItem={(partner) => {
          let currentStep = 0;
          if (partner.is_subscribed) {
            currentStep = 2;
          } else if (partner.active) {
            currentStep = 1;
          }
          const totalSteps = 3;
          const percent = Math.round((currentStep / totalSteps) * 100);
          return (
            <List.Item key={partner.user_id}>
              <Col span={24}>
                <div className={styles.historyItem}>
                  <Link
                    to={`/partners/${partner.user_id}`}
                    className={styles.partnerName}
                  >{`${partner.first_name} ${partner.last_name}`}</Link>
                  <Text type="secondary" className={styles.partnerInfo}>
                    {t("Total Partners")}: {partner.referral_count}
                  </Text>
                </div>
              </Col>
              <Col span={24}>
                <Steps
                  current={currentStep}
                  size="small"
                  type="inline"
                  percent={percent}
                >
                  <Step
                    title={t("Registered")}
                    status={"finish"}
                    className="step-finish"
                  />
                  <Step
                    title={t("Issued a loan")}
                    status={partner.active ? "finish" : "wait"}
                    className={partner.active ? "step-finish" : "step-wait"}
                  />
                  <Step
                    title={t("Subscribed")}
                    description={
                      partner.is_subscribed
                        ? t("Subscribed")
                        : t("Waiting for Subscription")
                    }
                    status={partner.is_subscribed ? "finish" : "wait"}
                    className={
                      partner.is_subscribed
                        ? "step-finish"
                        : "step-wait"
                    }
                  />
                </Steps>
              </Col>
            </List.Item>
          );
        }}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          current={page}
          total={totalPages * itemsPerPage}
          pageSize={itemsPerPage}
          onChange={onPageChange}
          size="small"
        />
      </div>
    </>
  );
};

export default PartnerList;
