import Settings from '../pages/Settings/Settings';
import Language from '../pages/Settings/Language';
import FAQ from '../pages/FAQ';
import About from '../pages/About';
import FollowingFeed from '../pages/SocialNetwork/FollowingFeed';
import Risks from '../pages/Risks';
import Statistics from '../pages/Statistics/Statistics';
import LoanIssuance from '../pages/LoansInvest/LoanIssuance';
import LoanOvernight from '../pages/Overnight/LoanOvernight';
import OvernightPartners from '../pages/Overnight/OvernightPartners';
import LoanInsure from '../pages/LoanInsure';
import SalesCertificates from '../pages/SalesCertificates';
import Certificate from '../pages/Certificate';
import LoanExchange from '../pages/LoanExchange';
import LoanPage from '../pages/LoanPage';
import Partners from '../pages//Partners/Partners';
import PartnersStatistics from '../pages//Partners/PartnersStatistics';
import PartnersLevelList from '../pages/Partners/PartnersLevelList';
import TopSeniorPartners from '../pages/Partners/TopSeniorPartners';
import Invest from '../pages/Invest';
import InvestPayment from '../pages/InvestPayment';
import Marketing from '../pages/Marketing';
import WebTokens from '../pages/WebTokens';
import Tasks from '../pages/Tasks/Tasks';
import UserTasks from '../pages/UserTasks/UserTasks';
import CreateTask from '../pages/UserTasks/CreateTask';
import WithdrawalPage from '../pages/Withdrawal/Withdrawal';
import Auction from '../pages/Auction/Auction';
import Notifications from '../pages/Notifications/Notifications';
import Home from '../pages/Home';
import Premium from '../pages/Premium/Premium';
import InvestPartnerBalance from '../pages/Partners/InvestPartnerBalance';
import BankGame from '../pages/Game/BankGame';
import PartnerDetails from '../components/PartnerDetails';
import SinglePost from '../components/SinglePost';
import NotificationSettings from '../components/NotificationSettings';
import TransactionHistory from "../components/history";
import Loans from "../components/loans";
import LastAuctionWinners from "../pages/Auction/LastAuctionWinners";
import Contest from '../pages/Contests/Contest';
import ChatWindow from '../pages/Chat/ChatWindow';
import Achievements from '../pages/Achievements/Achievements';
import TaskDetails from '../pages/UserTasks/TaskDetails';
import ReferralContest from '../pages/Contests/ReferralContest';

const routes = [
  { path: '/settings', component: Settings },
  { path: '/settings/language', component: Language },
  { path: '/settings/notifications', component: NotificationSettings },
  { path: '/settings/faq', component: FAQ },
  { path: '/settings/about', component: About },
  { path: '/settings/risks', component: Risks },
  { path: '/notifications', component: Notifications },
  { path: '/statistics', component: Statistics },
  { path: '/loan-issuance', component: LoanIssuance },
  { path: '/loan-overnight', component: LoanOvernight },
  { path: '/loan-overnight/partners', component: OvernightPartners },
  { path: '/loan-insure', component: LoanInsure },
  { path: '/certificates/sales', component: SalesCertificates },
  { path: '/certificates/sales/:loanId', component: Certificate },
  { path: '/p2p-loans', component: LoanExchange },
  { path: '/loan/:loanId', component: LoanPage },
  { path: '/auction', component: Auction },
  { path: '/partners', component: Partners },
  { path: '/partners/statistics', component: PartnersStatistics },
  { path: '/partners/level/:level', component: PartnersLevelList },
  { path: '/partners/top-senior', component: TopSeniorPartners },
  { path: '/partners/:userId', component: PartnerDetails },
  { path: '/feed', component: FollowingFeed },
  { path: '/post/:postId', component: SinglePost },
  { path: '/invest', component: Invest },
  { path: '/invest/payment', component: InvestPayment },
  { path: '/invest/marketing', component: Marketing },
  { path: '/web-tokens', component: WebTokens },
  { path: '/web-tokens/tasks', component: Tasks },
  { path: '/user-tasks', component: UserTasks },
  { path: '/user-tasks/create-task', component: CreateTask },
  { path: '/user-tasks/task-details/:taskId', component: TaskDetails },
  { path: '/withdraw', component: WithdrawalPage },
  { path: '/bank-game', component: BankGame },
  { path: '/premium', component: Premium },
  { path: '/invest-partners-balance', component: InvestPartnerBalance },
  { path: '/history', component: TransactionHistory },
  { path: '/loans', component: Loans },
  { path: '/last-auction-winners', component: LastAuctionWinners },
  { path: '/contest', component: Contest },
  { path: '/invest-contest', component: ReferralContest },
  { path: '/', component: Home },
  { path: '/chat/:userId', component: ChatWindow },
  { path: '/achievements', component: Achievements },
  { path: '*', component: Home },
];

export default routes;
