import { FC, useState, useEffect } from "react";
import { Row, Col, Card, Typography, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  BarChartOutlined,
  UsergroupAddOutlined,
  GiftOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../services/slices/userSlice";
import styles from "./premium.module.css";
import { activateTrialPremium, fetchPremiumData } from "../../helpers/api";

const { Title, Text } = Typography;

const NonPremiumContent: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasUsedTrial, setHasUsedTrial] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTrialStatus = async () => {
      try {
        const data = await fetchPremiumData();
        setHasUsedTrial(data.hasUsedTrial);
      } catch (error) {
        console.error("Error fetching trial status:", error);
      }
    };

    fetchTrialStatus();
  }, []);

  const handleActivateTrialPeriod = async () => {
    setLoading(true);
    try {
      const response = await activateTrialPremium();

      if (response.success) {
        message.success(response.message);
        dispatch(updateUserData({ premium_status: 1 }));
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error activating trial period:", error);
      message.error(
        t("Failed to activate trial period. Please try again later.")
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Title level={3} className={styles.title}>
        {t("Premium Subscription")} ⭐
      </Title>
      <Text className={styles.subtitle}>
        {t(
          "Unlock premium features with our subscription! Enjoy exclusive benefits including:"
        )}
      </Text>

      <Row gutter={[24, 24]} className={styles.featuresRow}>
        <Col xs={24} md={8}>
          <Card
            hoverable
            className={styles.featureCard}
          >
            <div className={styles.featureCardContent}>
              <UsergroupAddOutlined
                className={styles.icon}
              />
            <Text
              strong
              className={styles.featureTitle}
            >
              {t("Expanded Social Network Features")}
            </Text>
            <p className={styles.featureDescription}>
                {t(
                  "Gain access to advanced social networking tools, including enhanced profiles, connections, and interactions."
                )}
              </p>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            hoverable
            className={styles.featureCard}
          >
            <div className={styles.featureCardContent}>
              <BarChartOutlined className={styles.icon} />
              <Text
                strong
                className={styles.featureTitle}
            >
              {t("Advanced Referral Structure Analysis")}
            </Text>
            <p className={styles.featureDescription}>
                {t(
                  "Gain insights with in-depth analysis of your referral network's structure and performance."
                )}
              </p>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            hoverable
            className={styles.featureCard}
          >
            <div className={styles.featureCardContent}>
              <RiseOutlined className={styles.icon} />
              <Text
                strong
                className={styles.featureTitle}
            >
              {t("Priority Display")}
            </Text>
            <p className={styles.featureDescription}>
                {t(
                  "Your profile will be displayed higher in the list of popular people, and your posts will be shown at the beginning of the feed."
                )}
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      <Card
        className={styles.priceCard}
      >
        <Text strong className={styles.priceText}>
          {t("Price")}: <span className={styles.priceAmount}>1 TON</span> /{" "}
          {t("month")}
        </Text>
      </Card>

      {!hasUsedTrial && (
        <Card
          className={styles.trialCard}
        >
          <div
            className={styles.flexColumn}
          >
            <GiftOutlined className={styles.icon} />
            <div className={styles.trialContent}>
              <Text strong className={styles.trialTitle}>
                {t("7-Day Free Trial")}
              </Text>
              <p className={styles.trialDescription}>
                {t(
                  "Try all premium features free for 7 days. No payment required."
                )}
              </p>
            </div>
            <Button
              type="primary"
              size="large"
              onClick={handleActivateTrialPeriod}
              loading={loading}
              className={styles.primaryButton}
            >
              {t("Activate Trial")}
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default NonPremiumContent;
