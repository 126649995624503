import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Button,
  Modal,
  Flex,
  Tooltip,
} from "antd";
import {
  DollarOutlined,
  NotificationOutlined,
  TeamOutlined,
  RightOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { PartnerStatisticsResponse } from "../../types";
import { fetchPartnerStatistics, notifyPartnerChildren } from "../../helpers/api";
import styles from "./Partners.module.css";

const { Title, Text } = Typography;

const PartnersStatistics: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState<PartnerStatisticsResponse | null>(null);

  useEffect(() => {
    const loadStatistics = async () => {
      setIsLoading(true);
      try {
        const data = await fetchPartnerStatistics();
        setStatistics(data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadStatistics();
  }, [userData]);

  const handleNotifyChildren = async () => {
    try {
      const response = await notifyPartnerChildren();
      if (response.success) {
        Modal.success({
          content: t("Notification sent successfully"),
        });
      } else {
        Modal.error({
          content: t(response.error),
        });
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      Modal.error({
        content: t("Error sending notification"),
      });
    }
  };

  const handleViewPartnersByLevel = (level: number) => {
    if (userData?.premium_status === 1) {
      navigate(`/partners/level/${level}`);
    } else {
      Modal.info({
        title: t("Premium Feature"),
        content: t("Detailed statistics by level is available only for premium users"),
        okText: t("Get Premium"),
        onOk: () => navigate("/premium"),
        onCancel: () =>  {},
      });
    }
  };

  if (isLoading || !statistics) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  const levels = [1, 2, 3, 4, 5];
  const totalActiveLoans = levels.reduce((total, level) => {
    return total + Number(statistics.levels[level]?.total_active_loans.toFixed(0) || 0);
  }, 0);

  const isPremium = userData?.premium_status === 1;

  return (
    <div className={styles.container}>
      <Flex align="center" gap="middle" style={{ marginBottom: "24px" }}>
        <Title level={3} className={styles.statisticsTitle}>
          {t("Partners statistics")}
        </Title>
      </Flex>

      {isLoading || !statistics ? (
        <div className={styles.loaderContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Card
            title={
              <Flex align="center" gap="small">
                <DollarOutlined className={styles.blueIcon} />
                {t("The amount of active loans")}
              </Flex>
            }
            className={styles.shareCard}
          >
            <Row gutter={[16, 16]}>
              {levels.map(
                (level) =>
                  statistics.levels[level]?.total_active_loans != null && (
                    <Col xs={24} sm={12} md={8} key={level}>
                      <Card
                        className={`${styles.levelCard} ${isPremium ? styles.levelCardPremium : styles.levelCardNonPremium}`}
                        onClick={() => handleViewPartnersByLevel(level)}
                        hoverable={isPremium}
                      >
                        <Flex justify="space-between" align="center">
                          <Statistic
                            title={
                              <Flex align="center" gap="small">
                                <span>{level} {t("Level")}</span>
                                {isPremium ? (
                                  <Tooltip title={t("View partners on this level")}>
                                    <TeamOutlined className={styles.blueIcon} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={t("Available with a premium subscription")}>
                                    <LockOutlined style={{ color: "#999" }} />
                                  </Tooltip>
                                )}
                              </Flex>
                            }
                            value={Number(statistics.levels[level]?.total_active_loans).toFixed(0)}
                            prefix="$"
                            valueStyle={{ color: "#007aff" }}
                          />
                          <Flex vertical align="end">
                            <Text type="secondary">
                              {statistics.levels[level]?.number_of_referrals}
                            </Text>
                            {isPremium && (
                              <RightOutlined className={styles.blueIcon} />
                            )}
                          </Flex>
                        </Flex>
                      </Card>
                    </Col>
                  )
              )}
              <Col span={24}>
                <Card
                  className={styles.totalCard}
                >
                  <Statistic
                    title={t("Total")}
                    value={totalActiveLoans}
                    prefix="$"
                    valueStyle={{ color: "#007aff", fontSize: "24px" }}
                  />
                </Card>
              </Col>
            </Row>
          </Card>

          <Card
            title={
              <Flex align="center" gap="small">
                <NotificationOutlined className={styles.blueIcon} />
                {t("Notifications")}
              </Flex>
            }
            className={styles.shareCard}
          >
            <Flex vertical gap="small" align="center">
              {userData && userData.premium_status === 1 ? (
                <Button
                  type="primary"
                  icon={<NotificationOutlined />}
                  onClick={handleNotifyChildren}
                  className={styles.notificationButton}
                >
                  {t("Notify Junior Partners")}
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => navigate("/premium")}
                  className={styles.notificationButton}
                >
                  {t("Available with a premium subscription")}
                </Button>
              )}
              <Text type="secondary" className={styles.notificationText}>
                {t("Junior_notifications_caption")}
              </Text>
            </Flex>
          </Card>
        </>
      )}
    </div>
  );
};

export default PartnersStatistics;
