let cryptoKey: CryptoKey | null = null;

const getOrCreateKey = async (): Promise<CryptoKey> => {
  if (!cryptoKey) {
    const keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode("your-secret-key-string"),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    cryptoKey = await window.crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: new TextEncoder().encode("salt"),
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );
  }
  return cryptoKey;
};

export const encryptMessage = async (
  message: string
): Promise<{ encryptedMessage: string; iv: string }> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  const key = await getOrCreateKey();
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  const encryptedData = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    data
  );

  const encryptedArray = Array.from(new Uint8Array(encryptedData));
  const ivArray = Array.from(iv);

  const encryptedMessage = btoa(
    encryptedArray.map((byte) => String.fromCharCode(byte)).join("")
  );
  const ivString = btoa(
    ivArray.map((byte) => String.fromCharCode(byte)).join("")
  );

  return { encryptedMessage, iv: ivString };
};

export const decryptMessage = async (
  encryptedContent: string,
  iv: string
): Promise<string> => {
  try {
    const encryptedData = Uint8Array.from(atob(encryptedContent), (c) =>
      c.charCodeAt(0)
    );
    const ivArray = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));

    const key = await getOrCreateKey();

    const decryptedData = await window.crypto.subtle.decrypt(
      { name: "AES-GCM", iv: ivArray },
      key,
      encryptedData
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    return "Error decrypting message";
  }
};
