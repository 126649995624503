import { FC, useState, useEffect } from "react";
import { Typography, List, Divider, Spin, Card, Tag } from "antd";
import { useTranslation } from "react-i18next";
import {PurchaseHistoryItem} from '../../types';
import { fetchPremiumData } from "../../helpers/api";
import styles from "./premium.module.css";

const { Title, Text } = Typography;


const PremiumContent: FC = () => {
  const { t } = useTranslation();
  const [premiumEndDate, setPremiumEndDate] = useState<string | null>(null);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPremiumData();
        setPremiumEndDate(data.premiumEndDate);
        setIsTrial(data.isTrial === 1);
        setPurchaseHistory(data.purchaseHistory);
      } catch (error) {
        console.error("Error fetching premium data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div className={styles.container}>
      <Title level={3} className={styles.title}>
        {t("welcome_to_premium")} ✨
      </Title>

      <Card className={styles.premiumInfoCard}>
        <div className={styles.flexBetween}>
          <Text className={styles.premiumInfoText}>
            {t("premium_ends_on")}:{" "}
            <strong className={styles.premiumHighlight}>
              {premiumEndDate || t("no_premium_subscription")}
            </strong>
          </Text>
          {isTrial && (
            <Tag color="#007aff" className={styles.trialTag}>
              {t("Trial Period")}
            </Tag>
          )}
        </div>
      </Card>

      {isTrial && (
        <Card className={styles.trialCard}>
          <Text className={styles.subtitle}>
            {t("You are currently using the trial version of Premium. To continue enjoying premium features after the trial period, please purchase a subscription.")}
          </Text>
        </Card>
      )}

      <Divider className={styles.divider} />

      <Title level={4} className={styles.historyTitle}>{t("purchase_history")}</Title>
      {purchaseHistory.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={purchaseHistory}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <span className={styles.purchaseAmount}>
                    {`${Number(item.amount).toFixed(0)} TON`}
                  </span>
                }
                description={
                  <Text className={styles.purchaseDate}>{item.date}</Text>
                }
              />
            </List.Item>
          )}
          className={styles.historyList}
        />
      ) : (
        <Text className={styles.historyEmptyText}>
          {isTrial ? t("No purchases yet. You are using the trial version.") : t("No purchase history available.")}
        </Text>
      )}
    </div>
  );
};

export default PremiumContent;
