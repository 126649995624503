import React, { useEffect } from "react";
import { Spin, Card, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useTonAddress } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../services/store";
import { fetchTonData, fetchTonPrice } from "../services/slices/tonSlice";
import { fetchUSDTData } from "../services/slices/usdtSlice";

const { Text } = Typography;

const TonBalance: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userFriendlyAddress = useTonAddress();
  const navigate = useNavigate();

  const { balance, isLoading: tonLoading, tonPrice } = useSelector(
    (state: RootState) => state.ton
  );
  const { balance: usdtBalance, isLoading: usdtLoading } = useSelector(
    (state: RootState) => state.usdt
  );

  useEffect(() => {
    // Получаем цену TON только если её ещё нет
    if (tonPrice === null) {
      dispatch(fetchTonPrice());
    }

    if (userFriendlyAddress) {
      dispatch(fetchTonData(userFriendlyAddress));
      dispatch(fetchUSDTData(userFriendlyAddress));
    }
  }, [userFriendlyAddress, dispatch, tonPrice]);

  return (
    <>
      <Card
        size="small"
        onClick={() => navigate("/loan-issuance", { state: { tokenType: true } })}
        style={{
          background: "#f8fafc",
          borderRadius: "8px",
          cursor: "pointer",
          marginBottom: "8px",
        }}
        className="hover-card"
      >
        <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space size={4}>
            <img src="./assets/tonIcon.png" alt="TON Logo" width={16} />
            <Text style={{ fontSize: "13px" }}>TON</Text>
          </Space>
          <Text strong style={{ fontSize: "13px" }}>
            {tonLoading ? <Spin size="small" /> : balance ?? 0}
          </Text>
        </Space>
      </Card>

      <Card
        size="small"
        onClick={() => navigate("/loan-issuance")}
        style={{
          background: "#f8fafc",
          borderRadius: "12px",
          cursor: "pointer",
        }}
        className="hover-card"
      >
        <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
          <Space>
            <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={16} />
            <Text>USDT</Text>
          </Space>
          <Text strong style={{ fontSize: "13px" }}>
            {usdtLoading ? <Spin size="small" /> : usdtBalance ?? 0}
          </Text>
        </Space>
      </Card>
    </>
  );
};

export default TonBalance;
