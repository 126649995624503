import { FC, useState } from "react";
import { MyLoansProps, Loan } from "../types";
import {
  List,
  Tag,
  Card,
  Modal,
  Button,
  message,
  Space,
  Typography,
  Tooltip,
  Flex,
} from "antd";
import { getRateColor } from "../helpers/functions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sendTransaction } from "../helpers/transactionService";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { RootState } from "../services/store";
import { cancelLoan } from "../helpers/api";
import {
  CalendarOutlined,
  DollarOutlined,
  PercentageOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const { Text } = Typography;

const MyLoans: FC<MyLoansProps> = ({ loans }) => {
  const { t } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const { userData } = useSelector((state: RootState) => state.user);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);

  const handlePayOff = (loan: Loan) => {
    setSelectedLoan(loan);
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    if (!userFriendlyAddress || !userData || !wallet_address) {
      message.error(t("Please connect your wallet"));
      return;
    }

    if (selectedLoan) {
      try {
        const { loan_id } = selectedLoan;
        const comment = `Webtransfer loan repayment:${loan_id}`;

        const myTransaction = await sendTransaction(
          comment,
          selectedLoan.currency,
          wallet_address,
          userData.settings.pay_wallet,
          selectedLoan.total_amount
        );

        if (myTransaction) {
          const result = await tonConnectUI.sendTransaction(myTransaction);
          console.log({ result });
        }
      } catch (error) {
        console.error("Error repaying loan", error);
        message.error(t("Error repaying loan"));
      }
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleCancelLoan = async (loanId: number) => {
    try {
      const response = await cancelLoan(loanId);
      if (response.status === "success") {
        message.success(t("Loan cancelled successfully"));
        window.location.reload();
      } else {
        message.error(t("Failed to cancel loan"));
      }
    } catch (error) {
      message.error(t("Error cancelling loan"));
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "issued":
        return "green";
      case "pending":
        return "orange";
      default:
        return "gray";
    }
  };

  const formatDate = (date: string) => {
    return dayjs(date).format("DD.MM.YYYY");
  };

  const getLoanStatusIcon = (status: string) => {
    switch (status) {
      case "issued":
        return "🟢";
      case "pending":
        return "🟡";
      case "completed":
        return "✅";
      case "cancelled":
        return "❌";
      default:
        return "⚪";
    }
  };

  return (
    <Card
      title={
        <Space>
          <DollarOutlined /> {t("My loans")}
        </Space>
      }
      style={{
        marginTop: 16,
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        borderRadius: 12,
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={loans}
        locale={{ emptyText: t("You don't have any loans yet") }}
        renderItem={(loan) => (
          <List.Item
            key={loan.id}
            style={{
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#fafafa",
              marginBottom: "8px",
            }}
          >
            <Flex vertical style={{ width: "100%" }} justify="space-between">
              <Flex vertical gap={4}>
                <Flex align="center" gap={16}>
                  <img
                    src={
                      loan.currency === "USDT"
                        ? "./assets/UsdtLogo.webp"
                        : "./assets/tonIcon.png"
                    }
                    alt={loan.currency}
                    width={24}
                  />
                  <Text strong style={{ fontSize: "16px" }}>
                    {loan.amount} {loan.currency}
                  </Text>
                </Flex>
                <Flex gap={8}>
                  <Tooltip title={t("Interest rate")}>
                    <Tag
                      color={getRateColor(loan.rate)}
                      icon={<PercentageOutlined />}
                    >
                      {loan.rate}
                    </Tag>
                  </Tooltip>
                  <Tooltip title={t("Status")}>
                    <Tag
                      color={getStatusColor(loan.status)}
                      icon={getLoanStatusIcon(loan.status)}
                    >
                      {t(`loan_status.${loan.status}`)}
                    </Tag>
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex
                vertical
                gap={8}
                style={{ minWidth: "200px", marginTop: "10px" }}
              >
                <Space size={8}>
                  <Tooltip title={t("Term")}>
                    <Tag icon={<ClockCircleOutlined />}>
                      {loan.term} {t("days")}
                    </Tag>
                  </Tooltip>
                  <Tooltip title={t("Repayment date")}>
                    <Tag icon={<CalendarOutlined />}>
                      {formatDate(loan.expected_repayment_date)}
                    </Tag>
                  </Tooltip>
                </Space>

                {loan.status === "pending" && (
                  <Button
                    danger
                    block
                    onClick={() => handleCancelLoan(loan.loan_id)}
                    icon="❌"
                  >
                    {t("Cancel")}
                  </Button>
                )}
                {loan.status === "issued" && (
                  <Button
                    type="primary"
                    block
                    onClick={() => handlePayOff(loan)}
                    style={{ borderRadius: "6px" }}
                  >
                    {t("Pay off")}
                  </Button>
                )}
              </Flex>
            </Flex>
          </List.Item>
        )}
      />

      <Modal
        title={t("Pay off loan")}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={t("Confirm")}
        cancelText={t("Cancel")}
        style={{ borderRadius: "12px" }}
      >
        <p>{t("Are you sure you want to pay off the loan?")}</p>
        {selectedLoan && (
          <Space direction="vertical">
            <Text>
              {t("Amount")}: {selectedLoan.total_amount} {selectedLoan.currency}
            </Text>
            <Text>
              {t("Repayment date")}:{" "}
              {formatDate(selectedLoan.expected_repayment_date)}
            </Text>
          </Space>
        )}
      </Modal>
    </Card>
  );
};

export default MyLoans;
