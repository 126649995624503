import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBalance, getTonPrice } from '../../helpers/api';

interface TonState {
  balance: number | null;
  tonPrice: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: TonState = {
  balance: null,
  tonPrice: null,
  isLoading: false,
  error: null,
};

// Создаем отдельный thunk для получения цены TON
export const fetchTonPrice = createAsyncThunk(
  'ton/fetchTonPrice',
  async () => {
    const tonPrice = await getTonPrice();
    return tonPrice;
  }
);

export const fetchTonData = createAsyncThunk(
  'ton/fetchTonData',
  async (address: string | null, { getState }) => {
    const balance = address ? await getBalance(address) : null;
    return { balance };
  }
);

const tonSlice = createSlice({
  name: 'ton',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Обработчики для fetchTonPrice
      .addCase(fetchTonPrice.fulfilled, (state, action) => {
        state.tonPrice = action.payload;
      })
      // Обработчики для fetchTonData
      .addCase(fetchTonData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTonData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.balance = action.payload.balance;
      })
      .addCase(fetchTonData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default tonSlice.reducer;
