import { useEffect } from "react";
import axios from "axios";

const useAxiosSetup = () => {
  useEffect(() => {
    // Установка базового URL для API запросов
    axios.defaults.baseURL = "https://webtransfer.app";

    // Интерцептор запросов
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // Добавляем токен авторизации
        const token = localStorage.getItem("auth_token");
        if (token) {
          config.headers.Authorization = token;
        }

        // Добавляем X-App-Token из localStorage
        const appToken = localStorage.getItem("app_token");
        if (appToken) {
          config.headers["X-App-Token"] = appToken;
        }

        // Добавляем CSRF-токен для небезопасных методов
        if (
          ["POST", "PUT", "DELETE", "PATCH"].includes(
            config.method?.toUpperCase() || ""
          )
        ) {
          const csrfToken = localStorage.getItem("csrf_token");
          if (csrfToken) {
            config.headers["X-CSRF-Token"] = csrfToken;
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Интерцептор ответов
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Обновляем CSRF-токен, если он пришел в заголовках
        const csrfToken = response.headers["x-csrf-token"];
        if (csrfToken) {
          localStorage.setItem("csrf_token", csrfToken);
        }

        // Обновляем X-App-Token, если он пришел в заголовках
        const appToken = response.headers["x-app-token"];
        if (appToken) {
          localStorage.setItem("app_token", appToken);
        }

        return response;
      },
      (error) => {
        // Обработка ошибок авторизации
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // Проверяем, не связана ли ошибка с необходимостью CSRF-токена
          if (error.response.data?.message?.includes("CSRF")) {
            // Можно попробовать получить новый CSRF-токен и повторить запрос
            return axios
              .get("/api/generate_csrf.php")
              .then(() => {
                // Повторяем оригинальный запрос
                return axios(error.config);
              })
              .catch(() => {
                // Если не удалось получить CSRF-токен, разлогиниваем
                localStorage.removeItem("auth_token");
                localStorage.removeItem("csrf_token");
                // window.location.href = "/";
                return Promise.reject(error);
              });
          }

          // Если проблема не в CSRF, то разлогиниваем пользователя
          if (
            !error.config.url.includes("login") &&
            !error.config.url.includes("add-user.php")
          ) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("csrf_token");
            // window.location.href = "/";
          }
        }

        // Обработка ошибок рейт-лимита
        if (error.response && error.response.status === 429) {
          // Можно показать уведомление о превышении лимита запросов
          console.error("Rate limit exceeded. Please try again later.");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      // Удаляем интерцепторы при размонтировании
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};

export default useAxiosSetup;
