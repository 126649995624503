import React, { useEffect, useState, Suspense } from "react";
import { useTonAddress } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import WebApp from "@twa-dev/sdk";
import Page from "./wrappers/Page";
import routes from "./routes";
import "./i18n";
import { Spin, Typography, Button } from "antd";
import Banner from "./components/banner";
import "./App.css";
import { saveWalletToDatabase, addUser, validateHash } from "./helpers/api";
import { useTranslation } from "react-i18next";
import { RootState } from "./services/store";
import useAxiosSetup from "./hooks/useAxiosSetup";
import { BackButton } from "@twa-dev/sdk/react";
import {
  setUserData,
  setLoading,
  setStartParam,
  initializeUserData,
} from "./services/slices/userSlice";
import { WebAppUser, UserResponse } from "./types";

// import('eruda').then(eruda => eruda.default.init());

const { Text } = Typography;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [isHashValid, setIsHashValid] = useState(false);
  const { userData, isLoading, startParam } = useSelector(
    (state: RootState) => state.user
  );
  const userFriendlyAddress = useTonAddress();
  const [banned, setBanned] = useState(false);
  const [userIsNotValid, setUserIsNotValid] = useState(false);

  useAxiosSetup();

  useEffect(() => {
    const initData = WebApp.initData;
    const user = WebApp.initDataUnsafe.user as WebAppUser;
    const startParam = WebApp.initDataUnsafe.start_param as string;

    const initialUserData: UserResponse = initializeUserData(user);

    dispatch(setUserData(initialUserData));

    const savedLanguage =
      localStorage.getItem("language") || user?.language_code;
    const userLanguage = savedLanguage || "en";
    const supportedLanguages = [
      "ru",
      "zh",
      "hi",
      "es",
      "fr",
      "ar",
      "bn",
      "pt",
      "id",
      "de",
    ];
    i18n.changeLanguage(
      supportedLanguages.includes(userLanguage) ? userLanguage : "en"
    );

    if (startParam) {
      dispatch(setStartParam(startParam));
    }

    validateHash(initData).then((isValid) => {
      setIsHashValid(isValid);
      if (isValid) {
        WebApp.ready();
        WebApp.expand();
        WebApp.disableVerticalSwipes();
        WebApp.setBackgroundColor("#fff");
      } else {
        setUserIsNotValid(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isHashValid && userData) {
      dispatch(setLoading(true));
      addUser(userData, startParam || "", WebApp.initData)
        .then((response) => {
          localStorage.setItem('auth_token', `Bearer ${response.token}`);

          dispatch(
            setUserData({
              ...userData!,
              ...response,
            })
          );
          if (response.status === 1) {
            setBanned(true);
          }
        })
        .catch((error) => {
          console.error("Error adding user:", error);
        })
        .finally(() => dispatch(setLoading(false)));
    }
  }, [isHashValid]);

  useEffect(() => {
    if (userFriendlyAddress && userData && userData.token) {
      saveWalletToDatabase({
        userId: userData.id,
        walletAddress: userFriendlyAddress,
      });
    }
  }, [userFriendlyAddress]);

  useEffect(() => {
    dispatch({ type: "websocket/connect" });
  }, [dispatch]);

  if (userIsNotValid) {
    return (
      <div className="loader-container">
        <Button
          type="primary"
          onClick={() =>
            window.open("https://t.me/Webtransfer_apps_bot", "_self")
          }
        >
          Go to Telegram
        </Button>
      </div>
    );
  }

  if (!isHashValid || isLoading) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }
  if (banned) {
    return (
      <div className="loader-container">
        <Text>{t("banned")}</Text>
      </div>
    );
  }
  const isBannerEnabled = userData!.settings.banner_enabled === "on";

  return (
    <div className="App">
      <Router>
        {isBannerEnabled && <Banner />}
        <Page>
          <Suspense
            fallback={
              <div className="loader-container">
                <Spin size="large" />
              </div>
            }
          >
            <Routes>
              {routes.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <>
                      {path !== "/" && <BackButton />}
                      <Component />
                    </>
                  }
                />
              ))}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </Page>
      </Router>
    </div>
  );
};

export default App;
