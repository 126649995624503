import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";

interface WebPriceState {
  currentPrice: number;
  isLoading: boolean;
  error: string | null;
}

const initialState: WebPriceState = {
  currentPrice: 0,
  isLoading: false,
  error: null,
};

const webPriceSlice = createSlice({
  name: "webPrice",
  initialState,
  reducers: {
    setWebPrice: (state, action: PayloadAction<number>) => {
      state.currentPrice = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setWebPrice, setLoading, setError } = webPriceSlice.actions;

// Thunk для получения цены
export const fetchWebPrice = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading());

    const response = await fetch("/node/api/auction/get-price-web");
    const data = await response.json();

    if (!data.bids || !data.bids.length) {
      throw new Error("No bid data available");
    }

    // Берем последние 24 ставки
    const last24Bids = data.bids.slice(-24);

    const averageBid =
      last24Bids.reduce((sum: number, bid: any) => sum + bid.highest_bid, 0) /
      last24Bids.length;
    const pricePerToken = 2.2 / averageBid;

    dispatch(setWebPrice(pricePerToken));
  } catch (error) {
    dispatch(
      setError(
        error instanceof Error ? error.message : "Failed to fetch WEB price"
      )
    );
  }
};

export default webPriceSlice.reducer;
