import { FC, useEffect, useState } from "react";
import {
  Spin,
  Tabs,
  Form,
  Select,
  InputNumber,
  List,
  Pagination,
} from "antd";
import { useTranslation } from "react-i18next";
import { fetchLoansData } from "../helpers/api";
import { Loan } from "../types";

const { TabPane } = Tabs;

const LoanExchange: FC = () => {
  const { t } = useTranslation();
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [totalLoans, setTotalLoans] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<"take" | "give">("take");
  const [filters, setFilters] = useState<{
    amount?: number;
    rating?: number;
    insured?: boolean;
  }>({});

  const loadLoans = async () => {
    setLoading(true);
    try {
      const { loans, total } = await fetchLoansData({
        tab: activeTab,
        page: currentPage,
        pageSize,
        ...filters,
      });
      setLoans(loans);
      setTotalLoans(total);
    } catch (error) {
      console.error("Error fetching loans:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLoans();
  }, [activeTab, currentPage, filters]);

  const handleTabChange = (key: string) => {
    const newTab = key === "1" ? "take" : "give";
    setActiveTab(newTab);
    setCurrentPage(1);
  };

  const handleFilterChange = (values: any) => {
    setFilters(values);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="loan-exchange-container">
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={t("Take Loan")} key="1">
          <Form layout="inline" onFinish={handleFilterChange}>
            <Form.Item name="amount" label={t("Desired Amount")}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="insured" label={t("Insured")}>
              <Select
                allowClear
                options={[
                  { value: true, label: t("Yes") },
                  { value: false, label: t("No") },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item>
              <button type="submit">{t("Apply Filters")}</button>
            </Form.Item>
          </Form>

          {loading ? (
            <Spin tip={t("Loading...")} />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={loans}
              renderItem={(loan) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${t("Loan ID")}: ${loan.loan_id}`}
                    description={`${t("Amount")}: ${loan.amount}, ${t(
                      "Term"
                    )}: ${loan.term} ${t("days")}`}
                  />
                </List.Item>
              )}
            />
          )}

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalLoans}
            onChange={handlePageChange}
          />
        </TabPane>

        <TabPane tab={t("Give Loan")} key="2">
          {/* Аналогично вкладке Take Loan */}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default LoanExchange;
