import React, { useState, useEffect, useRef } from "react";
import { Input, Button, List, Avatar, Spin, Space, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RootState } from "../../services/store";
import { encryptMessage, decryptMessage } from "../../helpers/encryption";
import { fetchMessages, sendMessage } from "../../helpers/api";
import moment from "moment";
import { Message } from "../../types";
import styles from './chat.module.css';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import WebApp from "@twa-dev/sdk";
import { sanitize } from 'isomorphic-dompurify';

interface DecryptedMessage extends Message {
  decryptedContent?: string;
}

const { TextArea } = Input;

const ChatWindow: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const currentUser = useSelector((state: RootState) => state.user.userData);
  const [messages, setMessages] = useState<DecryptedMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const prevMessagesLength = useRef(messages.length);
  const [userInfo, setUserInfo] = useState<{
    first_name: string;
    last_name: string;
    avatar_url: string | null;
  } | null>(null);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (userId) {
      fetchChatMessages();
      const interval = setInterval(fetchChatMessages, 5000);
      return () => clearInterval(interval);
    }
  }, [userId]);

  useEffect(() => {
    if (isFirstLoad || messages.length > prevMessagesLength.current) {
      scrollToBottom();
      setIsFirstLoad(false);
    }
    prevMessagesLength.current = messages.length;
  }, [messages]);

  const decryptMessages = async (encryptedMessages: Message[]) => {
    const decryptedMessages = await Promise.all(
      encryptedMessages.map(async (message) => {
        const decryptedContent = await decryptMessage(
          message.encrypted_content,
          message.iv
        );
        const sanitizedContent = decryptedContent ? sanitize(decryptedContent) : '';
        return { ...message, decryptedContent: sanitizedContent };
      })
    );
    return decryptedMessages;
  };

  const fetchChatMessages = async () => {
    if (!userId) return;

    try {
      const data = await fetchMessages(userId);
      const decryptedMessages = await decryptMessages(data.messages);
      setMessages(decryptedMessages);
      if (data.user_info) {
        setUserInfo(data.user_info);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !userId) return;

    try {
      // Очищаем сообщение перед шифрованием и отправкой
      const sanitizedMessage = sanitize(newMessage.trim());
      const { encryptedMessage, iv } = await encryptMessage(sanitizedMessage);
      await sendMessage(userId, encryptedMessage, iv);
      setNewMessage("");
      fetchChatMessages();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Функция для обработки открытия ссылок
  const handleOpenLink = (url: string) => {
    if (!url) return;
    
    // Проверяем, является ли ссылка Telegram-ссылкой
    if (url.includes('t.me/') && WebApp) {
      WebApp.openTelegramLink(url);
    } else {
      // Для других ссылок открываем в новой вкладке
      window.open(url, '_blank');
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.chatWindow}>
      <div className={styles.chatHeader}>
        <Typography.Title level={5} className={styles.chatTitle}>
          {t("Chat with")} {userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : ""}
        </Typography.Title>
      </div>
      <div className={styles.messagesContainer}>
        <List
          dataSource={messages}
          renderItem={(message) => (
            <List.Item
              className={`${styles.messageItem} ${
                message.sender_id === currentUser?.id
                  ? styles.messageItemRight
                  : styles.messageItemLeft
              }`}
            >
              <Space align="start" className={styles.messageSpace}>
                {message.sender_id !== currentUser?.id && (
                  <Avatar
                    src={message.avatar_url}
                    icon={!message.avatar_url && <UserOutlined />}
                    className={styles.avatar}
                  />
                )}
                <div
                  className={`${styles.messageContent} ${
                    message.sender_id === currentUser?.id
                      ? styles.messageContentRight
                      : styles.messageContentLeft
                  }`}
                >
                  <div className={styles.messageText}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        a: ({ node, ...props }) => (
                          <a
                            href={props.href}
                            onClick={(e) => {
                              e.preventDefault();
                              if (props.href) {
                                handleOpenLink(props.href);
                              }
                            }}
                            style={{ cursor: 'pointer', color: '#1890ff' }}
                          >
                            {props.children}
                          </a>
                        ),
                        p: ({ node, ...props }) => (
                          <p {...props} />
                        ),
                        text: ({ node, ...props }) => {
                          return <>{props.children}</>
                        }
                      }}
                    >
                      {/* Очищаем входные данные перед передачей в ReactMarkdown */}
                      {message.decryptedContent ? sanitize(message.decryptedContent) : ''}
                    </ReactMarkdown>
                  </div>
                  <div
                    className={`${styles.messageTime} ${
                      message.sender_id === currentUser?.id
                        ? styles.messageTimeRight
                        : styles.messageTimeLeft
                    }`}
                  >
                    {moment.utc(message.created_at).format("HH:mm")}
                  </div>
                </div>
                {message.sender_id === currentUser?.id && (
                  <Avatar
                    src={currentUser.avatar_url}
                    icon={!currentUser.avatar_url && <UserOutlined />}
                    className={styles.avatar}
                  />
                )}
              </Space>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.inputContainer}>
        <Space.Compact className={styles.inputWrapper}>
          <TextArea
            className={styles.messageInput}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onPressEnter={(e) => {
              if (!e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            placeholder={t("Type a message...")}
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            icon={<SendOutlined />}
            className={styles.sendButton}
          />
        </Space.Compact>
      </div>
    </div>
  );
};

export default ChatWindow;
