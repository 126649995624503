// src/pages/LastAuctionWinners.tsx
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { Card, Typography, List, Spin, Alert, Space, Tag } from "antd";
import { TrophyOutlined } from "@ant-design/icons";
import { fetchLastAuctionWinners } from "../../helpers/api";
import { Winner } from "../../types";
import styles from "./Auction.module.css";

const { Title, Text } = Typography;

const LastAuctionWinners: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userData?.id);
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getWinners = async () => {
      try {
        const data = await fetchLastAuctionWinners();
        setWinners(data);
      } catch (err) {
        setError(t("Failed to fetch winners"));
      } finally {
        setLoading(false);
      }
    };

    getWinners();
  }, [t]);

  const handleUserClick = (userIdToNavigate: number) => {
    if (userIdToNavigate !== userId) {
      navigate(`/partners/${userIdToNavigate}`);
    }
  };

  const formatUserName = (bidUserId: number, firstName?: string) => {
    if (bidUserId === userId) {
      return <Tag color="success" className={styles.truncatedText}>{t('You')}</Tag>;
    }
    return <Tag color="error" className={styles.truncatedText}>{firstName || t('Participant')}</Tag>;
  };


  return (
    <Card
      title={
        <Title level={4}>
          <TrophyOutlined /> {t("Last Winners")}
        </Title>
      }
      className={styles.winnersCardContainer}
    >
      {loading ? (
        <div className={styles.loaderContainer}>
          <Spin size="large" />
        </div>
      ) : error ? (
        <Alert message={error} type="error" showIcon className={styles.alertMessage} />
      ) : winners.length === 0 ? (
        <Alert message={t("No winners yet")} type="info" showIcon className={styles.infoAlert} />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={winners}
          renderItem={(winner) => (
            <List.Item
              className={`${styles.winnerItem} ${winner.winner_id === userId ? styles.winnerItemUser : styles.winnerItemOther}`}
              onClick={() => handleUserClick(winner.winner_id)}
            >
              {formatUserName(winner.winner_id, winner.first_name)}
              <Space size={4}>
                <img src="./assets/LogoW.png" alt="Logo" width={16} />
                <Text strong>{winner.highest_bid}</Text>
                <Text strong>WEB</Text>
              </Space>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

export default LastAuctionWinners;