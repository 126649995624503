import React, { useState, useEffect } from 'react';
import { List, Avatar, Badge, Typography, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ru';
import { UserOutlined } from '@ant-design/icons';
import { fetchUserChats } from '../../helpers/api';
import { ChatSession } from '../../types';
import { decryptMessage } from '../../helpers/encryption';
import styles from './chat.module.css';

interface DecryptedChatSession extends Omit<ChatSession, 'last_message'> {
  last_message: string;
  decryptedLastMessage?: string;
}

const ChatList: React.FC = () => {
  const [chats, setChats] = useState<DecryptedChatSession[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const decryptChats = async (encryptedChats: ChatSession[]) => {
    const decryptedChats = await Promise.all(
      encryptedChats.map(async (chat) => {
        try {
          const [encrypted_content, iv] = chat.last_message.split(':');
          const decryptedLastMessage = await decryptMessage(encrypted_content, iv);
          return {
            ...chat,
            decryptedLastMessage
          };
        } catch (error) {
          console.error('Error decrypting chat message:', error);
          return {
            ...chat,
            decryptedLastMessage: t('Message cannot be displayed')
          };
        }
      })
    );
    return decryptedChats;
  };

  const loadChats = async () => {
    try {
      const data = await fetchUserChats();
      const decryptedChats = await decryptChats(data.chats);
      setChats(decryptedChats);
    } catch (error) {
      console.error('Error fetching chats:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    moment.locale(i18n.language);
    loadChats();
    const interval = setInterval(loadChats, 5000);
    return () => clearInterval(interval);
  }, [i18n.language]);

  const formatDate = (date: string) => {
    return moment.utc(date).fromNow();
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={chats}
      renderItem={(chat) => (
        <List.Item
          onClick={() => navigate(`/chat/${chat.user_id}`)}
          className={styles.chatListItem}
        >
          <List.Item.Meta
            className={styles.chatListMeta}
            avatar={
              <Badge count={chat.unread_count}>
                <Avatar
                  src={chat.avatar_url}
                  icon={!chat.avatar_url && <UserOutlined />}
                  className={styles.chatListAvatar}
                />
              </Badge>
            }
            title={`${chat.first_name} ${chat.last_name}`}
            description={
              <Typography.Text 
                ellipsis 
                className={styles.lastMessage}
              >
                {chat.decryptedLastMessage}
              </Typography.Text>
            }
          />
          <Typography.Text 
            type="secondary"
            className={styles.messageTime}
          >
            {formatDate(chat.last_message_at)}
          </Typography.Text>
        </List.Item>
      )}
    />
  );
};

export default ChatList; 