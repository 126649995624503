import  { FC, useEffect, useState } from "react";
import {
  Typography,
  Card,
  Button,
  Space,
  Tag,
  Descriptions,
  Progress,
  Spin,
  message,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  LinkOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MessageOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GlobalOutlined,
  YoutubeOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { fetchTaskDetails } from "../../helpers/api";
import { UserTask } from "../../types";
import styles from "./UserTasks.module.css";
import WebApp from "@twa-dev/sdk";

const { Title, Paragraph } = Typography;

// Компонент для отображения иконки социальной сети
const SocialIcon: FC<{ link: string; socialType?: string }> = ({ link, socialType }) => {
  const { t } = useTranslation();
  const linkLower = link.toLowerCase();

  // Если указан тип социальной сети, используем его
  if (socialType) {
    switch (socialType) {
      case "telegram":
        return (
          <Tag color="blue">
            <MessageOutlined /> Telegram
          </Tag>
        );
      case "vk":
        return (
          <Tag color="blue">
            <MessageOutlined /> VK
          </Tag>
        );
      case "x":
        return (
          <Tag color="black">
            <TwitterOutlined /> X
          </Tag>
        );
      case "facebook":
        return (
          <Tag color="blue">
            <FacebookOutlined /> Facebook
          </Tag>
        );
      case "linkedin":
        return (
          <Tag color="blue">
            <LinkedinOutlined /> LinkedIn
          </Tag>
        );
      case "medium":
        return (
          <Tag color="black">
            <GlobalOutlined /> Medium
          </Tag>
        );
      case "discord":
        return (
          <Tag color="purple">
            <MessageOutlined /> Discord
          </Tag>
        );
      case "youtube":
        return (
          <Tag color="red">
            <YoutubeOutlined /> YouTube
          </Tag>
        );
      case "miniapps":
        return (
          <Tag color="cyan">
            <AppstoreOutlined /> Mini Apps
          </Tag>
        );
      default:
        return (
          <Tag color="default">
            <GlobalOutlined /> {t("Other")}
          </Tag>
        );
    }
  }

  // Если тип не указан, определяем по ссылке (для обратной совместимости)
  if (linkLower.includes("t.me/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> Telegram
      </Tag>
    );
  } else if (linkLower.includes("vk.com/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> VK
      </Tag>
    );
  } else if (linkLower.includes("x.com/")) {
    return (
      <Tag color="black">
        <TwitterOutlined /> X
      </Tag>
    );
  } else if (linkLower.includes("facebook.com/")) {
    return (
      <Tag color="blue">
        <FacebookOutlined /> Facebook
      </Tag>
    );
  } else if (linkLower.includes("linkedin.com/")) {
    return (
      <Tag color="blue">
        <LinkedinOutlined /> LinkedIn
      </Tag>
    );
  } else if (linkLower.includes("medium.com/")) {
    return (
      <Tag color="black">
        <GlobalOutlined /> Medium
      </Tag>
    );
  } else if (linkLower.includes("discord.gg/")) {
    return (
      <Tag color="purple">
        <MessageOutlined /> Discord
      </Tag>
    );
  } else if (linkLower.includes("youtube.com/")) {
    return (
      <Tag color="red">
        <YoutubeOutlined /> YouTube
      </Tag>
    );
  }

  return (
    <Tag color="default">
      <GlobalOutlined /> {t("Other")}
    </Tag>
  );
};

const TaskDetails: FC = () => {
  const { t } = useTranslation();
  const { taskId } = useParams<{ taskId: string }>();
  const [task, setTask] = useState<UserTask | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadTaskDetails = async () => {
      if (!taskId) return;

      try {
        setLoading(true);
        const taskData = await fetchTaskDetails(parseInt(taskId));
        setTask(taskData);
      } catch (error) {
        console.error("Error fetching task details:", error);
        message.error(t("tasksFetchError"));
      } finally {
        setLoading(false);
      }
    };

    loadTaskDetails();
  }, [taskId, t]);

  const handleOpenLink = (task: UserTask) => {
    const link = task.link;
    const socialType = task.social_type || "other";
    
    // Для Telegram используем специальный метод
    if (socialType === "telegram" || socialType === "miniapps" || link.includes("t.me/")) {
      WebApp.openTelegramLink(link);
    } else {
      // Для других социальных сетей открываем в браузере
      window.open(link, "_blank");
    }
  };

  const renderTaskStatus = (task: UserTask) => {
    if (task.status === "active") {
      return (
        <Tag color="green">
          <CheckCircleOutlined /> {t("active")}
        </Tag>
      );
    } else if (task.status === "completed") {
      return (
        <Tag color="blue">
          <CheckCircleOutlined /> {t("completed")}
        </Tag>
      );
    } else if (task.status === "rejected") {
      return (
        <Tooltip title={task.rejection_reason}>
          <Tag color="red">
            <CloseCircleOutlined /> {t("rejected")}
          </Tag>
        </Tooltip>
      );
    } else if (task.status === "pending") {
      return (
        <Tag color="orange">
          <CheckCircleOutlined /> {t("pending")}
        </Tag>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className={styles["loader-container"]}>
        <Spin size="large" />
      </div>
    );
  }

  if (!task) {
    return (
      <div className={styles["task-details-container"]}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div className={styles["page-header"]}>
            <Title level={3}>{t("taskDetails")}</Title>
          </div>
          <Card>
            <Paragraph>{t("taskNotFound")}</Paragraph>
          </Card>
        </Space>
      </div>
    );
  }

  const progressPercent = Math.min(
    Math.round((task.total_participants / task.max_participants) * 100),
    100
  );

  return (
    <div className={styles["task-details-container"]}>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <div className={styles["page-header"]}>
          <Title level={3}>{t("taskDetails")}</Title>
        </div>

        <Card className={styles["task-details-card"]}>
          <div className={styles["task-details-header"]}>
            {renderTaskStatus(task)}
            <Title level={4}>{task.title}</Title>
          </div>

          <div className={styles["task-details-progress"]}>
            <Progress
              percent={progressPercent}
              status="active"
              format={() =>
                `${task.total_participants}/${task.max_participants}`
              }
            />
          </div>

          <div className={styles["task-details-content"]}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t("taskDescription")}>
                {task.description}
              </Descriptions.Item>
              <Descriptions.Item label={t("taskType")}>
                {task.type === "channel"
                  ? t("taskTypeChannel")
                  : task.type === "group"
                  ? t("taskTypeGroup")
                  : t("taskTypePost")}
              </Descriptions.Item>
              <Descriptions.Item label={t("socialMediaType")}>
                <SocialIcon link={task.link} socialType={task.social_type} />
              </Descriptions.Item>
              <Descriptions.Item label={t("taskReward")}>
                {task.reward} $WEB
              </Descriptions.Item>
              <Descriptions.Item label={t("taskTotalReward")}>
                {task.reward * task.max_participants} $WEB
              </Descriptions.Item>
              <Descriptions.Item label={t("taskParticipants")}>
                {task.total_participants}/{task.max_participants}
              </Descriptions.Item>
              <Descriptions.Item label={t("taskCreatedAt")}>
                {new Date(task.created_at).toLocaleDateString()}
              </Descriptions.Item>
              {task.status === "rejected" && task.rejection_reason && (
                <Descriptions.Item label={t("rejectionReason")}>
                  {task.rejection_reason}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>

          <div className={styles["task-details-footer"]}>
            <Button
              type="primary"
              icon={<LinkOutlined />}
              onClick={() => handleOpenLink(task)}
            >
              {t("openLink")}
            </Button>
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default TaskDetails;
