import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { Tabs, Card, Space, Typography, Tag } from "antd";
import {
  DollarCircleOutlined,
  BankOutlined,
  TeamOutlined,
  HistoryOutlined,
  SwapOutlined,
  UserOutlined,
  RiseOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  BarChartOutlined,
  LineChartOutlined,
  SendOutlined,
  CustomerServiceOutlined,
  InfoCircleOutlined,
  ThunderboltOutlined,
  StarOutlined,
  TrophyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import WebApp from "@twa-dev/sdk";

const { Text } = Typography;

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const [activeTab, setActiveTab] = useState(() =>
    localStorage.getItem('dashboardActiveTab') || 'loans'
  );

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    localStorage.setItem('dashboardActiveTab', key);
  };

  const menuItems = {
    loans: [
      {
        path: "/loan-issuance",
        title: t("to issue a loan"),
        icon: <DollarCircleOutlined />,
      },
      {
        path: "/loan-overnight",
        title: t("Overnight Loan"),
        icon: <SwapOutlined />,
        tag: <Tag color="red">{t("Popular")}</Tag>
      },
      {
        path: "/loan-insure",
        title: t("Take out a loan"),
        icon: <BankOutlined />,
      },
      {
        path: "/loans",
        title: t("My Loans"),
        icon: <DollarCircleOutlined />,
      },
      {
        path: "/invest",
        title: t("Invest in Loans Wholesale"),
        icon: <BankOutlined />,
      },
      {
        path: "/history",
        title: t("history"),
        icon: <HistoryOutlined />,
      },
    ],
    trading: [
      {
        path: "/certificates/sales",
        title: t("Certificate Exchange"),
        icon: <SwapOutlined />,
      },
      {
        path: "/auction",
        title: t("WEB Premarket Auction"),
        icon: <RiseOutlined />,
      },
      {
        path: "/web-tokens",
        title: t("web credits"),
        icon: <img src="./assets/LogoW.png" alt="web credits" width={16} />,
      },
    ],
    info: [
      {
        path: "/settings/faq",
        title: t("FAQ"),
        icon: <QuestionCircleOutlined />,
      },
      {
        path: "/invest/marketing",
        title: t("Marketing"),
        icon: <LineChartOutlined />,
      },
      {
        path: "https://t.me/webtransferp2p",
        title: t("community"),
        icon: <TeamOutlined />,
        external: true
      },
      {
        path: "https://t.me/webtransfercredit",
        title: t("News"),
        icon: <SendOutlined />,
        external: true
      },
      {
        path: "https://t.me/webtransferp2p/55828",
        title: t("Promotions"),
        icon: <ThunderboltOutlined />,
        external: true
      },
      {
        path: "https://t.me/webtransferp2p/4935",
        title: t("Support"),
        icon: <CustomerServiceOutlined />,
        external: true
      },
      {
        path: "/settings/about",
        title: t("About"),
        icon: <InfoCircleOutlined />
      }
    ],
    account: [
      {
        path: `/partners/${userData?.id}`,
        title: t("My profile"),
        icon: <UserOutlined />,
      },
      {
        path: "/achievements",
        title: t("Achievements"),
        icon: <TrophyOutlined />,
      },
      {
        path: "/feed",
        title: t("Feed"),
        icon: <NotificationOutlined />,
      },
      {
        path: "/statistics",
        title: t("statistics"),
        icon: <BarChartOutlined />,
      },
      {
        path: "/invest-contest",
        title: t("Invest Contest"),
        icon: <TrophyOutlined />,
      },
      {
        path: "/user-tasks",
        title: t("userTasks"),
        icon: <CheckCircleOutlined />,
      },
      {
        path: "/contest",
        title: t("Activity Contest"),
        icon: <TrophyOutlined />,
      },
      {
        path: "/premium",
        title: t("Premium"),
        icon: <StarOutlined />,
      },
      {
        path: "/partners",
        title: t("partners network"),
        icon: <TeamOutlined />,
      },
      {
        path: "/withdraw",
        title: t("Withdrawal"),
        icon: <DollarCircleOutlined />,
      },

    ],
  };

  const renderMenuItem = (item: any) => (
    <Card
      size="small"
      onClick={() => {
        if (item.external) {
          WebApp.openTelegramLink(item.path);
        } else {
          navigate(item.path);
        }
      }}
      style={{
        cursor: "pointer",
        marginBottom: "8px",
        borderRadius: "12px",
        background: "#f8fafc",
      }}
      className="hover-card"
    >
      <Space
        align="center"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        <Space>
          {item.icon}
          <Text>{item.title}</Text>
        </Space>
        {item.tag}
      </Space>
    </Card>
  );

  const items = [
    {
      key: "loans",
      label: t("Loans"),
      children: menuItems.loans.map(renderMenuItem),
    },
    {
      key: "account",
      label: t("Account"),
      children: menuItems.account.map(renderMenuItem),
    },
    {
      key: "trading",
      label: t("Trading"),
      children: menuItems.trading.map(renderMenuItem),
    },
    {
      key: "info",
      label: t("Info"),
      children: menuItems.info.map(renderMenuItem),
    },
  ];

  return (
    <div style={{ padding: "0 16px" }}>
      <Tabs 
        items={items} 
        animated 
        style={{ marginTop: "16px" }}
        activeKey={activeTab}
        onChange={handleTabChange}
      />
    </div>
  );
};

export default Dashboard;
