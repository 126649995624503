import { FC, useState } from "react";
import { MainButton } from "@twa-dev/sdk/react";
import { useTranslation } from "react-i18next";
import { sendTransaction } from "../../helpers/transactionService";
import { useSelector, useDispatch } from "react-redux";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { RootState } from "../../services/store";
import NonPremiumContent from "./nonPremiumContent";
import PremiumContent from "./PremiumContent";
import { Spin } from "antd";
import styles from "./premium.module.css";
import { updateUserData } from "../../services/slices/userSlice";

const Premium: FC = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [tonConnectUI] = useTonConnectUI();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setDisabled(true);
    const comment = `UserId:${userData!.id};`;

    const myTransaction = await sendTransaction(
      comment,
      "TON",
      null,
      "UQCOB-m8imN3d6BQ6y2yrLota6epBkrD4Y05hgmsXEuS8bzT",
      1,
      false
    );
    if (myTransaction) {
      try {
        const result = await tonConnectUI.sendTransaction(myTransaction);
        if (result) {
          dispatch(updateUserData({ premium_status: 1 }));
        }
      } catch (error) {
        console.error("Transaction failed:", error);
      }
    } else {
      console.error("Transaction could not be created.");
    }
    setDisabled(false);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {userData && userData.premium_status === 1 ? (
        <PremiumContent />
      ) : (
        <>
          <NonPremiumContent />
          <MainButton
            text={t("Buy Premium Status")}
            onClick={handleSubmit}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};

export default Premium;
