import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, List, Input } from "antd";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  SendOutlined,
  LoadingOutlined,
  UserOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CommentItem } from "../types";

const { TextArea } = Input;

const CommentList = ({
  comments,
  onDelete,
}: {
  comments: CommentItem[];
  onDelete: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);

  return (
    <List
      dataSource={comments}
      header={
        <div style={{ fontSize: "16px", fontWeight: 500, padding: "8px 0" }}>
          {t("comments")}
        </div>
      }
      itemLayout="horizontal"
      style={{
        width: "100%",
        overflowX: "hidden",
        height: "400px",
        overflowY: "auto",
        paddingRight: "8px",
      }}
      renderItem={(props) => (
        <div
          className="comment_contain"
          style={{
            padding: "12px 0",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          <div
            className="comment"
            onClick={() => navigate(`/partners/${props.author_id}`)}
            style={{
              display: "flex",
              flex: 1,
              minWidth: 0,
              wordBreak: "break-word",
            }}
          >
            <Avatar
              src={props.avatar}
              size={40}
              icon={<UserOutlined />}
              style={{ flexShrink: 0 }}
            />
            <div
              className="comment_texts"
              style={{
                marginLeft: "12px",
                flex: 1,
                minWidth: 0,
              }}
            >
              <p
                className="comment_author"
                style={{ margin: "0 0 4px 0", fontWeight: 500 }}
              >
                {props.author}
              </p>
              <div
                className="comment_content"
                style={{
                  margin: "0 0 4px 0",
                  wordWrap: "break-word",
                  overflow: "hidden",
                }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        href={props.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#1677ff" }}
                      >
                        {props.children}
                      </a>
                    ),
                  }}
                >
                  {props.content}
                </ReactMarkdown>
              </div>
              <span
                className="comment_date"
                style={{ fontSize: "12px", color: "#8c8c8c" }}
              >
                {moment.utc(props.datetime).fromNow()}
              </span>
            </div>
          </div>
          {(userData?.id === props.author_id ||
            userData?.id === props.post_author_id) && (
            <CloseOutlined
              onClick={() => onDelete(props.id)}
              className="comments_icon"
              style={{
                color: "#ff4d4f",
                fontSize: "16px",
                marginLeft: "8px",
                flexShrink: 0,
              }}
            />
          )}
        </div>
      )}
    />
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="create_comment_input">
        <TextArea
          rows={1}
          maxLength={500}
          showCount
          value={value}
          onChange={onChange}
          placeholder={t("Write a comment")}
          autoSize={{ minRows: 1, maxRows: 10 }}
          className="create_posts_input"
        />
        {submitting ? <LoadingOutlined /> : <SendOutlined onClick={onSubmit} />}
      </div>
    </>
  );
};

interface CommentsProps {
  postId: number;
}

const CommentsSection: FC<CommentsProps> = ({ postId }) => {
  const [localComments, setLocalComments] = useState<CommentItem[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const { userData } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  const fetchComments = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/api/account/get_post_comments.php", {
        post_id: postId,
      });
      setLocalComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [postId]);

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);

    try {
      const response = await axios.post("/api/account/add_post_comment.php", {
        post_id: postId,
        content: value,
      });

      if (response.data.status === "success") {
        const newCommentId = response.data.comment_id;
        setLocalComments([
          ...localComments,
          {
            id: newCommentId,
            author_id: userData!.id,
            post_author_id: response.data.post_author_id,
            author: userData!.first_name,
            avatar: userData!.avatar_url,
            content: value,
            datetime: moment().utc().toISOString(),
          },
        ]);
        setValue("");
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteComment = async (id: number) => {
    try {
      const response = await axios.post(
        "/api/account/delete_post_comment.php",
        {
          comment_id: id,
        }
      );

      if (response.data.status === "success") {
        setLocalComments(localComments.filter((comment) => comment.id !== id));
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowX: "hidden",
        height: "100%",
      }}
    >
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <LoadingOutlined style={{ fontSize: 24 }} />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              overflowX: "hidden",
              flex: 1,
            }}
          >
            {localComments.length > 0 && (
              <CommentList
                comments={localComments}
                onDelete={handleDeleteComment}
              />
            )}
          </div>
          <div
            className="create_comment"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "#fff",
              paddingTop: 16,
              width: "100%",
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <Avatar
              src={userData?.avatar_url}
              alt="User"
              size={30}
              icon={<UserOutlined />}
            />
            <Editor
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setValue(e.target.value)
              }
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CommentsSection;
