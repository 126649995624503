import { FC, useState, useEffect } from "react";
import {
  Typography,
  Tabs,
  Card,
  Button,
  List,
  Badge,
  Spin,
  message,
  Pagination,
  Modal,
  Tooltip,
  Empty,
  Progress,
  Form,
  Input,
  Tag,
  Select,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../services/store";
import { updateWebTokens } from "../../services/slices/userSlice";
import {
  fetchUserTasks,
  completeUserTask,
  fetchCreatedTasks,
} from "../../helpers/api";
import { UserTask } from "../../types";
import WebApp from "@twa-dev/sdk";
import styles from "./UserTasks.module.css";
import {
  LinkOutlined,
  QuestionCircleTwoTone,
  PlusOutlined,
  TeamOutlined,
  CrownOutlined,
  MessageOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GlobalOutlined,
  YoutubeOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;


const SocialIcon: FC<{ link: string; socialType?: string }> = ({ link, socialType }) => {
  const { t } = useTranslation();
  const linkLower = link.toLowerCase();

  if (socialType) {
    switch (socialType) {
      case "telegram":
        return (
          <Tag color="blue">
            <MessageOutlined /> Telegram
          </Tag>
        );
      case "vk":
        return (
          <Tag color="blue">
            <MessageOutlined /> VK
          </Tag>
        );
      case "x":
        return (
          <Tag color="black">
            <TwitterOutlined /> X
          </Tag>
        );
      case "facebook":
        return (
          <Tag color="blue">
            <FacebookOutlined /> Facebook
          </Tag>
        );
      case "linkedin":
        return (
          <Tag color="blue">
            <LinkedinOutlined /> LinkedIn
          </Tag>
        );
      case "medium":
        return (
          <Tag color="black">
            <GlobalOutlined /> Medium
          </Tag>
        );
      case "discord":
        return (
          <Tag color="purple">
            <MessageOutlined /> Discord
          </Tag>
        );
      case "youtube":
        return (
          <Tag color="red">
            <YoutubeOutlined /> YouTube
          </Tag>
        );
      case "miniapps":
        return (
          <Tag color="cyan">
            <AppstoreOutlined /> Mini Apps
          </Tag>
        );
      default:
        return (
          <Tag color="default">
            <GlobalOutlined /> {t("Other")}
          </Tag>
        );
    }
  }

  if (linkLower.includes("t.me/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> Telegram
      </Tag>
    );
  } else if (linkLower.includes("vk.com/")) {
    return (
      <Tag color="blue">
        <MessageOutlined /> VK
      </Tag>
    );
  } else if (linkLower.includes("x.com/")) {
    return (
      <Tag color="black">
        <TwitterOutlined /> X
      </Tag>
    );
  } else if (linkLower.includes("facebook.com/")) {
    return (
      <Tag color="blue">
        <FacebookOutlined /> Facebook
      </Tag>
    );
  } else if (linkLower.includes("linkedin.com/")) {
    return (
      <Tag color="blue">
        <LinkedinOutlined /> LinkedIn
      </Tag>
    );
  } else if (linkLower.includes("medium.com/")) {
    return (
      <Tag color="black">
        <GlobalOutlined /> Medium
      </Tag>
    );
  } else if (linkLower.includes("discord.gg/")) {
    return (
      <Tag color="purple">
        <MessageOutlined /> Discord
      </Tag>
    );
  } else if (linkLower.includes("youtube.com/")) {
    return (
      <Tag color="red">
        <YoutubeOutlined /> YouTube
      </Tag>
    );
  }

  return (
    <Tag color="default">
      <GlobalOutlined /> {t("Other")}
    </Tag>
  );
};

const UserTasks: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [availableTasks, setAvailableTasks] = useState<UserTask[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<UserTask[]>([]);
  const [completedTasks, setCompletedTasks] = useState<UserTask[]>([]);
  const [createdTasks, setCreatedTasks] = useState<UserTask[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>("available");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState<boolean>(false);
  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState<boolean>(false);
  const [verificationForm] = Form.useForm();
  const [currentTaskToComplete, setCurrentTaskToComplete] =
    useState<UserTask | null>(null);
  const [selectedSocialType, setSelectedSocialType] = useState<string>("all");

  const socialMediaTypes = [
    { value: "all", label: t("All") },
    { value: "telegram", label: "Telegram" },
    { value: "vk", label: "VK" },
    { value: "x", label: "X" },
    { value: "facebook", label: "Facebook" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "youtube", label: "YouTube" },
    { value: "medium", label: "Medium" },
    { value: "discord", label: "Discord" },
    { value: "miniapps", label: "Mini Apps" },
    { value: "other", label: t("Other") },
  ];

  useEffect(() => {
    fetchTasks();
  }, [activeTab, currentPage, selectedSocialType]);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      if (activeTab === "available") {
        const response = await fetchUserTasks(
          currentPage, 
          pageSize, 
          "active", 
          selectedSocialType
        );
        setAvailableTasks(response.tasks);
        setFilteredTasks(response.tasks);
        setTotalPages(response.pagination.total_pages);
      } else if (activeTab === "completed") {
        const response = await fetchUserTasks(
          currentPage, 
          pageSize, 
          "completed", 
          selectedSocialType
        );
        setCompletedTasks(response.tasks);
        setFilteredTasks(response.tasks);
        setTotalPages(response.pagination.total_pages);
      } else if (activeTab === "created") {
        const response = await fetchCreatedTasks(
          currentPage, 
          pageSize, 
          undefined, 
          selectedSocialType
        );
        setCreatedTasks(response.tasks);
        setFilteredTasks(response.tasks);
        setTotalPages(response.pagination.total_pages);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      message.error(t("tasksFetchError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setCurrentPage(1);
    setSelectedSocialType("all"); // Сбрасываем фильтр при смене вкладки
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSocialTypeChange = (value: string) => {
    setSelectedSocialType(value);
    setCurrentPage(1);
  };

  const handleOpenLink = (task: UserTask) => {
    const link = task.link;
    const socialType = task.social_type || "other";
    if (socialType === "telegram" || socialType === "miniapps" || link.includes("t.me/")) {
      WebApp.openTelegramLink(link);
    } else {
      WebApp.openLink(link);
    }
  };

  const handleCompleteTask = async (task: UserTask) => {
    setCurrentTaskToComplete(task);
    setIsVerificationModalVisible(true);
  };

  const handleVerificationSubmit = async (values: { code: string }) => {
    if (!currentTaskToComplete) return;

    if (values.code !== currentTaskToComplete.verification_code) {
      message.error(t("incorrectVerificationCode"));
      return;
    }

    setIsLoading(true);
    setIsVerificationModalVisible(false);

    try {
      const response = await completeUserTask(
        currentTaskToComplete.id,
        values.code
      );
      if (response.success) {
        message.success(
          t("taskCompletedSuccess", { reward: currentTaskToComplete.reward })
        );
        dispatch(updateWebTokens(currentTaskToComplete.reward));
        fetchTasks();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error completing task:", error);
      message.error(t("taskCompletionError"));
    } finally {
      setIsLoading(false);
      setCurrentTaskToComplete(null);
      verificationForm.resetFields();
    }
  };

  const handleVerificationCancel = () => {
    setIsVerificationModalVisible(false);
    setCurrentTaskToComplete(null);
    verificationForm.resetFields();
  };

  const showTaskDetails = (task: UserTask) => {
    navigate(`/user-tasks/task-details/${task.id}`);
  };

  const showCreateModal = () => {
    navigate("/user-tasks/create-task");
  };

  const showDescriptionModal = () => {
    setIsDescriptionModalVisible(true);
  };

  const handleDescriptionModalCancel = () => {
    setIsDescriptionModalVisible(false);
  };

  const renderTaskList = () => {
    let tasks: UserTask[] = [];

    if (activeTab === "available") {
      tasks = filteredTasks.length > 0 ? filteredTasks : availableTasks;
    } else if (activeTab === "completed") {
      tasks = filteredTasks.length > 0 ? filteredTasks : completedTasks;
    } else if (activeTab === "created") {
      tasks = filteredTasks.length > 0 ? filteredTasks : createdTasks;
    }

    return (
      <>
        <div style={{ marginBottom: 16 }}>
          <Row gutter={16} align="middle">
          {activeTab === "created" && (
              <Col xs={24} sm={12} md={16} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showCreateModal}
                  className={styles["create-task-button"]}
                >
                  {t("createTask")}
                </Button>
              </Col>
            )}
            <Col xs={24} sm={12} md={8}>
              <Select
                style={{ width: "100%" }}
                value={selectedSocialType}
                onChange={handleSocialTypeChange}
                placeholder={t("Select social media type")}
                loading={isLoading}
              >
                {socialMediaTypes.map(type => (
                  <Option key={type.value} value={type.value}>{type.label}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>

        {tasks.length === 0 ? (
          <Empty description={t("noTasksAvailable")} />
        ) : (
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={tasks}
            renderItem={(task) => (
              <List.Item key={task.id}>
                <Badge.Ribbon
                  text={`+${task.reward} $WEB`}
                  color={
                    task.total_participants >= task.max_participants
                      ? "red"
                      : "blue"
                  }
                >
                  <Card
                    hoverable
                    className={`${styles["task-card"]} ${
                      task.is_premium ? styles["premium-task"] : ""
                    }`}
                    actions={[
                      activeTab === "available" ? (
                        <Button
                          type="primary"
                          onClick={() => handleCompleteTask(task)}
                        >
                          {t("completeTask")}
                        </Button>
                      ) : (
                        <Typography.Link
                          key="details"
                          onClick={() => showTaskDetails(task)}
                        >
                          {t("viewDetails")}
                        </Typography.Link>
                      ),
                    ]}
                  >
                    {task.is_premium === 1 && (
                      <Tooltip title={t("premiumTask")}>
                        <CrownOutlined
                          style={{ color: "gold", marginLeft: 8 }}
                        />
                      </Tooltip>
                    )}
                    <Progress
                      percent={Math.min(
                        (task.total_participants / task.max_participants) * 100,
                        100
                      )}
                      showInfo={false}
                      strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                      }}
                      strokeWidth={6}
                      className={styles["task-progress-bar"]}
                    />
                    <div className={styles["task-header"]}>
                      <div className={styles["task-stats"]}>
                        <Tooltip title={t("participants")}>
                          <span>
                            <TeamOutlined /> {task.total_participants}/
                            {task.max_participants}
                          </span>
                        </Tooltip>
                      </div>
                      <div>
                        <Typography.Title level={5}>
                          {task.title}
                        </Typography.Title>
                      </div>
                    </div>
                    <Typography.Paragraph ellipsis={{ rows: 5 }}>
                      {task.description}
                    </Typography.Paragraph>
                    <div className={styles["task-footer"]}>
                      <SocialIcon link={task.link} socialType={task.social_type} />
                      <Button
                        type="link"
                        icon={<LinkOutlined />}
                        onClick={() => handleOpenLink(task)}
                      >
                        {t("openLink")}
                      </Button>
                    </div>
                  </Card>
                </Badge.Ribbon>
              </List.Item>
            )}
          />
        )}
      </>
    );
  };

  if (
    isLoading &&
    !availableTasks.length &&
    !completedTasks.length &&
    !createdTasks.length
  ) {
    return (
      <div className={styles["loader-container"]}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles["user-tasks-container"]}>
      <div className={styles["tasks-header"]}>
        <Title level={3}>{t("userTasks")}</Title>
        <div className={styles["tasks-actions"]}>
          <Tooltip title={t("taskInstructions")}>
            <Button
              type="text"
              icon={<QuestionCircleTwoTone />}
              onClick={showDescriptionModal}
            />
          </Tooltip>
        </div>
      </div>

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab={t("availableTasks")} key="available">
          {renderTaskList()}
        </TabPane>
        <TabPane tab={t("completedTasks")} key="completed">
          {renderTaskList()}
        </TabPane>
        <TabPane tab={t("createdTasks")} key="created">
          {renderTaskList()}
        </TabPane>
      </Tabs>

      {totalPages > 1 && (
        <div className={styles["pagination-container"]}>
          <Pagination
            current={currentPage}
            total={totalPages * pageSize}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}

      <Modal
        title={t("taskInstructions")}
        open={isDescriptionModalVisible}
        onCancel={handleDescriptionModalCancel}
        footer={[
          <Button key="close" onClick={handleDescriptionModalCancel}>
            {t("Close")}
          </Button>,
        ]}
      >
        <Typography.Paragraph>{t("TaskDescriptionUsers")}</Typography.Paragraph>
      </Modal>

      <Modal
        title={t("enterVerificationCode")}
        open={isVerificationModalVisible}
        onCancel={handleVerificationCancel}
        footer={null}
      >
        <Form form={verificationForm} onFinish={handleVerificationSubmit}>
          <Form.Item
            name="code"
            rules={[
              { required: true, message: t("pleaseEnterVerificationCode") },
              {
                pattern: /^\d{4}$/,
                message: t("verificationCodeMustBe4Digits"),
              },
            ]}
          >
            <Input placeholder="1234" maxLength={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              {t("verify")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserTasks;
