import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Progress,
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Space,
  Tooltip,
  Divider,
  message,
} from "antd";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import {
  UserOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  TrophyOutlined,
  BarChartOutlined,
  RiseOutlined,
  LikeOutlined,
  CommentOutlined,
  FileTextOutlined,
  TeamOutlined,
  BankOutlined,
} from "@ant-design/icons";
import styles from "./Statistics.module.css";
import { RatingDetails } from "../../types";
import { fetchStatistics } from "../../helpers/api";

const { Title } = Typography;

const Statistics: FC = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const { tonPrice } = useSelector((state: RootState) => state.ton);
  const [creditRating, setCreditRating] = useState<number>(0);
  const [ratingDetails, setRatingDetails] = useState<RatingDetails | null>(
    null
  );
  const [activeLoans, setActiveLoans] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [newUsers, setNewUsers] = useState<number>(0);
  const [webCredits, setWebCredits] = useState<number>(0);
  const [totalActiveLoansUSDT, setTotalActiveLoansUSDT] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ratesData, setRatesData] = useState<{ rate: number; count: number }[]>(
    []
  );

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A569BD",
    "#48C9B0",
    "#F4D03F",
    "#EB984E",
    "#5DADE2",
    "#58D68D",
    "#AF7AC5",
    "#85C1E9",
    "#F5B7B1",
    "#A9CCE3",
    "#F7DC6F",
    "#E74C3C",
    "#D2B4DE",
    "#1ABC9C",
    "#F8C471",
    "#C0392B",
  ];

  useEffect(() => {
    const loadStatistics = async () => {
      try {
        const data = await fetchStatistics();
        setCreditRating(data.creditRating.toFixed(0));
        setRatingDetails(data.ratingDetails);
        setRatesData(data.rates);
        setActiveLoans(data.active_loans);
        setTotalUsers(data.total_users);
        setNewUsers(data.new_users_today);
        setWebCredits(data.total_web_tokens);
        if (tonPrice) {
          setTotalActiveLoansUSDT(
            data.totalActiveLoansUSDT +
              data.totalActiveLoansTON * tonPrice
          );
        }
      } catch (error) {
        message.error(t("Error fetching statistics"));
      } finally {
        setIsLoading(false);
      }
    };

    loadStatistics();
  }, [userData, tonPrice]);


  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.statisticsContainer}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Card className={styles.card}>
          <Space align="center" className={styles.titleWrapper}>
            <TrophyOutlined className={styles.titleIcon} />
            <Title level={5} style={{ margin: 0 }}>
              {t("your credit rating")}
            </Title>
          </Space>
          <div className={styles.ratingCircle}>
            <Progress
              type="circle"
              percent={creditRating}
              size={120}
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              format={(percent) => (
                <div className={styles.ratingValue}>{percent}%</div>
              )}
            />
          </div>
          {ratingDetails && (
            <>
              <Divider>{t("Rating Details")}</Divider>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Tooltip title={t("Based on your total loans")}>
                    <Card
                      size="small"
                      bordered={false}
                      className={styles.loanScoreCard}
                    >
                      <Statistic
                        title={
                          <Space>
                            <BankOutlined />
                            <span>{t("Loan Score")}</span>
                          </Space>
                        }
                        value={ratingDetails.loanScore}
                        suffix={`/ 50 (${ratingDetails.totalLoans} ${t(
                          "loans"
                        )})`}
                        valueStyle={{ color: "#0088FE", fontSize: 14 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Tooltip title={t("Based on your invited partners")}>
                    <Card
                      size="small"
                      bordered={false}
                      className={styles.partnerScoreCard}
                    >
                      <Statistic
                        title={
                          <Space>
                            <TeamOutlined />
                            <span>{t("Partner Score")}</span>
                          </Space>
                        }
                        value={ratingDetails.partnerScore}
                        suffix={`/ 30 (${ratingDetails.partners} ${t(
                          "partners"
                        )})`}
                        valueStyle={{ color: "#00C49F", fontSize: 14 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Tooltip title={t("Based on your posts")}>
                    <Card
                      size="small"
                      bordered={false}
                      className={styles.postScoreCard}
                    >
                      <Statistic
                        title={
                          <Space>
                            <FileTextOutlined />
                            <span>{t("Post Score")}</span>
                          </Space>
                        }
                        value={ratingDetails.postScore}
                        suffix={`/ 10 (${ratingDetails.posts} ${t("posts")})`}
                        valueStyle={{ color: "#FFBB28", fontSize: 14 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Tooltip title={t("Based on your comments")}>
                    <Card
                      size="small"
                      bordered={false}
                      className={styles.commentScoreCard}
                    >
                      <Statistic
                        title={
                          <Space>
                            <CommentOutlined />
                            <span>{t("Comment Score")}</span>
                          </Space>
                        }
                        value={ratingDetails.commentScore}
                        suffix={`/ 5 (${ratingDetails.comments} ${t(
                          "comments_stat"
                        )})`}
                        valueStyle={{ color: "#FF8042", fontSize: 14 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Tooltip title={t("Based on your likes")}>
                    <Card
                      size="small"
                      bordered={false}
                      className={styles.likeScoreCard}
                    >
                      <Statistic
                        title={
                          <Space>
                            <LikeOutlined />
                            <span>{t("Like Score")}</span>
                          </Space>
                        }
                        value={ratingDetails.likeScore}
                        suffix={`/ 5 (${ratingDetails.likes} ${t("likes")})`}
                        valueStyle={{ color: "#A569BD", fontSize: 14 }}
                      />
                    </Card>
                  </Tooltip>
                </Col>
              </Row>

            </>
          )}
        </Card>

        <Card className={styles.card}>
          <Space align="center" className={styles.titleWrapper}>
            <BarChartOutlined className={styles.titleIcon} />
            <Title level={5} style={{ margin: 0 }}>
              {t("percentage rates in the last 24 hours")}
            </Title>
          </Space>
          <div className={styles.ratesChartContainer}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={ratesData}
                  labelLine={false}
                  label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
                  outerRadius={100}
                  innerRadius={60}
                  paddingAngle={5}
                  nameKey="rate"
                  dataKey="count"
                >
                  {ratesData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card
          title={
            <Space>
              <RiseOutlined className={styles.titleIcon} />
              <Title level={5} style={{ margin: 0 }}>
                {t("General statistics")}
              </Title>
            </Space>
          }
          className={styles.card}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Card bordered={false} className={styles.statsCard}>
                <Statistic
                  title={t("Total loans")}
                  value={activeLoans}
                  prefix={<DollarCircleOutlined />}
                  valueStyle={{ color: "#1890ff" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card bordered={false} className={styles.statsCard}>
                <Statistic
                  title={t("Total users")}
                  value={totalUsers}
                  prefix={<UserOutlined />}
                  valueStyle={{ color: "#52c41a" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card bordered={false} className={styles.statsCard}>
                <Statistic
                  title={t("New users")}
                  value={newUsers}
                  prefix={<UsergroupAddOutlined />}
                  valueStyle={{ color: "#faad14" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card bordered={false} className={styles.statsCard}>
                <Statistic
                  title={t("Total web credits")}
                  value={webCredits}
                  prefix={
                    <img
                      src="./assets/LogoW.png"
                      alt="Logo"
                      width={36}
                      style={{ marginRight: 4 }}
                    />
                  }
                  valueStyle={{ color: "#eb2f96" }}
                />
              </Card>
            </Col>
          </Row>
        </Card>

        <Card
          title={
            <Space>
              <UserOutlined className={styles.titleIcon} />
              <Title level={5} style={{ margin: 0 }}>
                {t("Self statistics")}
              </Title>
            </Space>
          }
          className={styles.card}
        >
          <Statistic
            title={t("Total active loans")}
            value={totalActiveLoansUSDT}
            prefix="$"
            precision={2}
            valueStyle={{ color: "#1890ff", fontSize: "24px" }}
          />
        </Card>
      </Space>
    </div>
  );
};

export default Statistics;
