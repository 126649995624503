import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import WebApp from "@twa-dev/sdk";
import { Cell } from "@ton/ton";
import { sendTransactionNew } from "../../helpers/transactionService";
import { MainButton } from "@twa-dev/sdk/react";
import { useNavigate } from "react-router-dom";
import { getPaymentWallet, fetchOvernightVolume } from "../../helpers/api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../services/store";
import styles from "./Overnight.module.css";
import {
  Select,
  InputNumber,
  Form,
  Typography,
  Checkbox,
  Card,
  List,
  message,
  Alert,
  Space,
  Statistic,
  Divider,
  Radio,
  Spin,
} from "antd";
import {
  RightCircleTwoTone,
  BulbTwoTone,
  SafetyCertificateOutlined,
  DollarCircleOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  AreaChartOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { splitAmount } from "../../helpers/functions";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { VolumeData } from "../../types";


const { Title, Text, Link, Paragraph } = Typography;
const { Option } = Select;


const LoanOvernight: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [tokenType, setTokenType] = useState<string>(() => {
    return localStorage.getItem('preferredTokenType') || "USDT";
  });
  const { userData } = useSelector((state: RootState) => state.user);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const [insurance, setInsurance] = useState<boolean>(true);
  const [demand, setDemand] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<React.ReactNode>("");
  const rate = insurance
    ? userData?.settings.max_rate_overnight
    : userData?.settings.max_rate_overnight_high_risk;
  const [volumeData, setVolumeData] = useState<VolumeData[]>([]);
  const [period, setPeriod] = useState<"7d" | "30d">("7d");
  const [isVolumeLoading, setIsVolumeLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('preferredTokenType', tokenType);
  }, [tokenType]);

  const profit = useMemo(() => {
    if (amount && rate) {
      const dailyProfit = amount * (Number(rate) / 100);
      const insuranceAmount = insurance
        ? dailyProfit * 0.25
        : dailyProfit * 0.05;
      return dailyProfit - insuranceAmount;
    }
    return 0;
  }, [amount, rate, insurance]);

  useEffect(() => {
    if (amount) {
      setMessageText(
        <>
          {demand ? t("Your daily profit will be") : t("Your profit will be")}:
          <span style={{ color: "green", marginLeft: 5 }}>
            +{profit.toFixed(3)} {tokenType}
          </span>{" "}
          {demand ? t("with daily increase") : ""}
        </>
      );
    }
  }, [amount, profit, demand, t, tokenType]);

  useEffect(() => {
    const fetchVolumeData = async () => {
      setIsVolumeLoading(true);
      try {
        const data = await fetchOvernightVolume(period);
        const formattedData = data.map((item: VolumeData) => ({
          ...item,
          date: new Date(item.date).toLocaleDateString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
          }),
        }));
        setVolumeData(formattedData);
      } catch (error) {
        console.error("Error fetching volume data:", error);
        setVolumeData([]);
      } finally {
        setIsVolumeLoading(false);
      }
    };

    fetchVolumeData();
  }, [period]);

  const handleSubmit = async () => {
    if (!userFriendlyAddress) {
      message.error(t("Please connect your wallet"));
      return;
    }
    if (!amount || amount <= 0) {
      message.error(t("Please enter valid amount"));
      return;
    }

    setLoading(true);

    try {
      if (userData && userFriendlyAddress) {
        const term = 1;
        const marginLoan = 0;

        let comment = `UserId:${
          userData!.id
        };Rate:${rate};Term:${term};Insurance:${insurance}`;
        if (tokenType === "USDT") {
          comment += `;Margin:${marginLoan}`;
        }
        if (demand) {
          comment += `;Demand:${demand}`;
        }

        const maxPerTx = tokenType === "USDT" ? 200 : 50;
        const minPerTx = tokenType === "USDT" ? 10 : 1;

        const amounts = splitAmount(amount, maxPerTx, minPerTx);

        const recipientAddresses = [];
        for (let i = 0; i < amounts.length; i++) {
          const walletResponse = await getPaymentWallet(userFriendlyAddress);
          if (walletResponse && walletResponse.walletAddress) {
            recipientAddresses.push(walletResponse.walletAddress);
          } else {
            console.error("Failed to fetch payment wallet.");
            return;
          }
        }

        const myTransaction = await sendTransactionNew(
          comment,
          tokenType,
          wallet_address,
          recipientAddresses,
          amounts
        );

        if (myTransaction) {
          const result = await tonConnectUI.sendTransaction(myTransaction);
          const hash = Cell.fromBase64(result.boc).hash().toString("hex");
          console.log({ hash });
        } else {
          console.error("Transaction could not be created.");
        }
      }
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      setLoading(false);
    }
  };

  const features = [
    t("overnight.features.short_duration"),
    t("overnight.features.floating_interest_rate"),
    t("overnight.features.full_insurance"),
    t("overnight.features.profit_commission"),
  ];

  const VolumeChart = () => (
    <div className={styles.chartContainer}>
      <ResponsiveContainer>
        {isVolumeLoading ? (
          <div className={styles.chartLoader}>
            <Spin size="large" />
          </div>
        ) : (
          <AreaChart
            data={volumeData}
            margin={{
              top: 10,
              right: 10,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{ fontSize: 12 }} />
            <YAxis
              tick={{ fontSize: 12 }}
              tickFormatter={(value: number) =>
                value === 0 ? "" : `${(value / 1000).toFixed(1)}K`
              }
            />
            <Tooltip
              formatter={(value: number, name: string) => {
                const formattedValue = Math.round(value).toLocaleString();
                if (name === "USDT") {
                  return [`${formattedValue}`, "USDT"];
                }
                return [`${formattedValue}`, "TON"];
              }}
              labelFormatter={(label) => `${t("Date")}: ${label}`}
            />
            <Area
              type="monotone"
              dataKey="usdt_volume"
              stroke="#26A17B"
              fill="#26A17B"
              fillOpacity={0.2}
              name="USDT"
            />
            <Area
              type="monotone"
              dataKey="ton_volume"
              stroke="#0088CC"
              fill="#0088CC"
              fillOpacity={0.2}
              name="TON"
            />
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  );

  const handleInsuranceChange = useCallback(() => {
    setInsurance((prev) => !prev);
  }, []);

  const handleDemandChange = useCallback(() => {
    setDemand((prev) => !prev);
  }, []);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Card
        title={
          <Space>
            <ClockCircleOutlined
              style={{ color: "#1890ff", fontSize: "24px" }}
            />
            <Title level={3} style={{ margin: 0 }}>
              {t("Overnight Loan")}
            </Title>
          </Space>
        }
        className={styles.overnightCard}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card className={styles.statsCard}>
            <Statistic
              title={t("Current_rate")}
              value={rate}
              suffix="%"
              precision={2}
              valueStyle={{ color: "#52c41a", fontSize: "24px" }}
            />
            {messageText && (
              <Alert
                message={messageText}
                type="success"
                showIcon
                className={styles.alertSuccess}
              />
            )}
          </Card>

          <Form layout="vertical" style={{ width: "100%" }}>
            <Form.Item
              label={t("Enter amount")}
              extra={`Min: ${tokenType === "USDT" ? "10 USDT" : "1 TON"}`}
            >
              <InputNumber
                value={amount}
                addonBefore={
                  <Select
                    value={tokenType}
                    onChange={(value) => setTokenType(value)}
                    style={{ width: 120 }}
                  >
                    <Option value="USDT">
                      <Space>
                        <img
                          src="./assets/UsdtLogo.webp"
                          alt="USDT"
                          width={16}
                        />
                        USDT
                      </Space>
                    </Option>
                    <Option value="TON">
                      <Space>
                        <img src="./assets/tonIcon.png" alt="TON" width={16} />
                        TON
                      </Space>
                    </Option>
                  </Select>
                }
                onChange={(value) => setAmount(value)}
                placeholder={t("Enter amount")}
                min={tokenType === "USDT" ? 10 : 1}
                style={{ width: "100%" }}
                size="large"
                disabled={loading}
              />
            </Form.Item>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Card
                size="small"
                className={styles.checkboxCard}
              >
                <Form.Item style={{ marginBottom: 0 }}>
                  <Checkbox
                    checked={insurance}
                    onChange={handleInsuranceChange}
                  >
                    <Space>
                      <SafetyCertificateOutlined style={{ color: "#52c41a" }} />
                      <Text>{t("To insure a loan")}</Text>
                    </Space>
                  </Checkbox>
                </Form.Item>
              </Card>

              <Card size="small" className={styles.checkboxCard}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Checkbox checked={demand} onChange={handleDemandChange}>
                    <Space>
                      <DollarCircleOutlined style={{ color: "#1890ff" }} />
                      <Text>{t("Overnight on demand")}</Text>
                    </Space>
                  </Checkbox>
                </Form.Item>
              </Card>
            </Space>
          </Form>

          {amount &&
            ((amount > 200 && tokenType === "USDT") ||
              (amount > 50 && tokenType === "TON")) && (
              <Alert
                message={
                  <Space>
                    <BulbTwoTone />
                    <Text>
                      {t("overnight.invest_massage")}{" "}
                      <Link
                        onClick={() =>
                          WebApp.openTelegramLink(
                            `https://t.me/webtransfercredit/203`
                          )
                        }
                      >
                        {t("Learn more...")}
                      </Link>
                    </Text>
                  </Space>
                }
                type="info"
                className={styles.infoAlert}
              />
            )}
        </Space>
      </Card>

      <Card
        title={
          <Space>
            <AreaChartOutlined className={styles.iconStyle} />
            <Text strong>{t("Volume Statistics")}</Text>
          </Space>
        }
        className={styles.overnightCard}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space size="large">
            <Statistic
              title={`${t("Totals")} USDT`}
              value={
                volumeData?.reduce((sum, item) => sum + item.usdt_volume, 0) ||
                0
              }
              precision={0}
              valueStyle={{ color: "#26A17B" }}
            />
            <Statistic
              title={`${t("Totals")} TON`}
              value={
                volumeData?.reduce((sum, item) => sum + item.ton_volume, 0) || 0
              }
              precision={0}
              valueStyle={{ color: "#0088CC" }}
            />
          </Space>
          <div style={{ marginTop: 16 }}>
            <VolumeChart />
          </div>
          <Radio.Group
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            optionType="button"
            buttonStyle="solid"
            style={{ marginTop: 16 }}
          >
            <Radio.Button value="7d">{t("7 days")}</Radio.Button>
            <Radio.Button value="30d">{t("30 days")}</Radio.Button>
          </Radio.Group>
          <span className={styles.demandInfo}>{t("overnight.demand_info")}</span>
        </Space>
      </Card>
      <Card
        className={styles.partnerLink}
        onClick={() => navigate("/loan-overnight/partners")}
      >
        <Space align="center">
          <UsergroupAddOutlined className={styles.iconLarge} />
          <Text type="secondary">{t("overnight.partner_massage")}</Text>
        </Space>
      </Card>
      <Card
        title={
          <Space>
            <InfoCircleOutlined className={styles.iconStyle} />
            <Text strong>{t("overnight.title")}</Text>
          </Space>
        }
        className={styles.overnightCard}
      >
        <Space direction="vertical" className={styles.textLeft}>
          <Paragraph>{t("overnight.description")}</Paragraph>
          <Divider orientation="left">{t("overnight.key_features")}</Divider>
          <List
            dataSource={features}
            renderItem={(item) => (
              <List.Item>
                <Space>
                  <RightCircleTwoTone />
                  <Text>{item}</Text>
                </Space>
              </List.Item>
            )}
          />
          <Paragraph type="secondary">{t("overnight.ps")}</Paragraph>
          <Link href="https://webtransfer.com/overnight" target="_blank">
            {t("Learn more...")}
          </Link>
        </Space>
      </Card>

      <MainButton
        text={t("to issue a loan")}
        onClick={handleSubmit}
        progress={loading}
      />
    </Space>
  );
};

export default LoanOvernight;
