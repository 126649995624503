import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Flex,
  List,
  message,
  Spin,
  Tag,
  Typography,
  Avatar,
  Alert,
  Empty,
  Space,
  Divider,
  Modal,
} from "antd";
import {
  StarOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserOutlined,
  CrownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { PotentialSeniorPartner } from "../../types";
import { fetchTopSeniorPartners, changeSeniorPartner } from "../../helpers/api";
import styles from "./Partners.module.css";

const { Title, Text } = Typography;

const TopSeniorPartners: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [partners, setPartners] = useState<PotentialSeniorPartner[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<PotentialSeniorPartner | null>(null);
  const [changingPartner, setChangingPartner] = useState<boolean>(false);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchTopSeniorPartners();
      if (response.success) {
        setPartners(response.partners);
      } else {
        setError(response.error || t("Failed to load top senior partners"));
      }
    } catch (error) {
      setError(t("Failed to load top senior partners"));
      message.error({
        content: t("Failed to load top senior partners"),
        style: { marginTop: "64px" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPartner = (partner: PotentialSeniorPartner) => {
    setSelectedPartner(partner);
    setConfirmModalVisible(true);
  };

  const handleConfirmChange = async () => {
    if (!selectedPartner) return;
    setChangingPartner(true);
    try {
      const response = await changeSeniorPartner(Number(selectedPartner.user_id));
      if (response.success) {
        message.success({
          content: t("Senior partner changed successfully"),
          style: { marginTop: "64px" },
        });
        setConfirmModalVisible(false);
        navigate("/partners");
      } else {
        message.error({
          content: response.error || t("Failed to change senior partner"),
          style: { marginTop: "64px" },
        });
      }
    } catch (error) {
      message.error({
        content: t("Failed to change senior partner"),
        style: { marginTop: "64px" },
      });
    } finally {
      setChangingPartner(false);
    }
  };

  const renderPartnerCard = (partner: PotentialSeniorPartner, index: number) => (
    <Card
      key={partner.user_id}
      className={styles.partnerCard}
    >
      <Flex vertical gap="small">
        <Flex align="center" gap="small">
          <div style={{ position: "relative" }}>
            <Avatar
              src={partner.avatar_url}
              icon={<UserOutlined />}
              size={40}
            />
            {index < 3 && (
              <div
                className={`${styles.topPartnerBadge} ${
                  index === 0
                    ? styles.goldBadge
                    : index === 1
                    ? styles.silverBadge
                    : styles.bronzeBadge
                }`}
              >
                {index + 1}
              </div>
            )}
          </div>
          <div className={styles.partnerInfo}>
            <Text strong>{`${partner.first_name} ${partner.last_name}`}</Text>
            {partner.premium_status && (
              <Tag color="gold" style={{ marginLeft: 8 }}>
                {t("Premium")}
              </Tag>
            )}

          </div>
        </Flex>

        <Divider style={{ margin: "8px 0" }} />

        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Flex align="center" gap="small">
            <StarOutlined style={{ color: "#007aff" }} />
            <Text>
              {t("Rating")}: <span className={styles.partnerStatValue}>{partner.rating}%</span>
            </Text>
          </Flex>

          <Flex align="center" gap="small">
            <TeamOutlined style={{ color: "#007aff" }} />
            <Text>
              {t("Referrals")}: <span className={styles.partnerStatValue}>{partner.referral_count}</span>
            </Text>
          </Flex>

          <Flex align="center" gap="small">
            <TrophyOutlined style={{ color: "#007aff" }} />
            <Text>
              {t("Active Loans")}: <span className={styles.partnerStatValue}>{partner.active_loans_count}</span>
            </Text>
          </Flex>
        </Space>

        <Divider style={{ margin: "8px 0" }} />

        {/* Кнопки */}
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Button
            type="primary"
            block
            onClick={() => navigate(`/partners/${partner.user_id}`)}
          >
            {t("View Profile")}
          </Button>
          <Button
            type="primary"
            block
            onClick={() => handleSelectPartner(partner)}
          >
            {t("Select as Senior Partner")}
          </Button>
        </Space>
      </Flex>
    </Card>
  );

  return (
    <div className={styles.container}>

      <Card className={styles.card} style={{ marginBottom: 16 }}>
        <Flex align="center" gap="middle" justify="center">
          <CrownOutlined style={{ color: "gold", fontSize: 24 }} />
          <Text strong>{t("Top 10 Senior Partners")}</Text>
        </Flex>
      </Card>

      {loading ? (
        <div className={styles.loaderContainer}>
          <Spin size="large" />
        </div>
      ) : error ? (
        <Alert message={error} type="error" showIcon />
      ) : partners.length === 0 ? (
        <Empty
          description={t("No senior partners found")}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <List
          dataSource={partners}
          renderItem={(partner, index) => renderPartnerCard(partner, index)}
        />
      )}

      <Modal
        title={
          <Flex align="center" gap="small">
            <ExclamationCircleOutlined style={{ color: "#faad14" }} />
            <Text strong>{t("Change Senior Partner")}</Text>
          </Flex>
        }
        open={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmModalVisible(false)}>
            {t("Cancel")}
          </Button>,
          <Button 
            key="confirm" 
            type="primary" 
            loading={changingPartner}
            onClick={handleConfirmChange}
          >
            {t("Confirm")}
          </Button>,
        ]}
      >
        <Flex vertical gap="middle">
          {selectedPartner && (
            <Flex align="center" gap="small">
              <Avatar
                src={selectedPartner.avatar_url}
                icon={<UserOutlined />}
                size={40}
              />
              <Text strong>{`${selectedPartner.first_name} ${selectedPartner.last_name}`}</Text>
            </Flex>
          )}
          <Alert
            message={t("Important Notice")}
            description={t("You can change your senior partner only once per year. Are you sure you want to select this partner as your senior partner?")}
            type="warning"
            showIcon
          />
        </Flex>
      </Modal>
    </div>
  );
};

export default TopSeniorPartners;