import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Statistic, message, Spin, Progress, Card } from "antd";
import { useTranslation } from "react-i18next";
import { RootState, AppDispatch } from "../../services/store";
import { updateWebTokens } from "../../services/slices/userSlice";
import { fetchTaps, getNextAvailableTapTime } from "../../helpers/api";
import {
  knockBank,
  receiveLoan,
  resetGame,
  setNextAvailableTapTime,
} from "../../services/actions";
import styles from './BankGame.module.css';

const { Title, Text } = Typography;
const { Countdown } = Statistic;

const BankGame: FC = () => {
  const knocks = useSelector((state: RootState) => state.game.knocks);
  const nextAvailableTapTime = useSelector(
    (state: RootState) => state.game.nextAvailableTapTime
  );
  const { userData } = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [showPoints, setShowPoints] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNextAvailableTapTime = async () => {
      setLoading(true);
      if (userData) {
        try {
          const result = await getNextAvailableTapTime(userData.id);
          if (result.success && result.nextAvailableTapTime) {
            const serverTime = new Date(result.nextAvailableTapTime).getTime();
            setCurrentTime(new Date(result.nowTime).getTime());
            dispatch(setNextAvailableTapTime(serverTime));
          } else {
            setCurrentTime(Date.now());
          }
        } catch (error) {
          setCurrentTime(Date.now());
          message.error(t("Farming.ErrorLoading"));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchNextAvailableTapTime();
  }, [userData, dispatch, t]);

  const handleKnockBank = async () => {
    if (knocks < 10) {
      dispatch(knockBank());
      setAnimationKey((prev) => prev + 1);
      setShowPoints(true);
      setTimeout(() => setShowPoints(false), 1000);
    }
    if (knocks === 9) {
      try {
        const result = await fetchTaps();
        if (result.success) {
          message.success(t("Farming.Congratulations"));
          dispatch(receiveLoan());
          dispatch(updateWebTokens(10000));
          const newNextAvailableTapTime = Date.now() + 15 * 60 * 1000;
          dispatch(setNextAvailableTapTime(newNextAvailableTapTime));
          setCurrentTime(Date.now());
        } else {
          message.error(result.message);
        }
      } catch (error) {
        message.error("An error occurred while processing your request.");
        console.error("Error:", error);
      }
    }
  };

  const onFinish = () => {
    dispatch(resetGame());
    dispatch(setNextAvailableTapTime(0));
    setCurrentTime(Date.now());
  };

  const getCountdownValue = () => {
    if (nextAvailableTapTime && currentTime) {
      const remainingTime = nextAvailableTapTime - currentTime;
      if (remainingTime > 0) {
        return nextAvailableTapTime;
      }
    }
    return undefined;
  };

  if (loading) {
    return (
      <div className={styles.gameContainer}>
        <div className="loader-container">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.gameContainer}>
      <Title level={3} className={styles.title}>
        {t("Farming.Title")}
      </Title>
      <Card className={styles.styledCard}>
        {getCountdownValue() !== undefined ? (
          <div className={styles.countdownContainer}>
            <Countdown
              title={t("Farming.NextTap")}
              value={getCountdownValue()}
              onFinish={onFinish}
            />
          </div>
        ) : (
          <div className={styles.tapContainer}>
            <div className={styles.progressContainer}>
              <Progress
                className={styles.styledProgress}
                percent={knocks * 10}
                format={() => `${knocks}/10`}
                status={knocks === 10 ? "success" : "active"}
              />
            </div>

            <img
              className={styles.bankImage}
              src="/assets/bank.svg"
              alt="Bank"
              width={250}
              onClick={handleKnockBank}
            />

            {showPoints && (
              <p className={styles.pointsAnimation} key={animationKey}>+1000</p>
            )}

            <Text strong className={styles.tapsText}>
              {t("Farming.TapsRemaining")}: {10 - knocks}
            </Text>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BankGame;
