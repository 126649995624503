import { configureStore, Middleware, isPlainObject } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import tonReducer from "./slices/tonSlice";
import usdtReducer from "./slices/usdtSlice";
import bankReducer from "./reducers";
import websocketReducer from "./slices/websocketSlice";
import webPriceReducer from "./slices/webPriceSlice";
import { websocketMiddleware } from "./middleware/websocketMiddleware";
import auctionReducer from "./slices/auctionSlice";
import appReducer from "./slices/appSlice";

const loggerMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  try {
    const result = next(action);
   // console.log("Middleware - NEW STATE: ", storeAPI.getState());
    return result;
  } catch (error) {
    console.error("Error in middleware:", error);
    throw error;
  }
};

const isSerializable = (value: any) => {
  return (
    typeof value === "string" || isPlainObject(value) || Array.isArray(value)
  );
};

const store = configureStore({
  reducer: {
    user: userReducer,
    ton: tonReducer,
    usdt: usdtReducer,
    game: bankReducer,
    websocket: websocketReducer,
    auction: auctionReducer,
    app: appReducer,
    webPrice: webPriceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable,
      },
    }).concat(loggerMiddleware, websocketMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
