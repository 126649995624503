import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Cell } from "@ton/ton";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { Typography, Spin, Alert, Tag, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { getStatusColor } from "../helpers/functions";
import LoanCertificate from "../components/loanCertificate";
import { MainButton } from "@twa-dev/sdk/react";
import LoanProgress from "../components/LoanProgress";
import { sendTransaction } from "../helpers/transactionService";
import { RootState } from "../services/store";
import {
  fetchCertificateDetails,
  getSellerWallet,
  confirmPayment,
} from "../helpers/api";
import { LoanDetails } from "../types";

const Certificate: FC = () => {
  const { t } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const { userData } = useSelector((state: RootState) => state.user);
  const { loanId } = useParams<{ loanId: string }>();
  const [loanDetails, setLoanDetails] = useState<LoanDetails | null>(null);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");
  const [rate, setRate] = useState<number | null>(null);
  const [isChecking, setIsChecking] = useState<boolean>(false);


  const loadLoanDetails = async () => {
    try {
      setLoading(true);
      const loanIdNumber = Number(loanId);
      if (isNaN(loanIdNumber)) {
        throw new Error(t("Invalid loan ID"));
      }
      const data = await fetchCertificateDetails(loanIdNumber);
      setLoanDetails(data);
      setRate(data.rate);
      setStatus(data.status);
    } catch (err) {
      setError(t("Failed to fetch loan details"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLoanDetails();
  }, []);

  // Покупка сертификата
  const handleBuy = async () => {
    if (!loanDetails) return;
    if (!userFriendlyAddress) {
      message.error(t("Please connect your wallet"));
      return;
    }

    const sender_address =
      loanDetails.currency === "USDT" ? wallet_address : userFriendlyAddress;

    try {
      const { wallet } = await getSellerWallet(loanDetails.loan_id);
      if (!wallet) throw new Error("Не удалось получить кошелёк продавца");

      const myTransaction = await sendTransaction(
        "Sale of Webtransfer Credit Certificate",
        loanDetails.currency || "TON",
        sender_address,
        wallet,
        loanDetails.price,
        false
      );

      if (!myTransaction) {
        throw new Error("Ошибка при создании транзакции");
      }
      const result = await tonConnectUI.sendTransaction(myTransaction);

      if (!result || !result.boc) {
        message.error(t("Error when sending a transaction"));
      }

      setIsChecking(true);

      const hash = Cell.fromBase64(result.boc).hash().toString("base64");

      await confirmPayment(loanDetails.loan_id, userFriendlyAddress, hash);
      setIsChecking(false);
      setStatus("waiting_confirmation");
      message.success(t("Payment sent! Waiting for seller confirmation..."));

    } catch (error) {
      message.error("Error buying certificate");
      console.error("Error buying certificate:", error);
      setIsChecking(false);
    }
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (!loanDetails || !userData) {
    return <Alert message={t("Loan not found")} type="warning" />;
  }

  return (
    <div>
      <Typography.Title level={4}>
        {t("Loan Details")} №{loanId}
      </Typography.Title>
      <LoanProgress
        created_at={loanDetails.created_at}
        expires_at={loanDetails.expires_at}
        status={status}
      />
      {loanDetails.blocked === 1 && (
        <Alert
          message={t("certificate_blocked")}
          type="warning"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <div className="balance_item">
        <span>{t("Status")}</span>
        <span className="balance_item_price">
          {
            <Tag color={getStatusColor(status)}>
              {t(`loan_status.${status}`)}
            </Tag>
          }
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Amount")}</span>
        <span className="balance_item_price">{loanDetails.amount}</span>
      </div>
      {loanDetails.margin > 0 && (
        <div className="balance_item">
          <span>{t("The amount without a margin loan")}</span>
          <span className="balance_item_price">{loanDetails.fix_amount}</span>
        </div>
      )}
      {loanDetails.currency !== "" && (
        <div className="balance_item">
          <span>{t("Currency")}</span>
          <span className="balance_item_price">{loanDetails.currency}</span>
        </div>
      )}
      <div className="balance_item">
        <span>{t("Term")}</span>
        <span className="balance_item_price">
          {loanDetails.term} {t("days")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Rate")}</span>
        <span className="balance_item_price">{rate}%</span>
      </div>
      <div className="balance_item">
        <span>{t("Insurance")}</span>
        <span className="balance_item_price">
          {loanDetails.insurance ? t("Yes") : t("No")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Profit")}</span>
        <span className="balance_item_price">{loanDetails.profit}</span>
      </div>
      <div className="balance_item">
        <span>{t("Created")}</span>
        <span className="balance_item_price">
          {moment(loanDetails.created_at)
            .locale(userData.language_code || "en")
            .format("D MMM YYYY")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Expires")}</span>
        <span className="balance_item_price">
          {moment(loanDetails.expires_at)
            .locale(userData.language_code || "en")
            .format("D MMM YYYY")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("To be refunded")}</span>
        <span className="balance_item_price">
          {loanDetails.total_repayment}
        </span>
      </div>
      {loanDetails.margin > 0 && (
        <Alert
          message={`${t("A margin loan has been used")} ${loanDetails.margin}%`}
          type="success"
          showIcon
        />
      )}
      <LoanCertificate
        showName={false}
        loanNumber={loanDetails.loan_id.toString()}
        loanAmount={loanDetails.amount}
        loanEndDate={loanDetails.expires_at.substring(0, 10)}
        loanDate={loanDetails.created_at.substring(0, 10)}
      />
      {status === "for_sale" && (
        <MainButton
          text={`${t("Buy for")} ${loanDetails.price} ${loanDetails.currency}`}
          onClick={handleBuy}
          progress={loading}
        />
      )}

      <Modal open={isChecking} footer={null} closable={false} centered>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <p>{t("Waiting for transaction confirmation...")}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Certificate;
