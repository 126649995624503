import { FC, useState, useEffect } from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import { useTranslation } from "react-i18next";
import { MainButton } from "@twa-dev/sdk/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { sendTransactionNew } from "../../helpers/transactionService";
import { useSelector, useDispatch } from "react-redux";
import { updateBonusBalance } from "../../services/slices/userSlice";
import { RootState, AppDispatch } from "../../services/store";
import { getPaymentWallet } from "../../helpers/api";
import { splitAmount } from "../../helpers/functions";
import {
  InputNumber,
  Form,
  Switch,
  Typography,
  message,
  Divider,
  Checkbox,
  Select,
  Radio,
  Alert,
  Card,
  Tooltip,
} from "antd";
import {
  BulbTwoTone,
  InfoCircleOutlined,
  SafetyOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import styles from "./loanIssuance.module.css";

const { Text, Link } = Typography;
const { Option } = Select;

const LoanIssuance: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const tonPrice = useSelector((state: RootState) => state.ton.tonPrice);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const { currentPrice } = useSelector((state: RootState) => state.webPrice);
  const [useBonus, setUseBonus] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [rate, setRate] = useState<number | null>(null);
  const [term, setTerm] = useState<number | null>(null);
  const [insurance, setInsurance] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [marginLoan, setMarginLoan] = useState<number>(0);
  const [tokenType, setTokenType] = useState("USDT");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [useWebTokenPromotion, setUseWebTokenPromotion] =
    useState<boolean>(false);
  const [webTokensToExchange, setWebTokensToExchange] = useState<number>(0);

  const [errors, setErrors] = useState<{
    amount?: string;
    rate?: string;
    term?: string;
  }>({});
  const [messageText, setMessageText] = useState<React.ReactNode>("");
  const popularAmounts = [50, 100, 500, 1000];
  const popularTerms = [15, 30, 60, 90];

  useEffect(() => {
    if (location.state?.useBonus) {
      setUseBonus(true);
    }
    if (location.state?.tokenType) {
      setTokenType("TON");
    }
  }, [location.state]);

  useEffect(() => {
    if (useBonus && userData) {
      setTerm(14);
      setAmount(userData.bonus_balance);
      setRate(0.21);
    }
    if (useWebTokenPromotion && userData) {
      setRate(userData.settings.web_token_promotion_rate);
    }
    if (amount && rate && term) {
      const margin = Math.min(amount * (marginLoan / 100), 3000);
      const profit = amount * (rate / 100) * term;
      const marginProfit = margin * (rate / 100) * term;
      const totalProfit = profit + marginProfit;
      const insuranceAmount = insurance
        ? totalProfit * 0.25
        : totalProfit * 0.05;
      const marginPercent =
        margin * (Number(userData?.settings.margin_rate) / 100) * term;
      const total_profit = totalProfit - insuranceAmount - marginPercent;

      let bonuses: [number, number, [number, number, number][]][] =
        tokenType === "TON" && insurance
          ? [
              [
                30,
                90,
                [
                  [1, 10, 0.5],
                  [10, 20, 0.75],
                  [20, 40, 1],
                  [40, 60, 1.25],
                  [60, 80, 1.5],
                  [80, 100, 1.75],
                  [100, 500, 2],
                  [500, Infinity, 2.5],
                ],
              ],
              [
                90,
                180,
                [
                  [1, 10, 1],
                  [10, 20, 1.25],
                  [20, 40, 1.5],
                  [40, 60, 1.75],
                  [60, 80, 2],
                  [80, 100, 2.5],
                  [100, 500, 3],
                  [500, Infinity, 3.5],
                ],
              ],
              [
                180,
                365,
                [
                  [1, 10, 1.5],
                  [10, 20, 2],
                  [20, 40, 2.5],
                  [40, 60, 3],
                  [60, 80, 3.5],
                  [80, 100, 4],
                  [100, 500, 4.5],
                  [500, Infinity, 5],
                ],
              ],
              [
                365,
                Infinity,
                [
                  [1, 10, 3],
                  [10, 20, 4],
                  [20, 40, 5],
                  [40, 60, 6],
                  [60, 80, 7],
                  [80, 100, 8],
                  [100, 500, 9],
                  [500, Infinity, 10],
                ],
              ],
            ]
          : [
              [
                30,
                90,
                [
                  [1, 50, 0.5],
                  [50, 100, 0.75],
                  [100, 200, 1],
                  [200, 300, 1.25],
                  [300, 400, 1.5],
                  [400, 500, 1.75],
                  [500, 2500, 2],
                  [2500, Infinity, 2.5],
                ],
              ],
              [
                90,
                180,
                [
                  [1, 50, 1],
                  [50, 100, 1.25],
                  [100, 200, 1.5],
                  [200, 300, 1.75],
                  [300, 400, 2],
                  [400, 500, 2.5],
                  [500, 2500, 3],
                  [2500, Infinity, 3.5],
                ],
              ],
              [
                180,
                365,
                [
                  [1, 50, 1.5],
                  [50, 100, 2],
                  [100, 200, 2.5],
                  [200, 300, 3],
                  [300, 400, 3.5],
                  [400, 500, 4],
                  [500, 2500, 4.5],
                  [2500, Infinity, 5],
                ],
              ],
              [
                365,
                Infinity,
                [
                  [1, 50, 3],
                  [50, 100, 4],
                  [100, 200, 5],
                  [200, 300, 6],
                  [300, 400, 7],
                  [400, 500, 8],
                  [500, 2500, 9],
                  [2500, Infinity, 10],
                ],
              ],
            ];

      let bonus: number | undefined;

      for (let [minTerm, maxTerm, amountRanges] of bonuses) {
        if (term >= minTerm && term < maxTerm) {
          for (let [minAmount, maxAmount, factor] of amountRanges) {
            if (amount >= minAmount && amount < maxAmount) {
              bonus = amount * factor;
              break;
            }
          }
          break;
        }
      }

      if (tokenType === "TON" && bonus && tonPrice) {
        bonus *= tonPrice;
      }

      setMessageText(
        <>
          {t("Your profit will be")}
          <br />
          <strong>
            {useBonus ? "$" : ""}
            {total_profit.toFixed(2)} {useBonus ? "" : tokenType}
          </strong>
          {!useBonus && term >= 30 && insurance && bonus && (
            <span className={styles.profitBonus}>
              + ${bonus.toFixed(2)} {t("Bonus")}
            </span>
          )}
          {useWebTokenPromotion && amount && tonPrice && (
            <span className={styles.profitBonus}>
              + ${(tokenType === "USDT" ? amount * 0.05 : amount * 0.05 * tonPrice).toFixed(2)} {t("WEB Exchange")}
            </span>
          )}
        </>
      );

      if (amount && currentPrice && useWebTokenPromotion && tonPrice) {
        const dollarAmount = tokenType === "USDT" ? amount * 0.05 : amount * 0.05 * tonPrice;
        const tokensNeeded = dollarAmount / currentPrice;
        setWebTokensToExchange(tokensNeeded);
      } else {
        setWebTokensToExchange(0);
      }
    }
  }, [
    amount,
    rate,
    term,
    insurance,
    t,
    useBonus,
    tokenType,
    marginLoan,
    userData,
    tonPrice,
    useWebTokenPromotion,
    currentPrice,
  ]);

  useEffect(() => {
    if (term !== null && term < 30 && useWebTokenPromotion) {
      setUseWebTokenPromotion(false);
      message.info(t("Web token promotion requires a minimum term of 30 days"));
    }
  }, [term, useWebTokenPromotion, t]);

  const selectBefore = (
    <Select
      value={tokenType}
      onChange={(value) => setTokenType(value)}
      style={{ width: 100 }}
    >
      <Option value="USDT">
        <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={12} /> USDT
      </Option>
      <Option value="TON">
        <img src="./assets/tonIcon.png" alt="TON Logo" width={12} /> TON
      </Option>
    </Select>
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setDisabled(true);
    let validationErrors: { amount?: string; rate?: string; term?: string } =
      {};

    // Validate amount
    if (amount === null || amount <= 0) {
      validationErrors.amount = t("Please enter a valid amount");
    }

    // Validate rate
    if (rate === null || rate <= 0) {
      validationErrors.rate = t("Please enter a valid rate");
    }

    // Validate term only if not using bonus
    if (!useBonus) {
      if (term === null || term < 3) {
        validationErrors.term = t("Please enter a valid term");
      }

      // Проверка для акции с вебтокенами
      if (useWebTokenPromotion && term !== null && term < 30) {
        validationErrors.term = t(
          "Web token promotion requires a minimum term of 30 days"
        );
      }
    }

    // Проверка наличия достаточного количества вебтокенов
    if (useWebTokenPromotion && userData && webTokensToExchange > 0) {
      if (!userData.web_tokens || userData.web_tokens < webTokensToExchange) {
        validationErrors.amount = t(
          "You don't have enough WEB tokens for this promotion"
        );
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setDisabled(false);
      setIsSubmitting(false);
      return;
    }

    const loanData = {
      amount,
      rate,
      term: useBonus ? 14 : term,
      useBonus,
      insurance,
    };

    if (useBonus) {
      try {
        axios
          .post("/api/issue-loan.php", loanData)
          .then((response) => {
            if (response.data.error) {
              message.error(t(response.data.error));
            } else {
              dispatch(updateBonusBalance(-amount!));
              message.success(t("Loan issued successfully"));
              setDisabled(false);
              setAmount(null);
              setRate(null);
              setTerm(null);
              setInsurance(false);
              setErrors({});
              navigate("/");
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              message.error(t(error.response.data.error));
            } else {
              message.error("Error issuing loan");
            }
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } catch (e) {
        console.error("Error sending transaction:", e);
        setIsSubmitting(false);
      }
    } else {
      if (userData && userFriendlyAddress) {
        if (!amount || amount <= 0) {
          message.error(t("Please enter valid amount"));
          setIsSubmitting(false);
          setDisabled(false);
          return;
        }

        let comment = `UserId:${
          userData!.id
        };Rate:${rate};Term:${term};Insurance:${insurance}`;
        if (tokenType === "USDT") {
          comment += `;Margin:${marginLoan}`;
        }

        // Добавляем информацию об акции в комментарий
        if (useWebTokenPromotion) {
          comment += `;WebPromo:${true}`;
        }

        const maxPerTx = tokenType === "USDT" ? 200 : 50;
        const minPerTx = tokenType === "USDT" ? 10 : 1;

        try {
          const amounts = splitAmount(amount, maxPerTx, minPerTx);

          const recipientAddresses = [];
          for (let i = 0; i < amounts.length; i++) {
            const walletResponse = await getPaymentWallet(userFriendlyAddress);
            if (walletResponse && walletResponse.walletAddress) {
              recipientAddresses.push(walletResponse.walletAddress);
            } else {
              console.error("Failed to fetch payment wallet.");
              setIsSubmitting(false);
              setDisabled(false);
              return;
            }
          }

          const myTransaction = await sendTransactionNew(
            comment,
            tokenType,
            wallet_address,
            recipientAddresses,
            amounts
          );

          if (myTransaction) {
            await tonConnectUI.sendTransaction(myTransaction);
            navigate("/");
          } else {
            message.error(t("Transaction could not be created"));
          }
        } catch (error) {
          console.error("Error in transaction:", error);
          message.error(t("Error processing transaction"));
        } finally {
          setIsSubmitting(false);
          setDisabled(false);
        }
      }
    }
  };

  if (!userData) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <Form layout="vertical">
        <div className={styles.bonusSwitch}>
          <Switch
            checked={useBonus}
            onChange={() => {
              setUseBonus(!useBonus);
              setInsurance(true);
              setAmount(null);
              setRate(null);
              setTerm(null);
              setMessageText(null);
            }}
          />

          <div className={styles.bonusLabel}>{t("give out bonuses")}</div>
          <Tooltip
            title={t(
              "Bonuses can only be invested for 14 days with a fixed rate of 0.21% per day"
            )}
          >
            <QuestionCircleOutlined
              style={{ marginLeft: 8, color: "#1890ff" }}
            />
          </Tooltip>
        </div>

        {!useBonus && (
          <div className={styles.bonusSwitch} style={{ marginTop: "10px" }}>
            <Switch
              checked={useWebTokenPromotion}
              onChange={(checked) => {
                if (term !== null && term < 30 && checked) {
                  message.info(
                    t("Web token promotion requires a minimum term of 30 days")
                  );
                  return;
                }
                setUseWebTokenPromotion(checked);
              }}
              disabled={useBonus || (term !== null && term < 30)}
            />

            <div className={styles.bonusLabel}>{t("WEB Exchange")}</div>
            <Tooltip
              title={t(
                "Exchange your WEB tokens for 5% on your loan amount. You'll receive this bonus at the end of the loan term."
              )}
            >
              <SwapOutlined style={{ marginLeft: 8, color: "#1890ff" }} />
            </Tooltip>
          </div>
        )}

        {messageText && <div className={styles.messageText}>{messageText}</div>}

        <Form.Item
          label={<span>{t("Amount")}</span>}
          extra={
            !useBonus && `Min. ${tokenType === "USDT" ? "10 USDT" : "1 TON"}`
          }
          validateStatus={errors.amount ? "error" : ""}
          help={errors.amount}
          className={styles.formItem}
        >
          <InputNumber
            placeholder={t("Enter amount")}
            addonBefore={useBonus ? "$" : selectBefore}
            value={useBonus ? userData!.bonus_balance : amount}
            onChange={(value) => setAmount(value)}
            className={styles.inputNumber}
            disabled={useBonus}
            min={useBonus ? 1 : tokenType === "USDT" ? 10 : 1}
            formatter={(value) => {
              if (value === undefined) return "";
              return `${value}`.replace(",", ".");
            }}
            parser={(value) => {
              if (value === undefined) return 0;
              return parseFloat(value.replace(",", "."));
            }}
          />
          {!useBonus && (
            <div className={styles.popularRates}>
              {t("Popular")}:
              {popularAmounts.map((amount) => (
                <Link
                  key={amount}
                  onClick={() => setAmount(amount)}
                  className={styles.popularRateLink}
                >
                  {amount}
                </Link>
              ))}
            </div>
          )}
        </Form.Item>

        {amount &&
          ((amount > 200 && tokenType === "USDT") ||
            (amount > 50 && tokenType === "TON")) &&
          !useBonus && (
            <Alert
              message={
                <>
                  {t("overnight.invest_massage")}{" "}
                  <span
                    className={styles.alertLink}
                    onClick={() =>
                      WebApp.openTelegramLink(
                        `https://t.me/webtransfercredit/203`
                      )
                    }
                  >
                    {t("Learn more...")}
                  </span>
                </>
              }
              type="info"
              action={<BulbTwoTone style={{ fontSize: "16px" }} />}
              className={styles.alertInfo}
            />
          )}

        <Form.Item
          label={t("Rate")}
          validateStatus={errors.rate ? "error" : ""}
          help={errors.rate}
          className={styles.formItem}
        >
          <InputNumber
            placeholder="0.25"
            value={
              useWebTokenPromotion
                ? userData.settings.web_token_promotion_rate
                : useBonus
                ? 0.21
                : rate
            }
            onChange={(value) => setRate(value)}
            className={styles.inputNumber}
            decimalSeparator="."
            disabled={useBonus || useWebTokenPromotion}
            formatter={(value) => {
              if (value === undefined) return "";
              return `${value}`.replace(",", ".");
            }}
            parser={(value) => {
              if (value === undefined) return 0;
              return parseFloat(value.replace(",", "."));
            }}
          />

          {!useBonus && !useWebTokenPromotion && (
            <div className={styles.popularRates}>
              {t("Popular")}:
              {insurance ? (
                userData?.popular_rates.map((rate) => (
                  <Link
                    key={rate}
                    onClick={() => setRate(rate)}
                    className={styles.popularRateLink}
                  >
                    {rate}%
                  </Link>
                ))
              ) : (
                <Link
                  onClick={() =>
                    setRate(Number(userData.settings.max_rate_high_risk))
                  }
                  className={styles.popularRateLink}
                >
                  {userData.settings.max_rate_high_risk}%
                </Link>
              )}
            </div>
          )}
        </Form.Item>

        {!useBonus && (
          <Form.Item
            label={t("Term")}
            validateStatus={errors.term ? "error" : ""}
            help={errors.term}
            className={styles.formItem}
          >
            <InputNumber
              placeholder={t("Enter term in days")}
              value={term}
              onChange={(value) => setTerm(value)}
              className={styles.inputNumber}
              min={useWebTokenPromotion ? 30 : insurance ? 3 : 15}
              step={1}
              formatter={(value) => `${value}`}
              parser={(value) => parseInt(value!, 10)}
            />

            <div className={styles.popularRates}>
              {t("Popular")}:
              {popularTerms.map((term) => (
                <Link
                  key={term}
                  onClick={() => setTerm(term)}
                  className={styles.popularRateLink}
                >
                  {term}
                </Link>
              ))}
            </div>

            <Text type="secondary">
              {useWebTokenPromotion
                ? `Min. 30 days`
                : insurance
                ? `Min. 3 day`
                : `Min. 15 day`}
            </Text>
          </Form.Item>
        )}

        {tokenType === "USDT" && !useBonus && (
          <div className={styles.marginLoansSection}>
            <Text className={styles.marginTitle}>
              <SafetyOutlined style={{ marginRight: 8 }} />
              {t("Margin Loans")} ({userData?.settings.margin_rate}%)
            </Text>

            <Radio.Group
              defaultValue="0"
              disabled={!insurance}
              buttonStyle="solid"
              className={styles.marginRadioGroup}
              onChange={(e) => setMarginLoan(e.target.value)}
              value={marginLoan}
            >
              <Radio.Button value={0}>0%</Radio.Button>
              <Radio.Button value={100}>100%</Radio.Button>
              <Radio.Button value={200}>200%</Radio.Button>
              <Radio.Button value={300}>300%</Radio.Button>
            </Radio.Group>

            <Link
              href="https://webtransfer.com/margin-loans"
              target="_blank"
              className={styles.marginLearnMore}
            >
              {t("Learn more...")}
            </Link>
          </div>
        )}

        {useWebTokenPromotion && amount && term && tonPrice && (
          <Alert
            message={
              <>
                {t("You will exchange")}
                <br />
                <strong>{webTokensToExchange.toFixed(4)} WEB</strong>
                <br /> {t("tokens for")} <strong> ${(tokenType === "USDT" ? amount * 0.05 : amount * 0.05 * tonPrice).toFixed(2)}</strong>
                <br /> {t("at the end of the loan term")}
              </>
            }
            type="info"
            action={
              <SwapOutlined style={{ fontSize: "16px", color: "#1890ff" }} />
            }
            className={styles.alertInfo}
          />
        )}

        <Form.Item className={styles.insuranceCheckbox}>
          <Checkbox
            checked={insurance}
            disabled={useBonus}
            onChange={() => {
              setInsurance(!insurance);
              setMarginLoan(0);
            }}
          >
            <span>
              {t("To insure a loan")}{" "}
              <Tooltip title={t("Insurance protects your investment")}>
                <InfoCircleOutlined style={{ color: "#1890ff" }} />
              </Tooltip>
            </span>
          </Checkbox>
          <Link href="https://webtransfer.com/wt_insurance" target="_blank">
            {t("Learn more...")}
          </Link>
        </Form.Item>

        <Link
          href="https://webtransfer.com/risk-warning"
          target="_blank"
          className={styles.riskLink}
        >
          {t("Risk disclosure")}
        </Link>

        <Divider className={styles.divider} />

        <MainButton
          text={isSubmitting ? t("Processing...") : t("to issue a loan")}
          onClick={handleSubmit}
          disabled={disabled || isSubmitting}
        />
      </Form>
    </Card>
  );
};

export default LoanIssuance;
