import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, List, Row, Tag, Tabs, Divider, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { MessageOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Post from "./Post/Post";
import Rating from "./Rating";
import { OtherUserActionsProps, PostType } from "../types";
import { fetchUserPosts } from "../helpers/api";

const OtherUserActions: FC<OtherUserActionsProps> = ({
  partner,
  isSubscribed,
  children,
  handleSubscribe,
  activeTabKey,
  onTabChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<PostType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { userData } = useSelector((state: RootState) => state.user);
  const [offset, setOffset] = useState<number>(0);
  const limit = 10;

  const loadPosts = async () => {
    try {
      const data = await fetchUserPosts(partner.user_id, offset, limit);
      const newPosts = data.posts;
      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setHasMore(newPosts.length === limit);
      setOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  return (
    <>
      <Row gutter={16} style={{marginTop: 10}}>
        <Col span={12}>
          {userData?.id !== Number(partner.user_id) && (
            <Button
              type="primary"
              icon={<MessageOutlined />}
              onClick={() => navigate(`/chat/${partner.user_id}`)}
            >
              {t("Send message")}
            </Button>
          )}
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={handleSubscribe}>
            {isSubscribed ? t("Unsubscribe") : t("Subscribe")}
          </Button>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="feed"
        type="card"
        onChange={onTabChange}
        activeKey={activeTabKey}
        style={{ marginTop: 20 }}
        items={[
          {
            label: t("Posts"),
            key: "feed",
            children: (
              <InfiniteScroll
                dataLength={posts.length}
                next={loadPosts}
                hasMore={hasMore}
                loader={<Spin size="large" />}
                endMessage={<Divider />}
              >
                <List
                  dataSource={posts}
                  renderItem={(post) => (
                    <Post
                      key={post.id}
                      first_name={post.first_name}
                      last_name={post.last_name}
                      avatarUrl={post.avatar_url}
                      content={post.content}
                      imageUrl={post.image_url}
                      likes={post.likes}
                      comments_count={post.comments_count}
                      postId={post.id}
                      postTime={post.created_at}
                      user_id={post.user_id}
                      userId={userData!.id}
                      poll={post.poll}
                      liked_by_user={post.liked_by_user}
                      view_count={post.view_count}
                    />
                  )}
                />
              </InfiniteScroll>
            ),
          },
          {
            label: t("Partners"),
            key: "partners",
            children: (
              <List
                dataSource={children}
                renderItem={(child) => (
                  <List.Item key={child.user_id}>
                    <List.Item.Meta
                      title={
                        <div className="history-item">
                          <Link to={`/partners/${child.user_id}`}>
                            {`${child.first_name} ${child.last_name}`}
                          </Link>
                        </div>
                      }
                    />
                    {child.active ? (
                      <Tag color="green">{t("active")}</Tag>
                    ) : (
                      <Tag color="orange">{t("not active")}</Tag>
                    )}
                  </List.Item>
                )}
              />
            ),
          },
          {
            label: t("Score"),
            key: "score",
            children: <Rating rating={partner.rating} />,
          },
        ]}
      />
    </>
  );
};

export default OtherUserActions;
