import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponse, WebAppUser, ProjectSettings } from "../../types";

export interface UserState {
  userData: UserResponse | null;
  isLoading: boolean;
  startParam: string | null;
}

const initialState: UserState = {
  userData: null,
  isLoading: true,
  startParam: null,
};

const defaultSettings: ProjectSettings = {
  pay_wallet: "",
  service_mode: "",
  banner_enabled: "",
  banner_message: "",
  margin_rate: "",
  max_rate_overnight: "",
  max_rate_high_risk: "",
  max_rate_overnight_high_risk: "",
  web_token_promotion_rate: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserResponse>) => {
      state.userData = { ...action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateWebTokens: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.web_tokens += action.payload;
      }
    },
    updateBonusBalance: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.bonus_balance = Number(
          (state.userData.bonus_balance + action.payload).toFixed(2)
        );
      }
    },
    updatePartnerBalance: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.partner_balance = Number(
          (state.userData.partner_balance - action.payload).toFixed(2)
        );
      }
    },
    setStartParam: (state, action: PayloadAction<string | null>) => {
      state.startParam = action.payload;
    },
    updateUnreadNotifications: (state, action: PayloadAction<number>) => {
      if (state.userData) {
        state.userData.unread_notifications = action.payload;
      }
    },
    updateUserData: (state, action: PayloadAction<Partial<UserResponse>>) => {
      if (state.userData) {
        state.userData = { ...state.userData, ...action.payload };
      }
    },
  },
});

export const initializeUserData = (user: WebAppUser): UserResponse => ({
  ...user,
  real_balance: 0,
  bonus_balance: 0,
  partner_balance: 0,
  token: "",
  avatar_url: "",
  web_tokens: 0,
  settings: defaultSettings,
  popular_rates: [],
  status: 0,
  premium_status: 0,
  unread_notifications: 0,
});

export const {
  setUserData,
  setLoading,
  updateWebTokens,
  updateBonusBalance,
  updatePartnerBalance,
  setStartParam,
  updateUnreadNotifications,
  updateUserData,
} = userSlice.actions;

export default userSlice.reducer;
