import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtherUserActions from "./OtherUserActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Card, Row, Col, Tag, Typography, Avatar, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import Feed from "./Feed";
import { Partner } from "../types";
import { updateBonusBalance } from "../services/slices/userSlice";
import { RootState, AppDispatch } from "../services/store";
import { formatViewCount } from "../helpers/functions";
import { UserOutlined } from "@ant-design/icons";
import ChatList from "../pages/Chat/ChatList";
import { fetchPartnerDetails, subscribeToPartner } from "../helpers/api";

const { Link } = Typography;
const { TabPane } = Tabs;

const PartnerDetails: FC = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const [partner, setPartner] = useState<Partner | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [premium, setPremium] = useState<boolean>(false);
  const [children, setChildren] = useState<Partner[]>([]);
  const [totalPartners, setTotalPartners] = useState(0);
  const [totalActiveLoans, setTotalActiveLoans] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState<string>(() => {
    if (userData?.id === Number(userId)) {
      return localStorage.getItem('partnerDetailsTab') || "feed";
    }
    return "feed";
  });

  useEffect(() => {
    const loadPartnerDetails = async () => {
      setLoading(true);
      try {
        const data = await fetchPartnerDetails(userId!);
        setPartner(data.partner);
        setChildren(data.children);
        setTotalPartners(data.total_partners);
        setTotalActiveLoans(data.total_active_loans);
        setIsSubscribed(data.is_subscribed);
        setFollowerCount(data.follower_count);
        setPremium(data.premium_status);
      } catch (error) {
        console.error("Error fetching partner details:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPartnerDetails();
  }, [userId]);

  const handleSubscribe = async () => {
    const action = isSubscribed ? "unsubscribe" : "subscribe";
    try {
      const response = await subscribeToPartner(userId!, action);
      if (action === "subscribe") {
        dispatch(updateBonusBalance(response.bonus_added));
      }
      setIsSubscribed(!isSubscribed);
      setFollowerCount((prev) => prev + (isSubscribed ? -1 : 1));
    } catch (error) {
      console.error(`Error trying to ${action}:`, error);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    if (userData?.id === Number(userId)) {
      localStorage.setItem('partnerDetailsTab', key);
    }
  };

  return (
    <div className="partner-details">
      {loading ? (
        <Spin />
      ) : partner ? (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Card>
                <Card.Meta
                  avatar={
                    <Avatar
                      size={64}
                      src={partner.avatar_url}
                      icon={!partner.avatar_url && <UserOutlined />}
                    />
                  }
                  title={
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 5 }}>
                      {`${partner.first_name} ${partner.last_name}`}
                      {premium && (
                        <Tag color="#ffd100">
                          {t("Premium")}
                        </Tag>
                      )}
                    </div>
                  }
                  description={
                    <div className="profile-item">
                      <div style={{ marginBottom: "8px" }}>
                        <span style={{ marginRight: "16px" }}>
                          {t("Followers")}: {formatViewCount(followerCount)}
                        </span><br />
                        <span>
                          {t("Total Partners")}:{" "}
                          {formatViewCount(totalPartners)}
                        </span>
                      </div>
                      {userData?.id !== Number(userId) && (
                        <div>
                          {t("Total Active Loans")}:{" "}
                          {totalActiveLoans !== null ? (
                            `$${totalActiveLoans.toFixed(2)}`
                          ) : (
                            <Link onClick={() => navigate("/premium/")}>
                              {t("Get premium status")}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  }
                />
              </Card>
            </Col>
          </Row>

          {userData?.id !== Number(userId) ? (
            <OtherUserActions
              partner={partner}
              isSubscribed={isSubscribed}
              children={children}
              handleSubscribe={handleSubscribe}
              activeTabKey={activeTabKey}
              onTabChange={handleTabChange}
            />
          ) : (
            <Tabs
              activeKey={activeTabKey}
              onChange={handleTabChange} 
              style={{marginTop: 10}}
            >
              <TabPane tab={t("Feed")} key="feed">
                <Feed userId={userData!.id} />
              </TabPane>
              <TabPane
                tab={<span>{t("Messages")}</span>} 
                key="messages"
              >
                <ChatList />
              </TabPane>
            </Tabs>
          )}
        </>
      ) : (
        <p>{t("Partner not found")}</p>
      )}
    </div>
  );
};

export default PartnerDetails;
