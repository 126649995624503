import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { List, Spin, message, Tabs, Avatar, Divider, Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../services/store";
import Post from "../../components/Post/Post";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { updateBonusBalance } from "../../services/slices/userSlice";
import { PostType, UserType } from "../../types";
import { formatViewCount } from "../../helpers/functions";

const { TabPane } = Tabs;

const FollowingFeed: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const [posts, setPosts] = useState<PostType[]>([]);
  const [recommendedPosts, setRecommendedPosts] = useState<PostType[]>([]);
  const [people, setPeople] = useState<UserType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreRecommended, setHasMoreRecommended] = useState(true);
  const [hasMorePeople, setHasMorePeople] = useState(true);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [offsetRecommended, setOffsetRecommended] = useState(0);
  const [offsetPeople, setOffsetPeople] = useState(0);
  const [activeTab, setActiveTab] = useState("");


  useEffect(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    if (storedTab) {
      setActiveTab(storedTab);
    } else {
      setActiveTab("following");
    }
  }, []);

  // Функция для загрузки постов
  const fetchFollowingPosts = async () => {
    if (
      loading ||
      (posts.length > 0 &&
        offset === Number(sessionStorage.getItem("feedOffset")))
    )
      return;
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/account/get-following-posts.php",
        { offset }
      );
      const newPosts = response.data.posts;

      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setHasMore(newPosts.length === 10);
      setOffset((prevOffset) => prevOffset + 10);
    } catch (error) {
      message.error("Failed to load posts.");
      console.error("Error loading posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecommendedPosts = async () => {
    if (
      loading ||
      (recommendedPosts.length > 0 &&
        offsetRecommended ===
          Number(sessionStorage.getItem("feedOffsetRecommended")))
    )
      return;
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/account/get-recommended-posts.php",
        { offset: offsetRecommended }
      );
      const newPosts = response.data.posts;

      setRecommendedPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setHasMoreRecommended(newPosts.length === 10);
      setOffsetRecommended((prevOffset) => prevOffset + 10);
    } catch (error) {
      message.error("Failed to load recommended posts.");
      console.error("Error loading recommended posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPopularPeople = async () => {
    if (
      loading ||
      (people.length > 0 &&
        offsetPeople === Number(sessionStorage.getItem("feedOffsetPeople")))
    )
      return;
    setLoading(true);
    try {
      const response = await axios.post("/api/account/get-popular-people.php", {
        offset: offsetPeople,
      });
      const newPeople = response.data.people;

      setPeople((prevPeople) => [...prevPeople, ...newPeople]);
      setHasMorePeople(newPeople.length === 10);
      setOffsetPeople((prevOffset) => prevOffset + 10);
    } catch (error) {
      message.error("Failed to load people.");
      console.error("Error loading people:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      if (activeTab === "following" && posts.length === 0) {
        fetchFollowingPosts();
      } else if (activeTab === "recommended" && recommendedPosts.length === 0) {
        fetchRecommendedPosts();
      } else if (activeTab === "people" && people.length === 0) {
        fetchPopularPeople();
      }
    }
  }, [activeTab, userData]);

  const handleTabChange = (key: string) => {
    sessionStorage.setItem("activeTab", key);
    if (key !== activeTab) {
      setActiveTab(key);
    }
  };

  const handleDelete = (postId: number) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    setRecommendedPosts((prevPosts) =>
      prevPosts.filter((post) => post.id !== postId)
    );
  };

  const handleSubscribe = async (userId: number) => {
    const action = "subscribe";
    try {
      const response = await axios.post("/api/partners/subscribe.php", {
        user_id: userId,
        action: action,
      });
      dispatch(updateBonusBalance(response.data.bonus_added));
    } catch (error) {
      console.error(`Error trying to ${action}:`, error);
    }
  };

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab={t("Following")} key="following">
        <div>
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchFollowingPosts}
            hasMore={hasMore}
            loader={<Spin size="large" />}
            endMessage={<Divider />}
          >
            <List
              dataSource={posts}
              locale={{ emptyText: loading ? ' ' : t('No data') }}
              renderItem={(post) => (
                <Post
                  key={post.id}
                  first_name={post.first_name}
                  last_name={post.last_name}
                  avatarUrl={post.avatar_url}
                  content={post.content}
                  imageUrl={post.image_url}
                  likes={post.likes}
                  comments_count={post.comments_count}
                  postId={post.id}
                  postTime={post.created_at}
                  user_id={post.user_id}
                  userId={userData!.id}
                  onDelete={handleDelete}
                  poll={post.poll}
                  liked_by_user={post.liked_by_user}
                  view_count={post.view_count}
                  is_premium_user={post.is_premium_user}
                />
              )}
            />
          </InfiniteScroll>
        </div>
      </TabPane>
      <TabPane tab={t("Recommended")} key="recommended">
        <div>
          <InfiniteScroll
            dataLength={recommendedPosts.length}
            next={fetchRecommendedPosts}
            hasMore={hasMoreRecommended}
            loader={<Spin size="large" />}
            endMessage={<Divider />}
          >
            <List
              dataSource={recommendedPosts}
              locale={{ emptyText: loading ? ' ' : t('No data') }}
              renderItem={(post) => (
                <Post
                  key={post.id}
                  first_name={post.first_name}
                  last_name={post.last_name}
                  avatarUrl={post.avatar_url}
                  content={post.content}
                  imageUrl={post.image_url}
                  likes={post.likes}
                  comments_count={post.comments_count}
                  postId={post.id}
                  postTime={post.created_at}
                  user_id={post.user_id}
                  userId={userData!.id}
                  onDelete={handleDelete}
                  poll={post.poll}
                  liked_by_user={post.liked_by_user}
                  view_count={post.view_count}
                  is_premium_user={post.is_premium_user}
                />
              )}
            />
          </InfiniteScroll>
        </div>
      </TabPane>
      <TabPane tab={t("People")} key="people">
        <div>
          <InfiniteScroll
            dataLength={people.length}
            next={fetchPopularPeople}
            hasMore={hasMorePeople}
            loader={<Spin size="large" />}
            endMessage={<Divider />}
          >
            <List
              dataSource={people}
              locale={{ emptyText: loading ? ' ' : t('No data') }}
              renderItem={(person) => (
                <List.Item
                  key={person.user_id}
                  actions={[
                    person.is_subscribed === 0 && (
                      <Button
                        type="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSubscribe(person.user_id);
                          setPeople((prevPeople) =>
                            prevPeople.map((p) =>
                              p.user_id === person.user_id
                                ? {
                                    ...p,
                                    is_subscribed: 1,
                                    follower_count: p.follower_count + 1,
                                  }
                                : p
                            )
                          );
                        }}
                      >
                        {t("Subscribe")}
                      </Button>
                    ),
                  ]}
                  onClick={() => navigate(`/partners/${person.user_id}`)}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={person.avatar_url}
                        shape="square"
                        size="large"
                        icon={<UserOutlined />}
                      />
                    }
                    title={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {`${person.first_name} ${person.last_name}`}
                      </div>
                    }
                    description={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${t("Followers")}: ${formatViewCount(
                          person.follower_count
                        )}`}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default FollowingFeed;
