import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import {
  Typography,
  Spin,
  Row,
  Button,
  InputNumber,
  message,
  Alert,
  Tag,
  Modal,
  Flex,
  Checkbox,
  Space,
} from "antd";
import {
  fetchLoanDetails,
  changeLoanRate,
  fetchSellLoans,
  fetchRemoveSellLoans,
  returnDemandLoan,
  confirmSellerPayment,
  reportPaymentIssue,
} from "../helpers/api";
import { getStatusColor } from "../helpers/functions";
import LoanCertificate from "../components/loanCertificate";
import { RootState } from "../services/store";
import { LoanDetails } from "../types";
import LoanProgress from "../components/LoanProgress";
import { MainButton } from "@twa-dev/sdk/react";

const LoanPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userData } = useSelector((state: RootState) => state.user);
  const { loanId } = useParams<{ loanId: string }>();
  const [loanDetails, setLoanDetails] = useState<LoanDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newRate, setNewRate] = useState<number | null>(null);
  const [rate, setRate] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isReporting, setIsReporting] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [confirmChecked, setConfirmChecked] = useState<boolean>(false);
  const [reportReason, setReportReason] = useState<string>("");

  const loadLoanDetails = async () => {
    try {
      setLoading(true);
      const loanIdNumber = Number(loanId);
      if (isNaN(loanIdNumber)) {
        throw new Error(t("Invalid loan ID"));
      }
      const data = await fetchLoanDetails(loanIdNumber);
      setLoanDetails(data);
      setRate(data.rate);
    } catch (err) {
      setError(t("Failed to fetch loan details"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLoanDetails();
  }, []);

  const handleRateChange = () => {
    if (!loanDetails || newRate === null) return;
    const loanIdNumber = Number(loanId);
    changeLoanRate(loanIdNumber, newRate)
      .then((response) => {
        if (response.success) {
          setRate(newRate);
          message.success(t("Success"));
        } else {
          message.error(t("Error"));
          console.error("Error changing loan rate:", response.error);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const infoFormatterBonus = (value: number) => {
    if (value === 1) {
      return <Tag color="green">{t("bonus")}</Tag>;
    } else if (value === 2) {
      return <Tag color="cyan">{t("partner")}</Tag>;
    } else {
      return <Tag color="blue">{t("personal")}</Tag>;
    }
  };

  const handleSellLoan = async () => {
    setConfirmLoading(true);
    const loanIdNumber = Number(loanId);
    await fetchSellLoans(loanIdNumber, price)
      .then((response) => {
        if (response.success) {
          message.success(t("Success"));
          setConfirmLoading(false);
        } else {
          message.error(t("Error"));
          setConfirmLoading(false);
        }
      })
      .catch((error) => message.error(t("Error")));

    setConfirmLoading(false);
    await loadLoanDetails();
    setOpenModal(false);
    setOpenPriceModal(false);
  };

  const handleRemoveSellLoan = async () => {
    const loanIdNumber = Number(loanId);
    await fetchRemoveSellLoans(loanIdNumber);
    await loadLoanDetails();
    message.success(t("Success"));
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const handleCancel = () => {
    setPrice(null);
    setOpenModal(false);
  };

  const showPriceModal = () => {
    setOpenPriceModal(true);
  };

  const handlePriceCancel = () => {
    setPrice(null);
    setOpenPriceModal(false);
  };

  const handleReturnLoan = async () => {
    try {
      const loanIdNumber = Number(loanId);
      const response = await returnDemandLoan(loanIdNumber);
      if (response.success) {
        message.success(t("loan_return_success"));
        await loadLoanDetails();
      } else {
        message.error(t("Error"));
      }
    } catch (error) {
      message.error(t("Error"));
    }
  };

  const handleConfirmPayment = async () => {
    if (!loanDetails) return;
    setShowConfirmModal(true);
  };

  const handleReportPayment = async () => {
    if (!loanDetails) return;
    setShowReportModal(true);
  };

  const handleConfirmModalOk = async () => {
    if (!confirmChecked) {
      message.error(t("Please confirm that you received the payment"));
      return;
    }

    try {
      setIsConfirming(true);
      setShowConfirmModal(false);
      await confirmSellerPayment(loanDetails!.loan_id);
      message.success(t("Payment confirmed!"));
      navigate("/loans");
    } catch (error) {
      message.error(t("Error confirming payment"));
      console.error("Error confirming payment:", error);
    } finally {
      setIsConfirming(false);
    }
  };

  const handleConfirmModalCancel = () => {
    setShowConfirmModal(false);
    setConfirmChecked(false);
  };

  const handleReportModalOk = async () => {
    if (!loanDetails) return;

    try {
      setIsReporting(true);
      setShowReportModal(false);
      await reportPaymentIssue(loanDetails.loan_id, reportReason);
      message.success(t("Report sent to administrators for review"));
      navigate("/loans");
    } catch (error) {
      message.error(t("Error reporting payment issue"));
      console.error("Error reporting payment issue:", error);
    } finally {
      setIsReporting(false);
      setReportReason("");
    }
  };

  const handleReportModalCancel = () => {
    setShowReportModal(false);
    setReportReason("");
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (!loanDetails || !userData) {
    return <Alert message={t("Loan not found")} type="warning" />;
  }

  return (
    <div>
      <Typography.Title level={4}>
        {t("Loan Details")} №{loanId}
      </Typography.Title>
      {!Boolean(loanDetails.demand) && loanDetails.expires_at && (
        <LoanProgress
          created_at={loanDetails.created_at}
          expires_at={loanDetails.expires_at}
          status={loanDetails.status}
        />
      )}
      <div className="balance_item">
        <span>{t("Status")}</span>
        <span className="balance_item_price">
          {
            <Tag color={getStatusColor(loanDetails.status)}>
              {t(`loan_status.${loanDetails.status}`)}
            </Tag>
          }
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Type")}</span>
        <span className="balance_item_price">
          {infoFormatterBonus(loanDetails.used_bonus)}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Amount")}</span>
        <span className="balance_item_price">{loanDetails.amount}</span>
      </div>
      {loanDetails.margin > 0 && (
        <div className="balance_item">
          <span>{t("The amount without a margin loan")}</span>
          <span className="balance_item_price">{loanDetails.fix_amount}</span>
        </div>
      )}
      {loanDetails.currency !== "" && (
        <div className="balance_item">
          <span>{t("Currency")}</span>
          <span className="balance_item_price">{loanDetails.currency}</span>
        </div>
      )}
      <div className="balance_item">
        <span>{t("Term")}</span>
        <span className="balance_item_price">
          {loanDetails.demand ? (
            <Tag color="blue">{t("Overnight on demand")}</Tag>
          ) : (
            `${loanDetails.term} ${t("days")}`
          )}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Rate")}</span>
        <span className="balance_item_price">{rate}%</span>
      </div>
      <div className="balance_item">
        <span>{t("Insurance")}</span>
        <span className="balance_item_price">
          {loanDetails.insurance ? t("Yes") : t("No")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Profit")}</span>
        <span className="balance_item_price">{loanDetails.profit}</span>
      </div>
      <div className="balance_item">
        <span>{t("Created")}</span>
        <span className="balance_item_price">
          {moment(loanDetails.created_at)
            .locale(userData.language_code || "en")
            .format("D MMM YYYY")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Expires")}</span>
        <span className="balance_item_price">
          {loanDetails.demand ? (
            <Tag color="blue">{t("Overnight on demand")}</Tag>
          ) : (
            moment(loanDetails.expires_at)
              .locale(userData.language_code || "en")
              .format("D MMM YYYY")
          )}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("To be refunded")}</span>
        <span className="balance_item_price">
          {loanDetails.total_repayment}
        </span>
      </div>
      {loanDetails.margin > 0 && (
        <Alert
          message={`${t("A margin loan has been used")} ${loanDetails.margin}%`}
          type="success"
          showIcon
        />
      )}
      {(loanDetails.status === "active" ||
        loanDetails.status === "completed" ||
        loanDetails.status === "for_sale") && (
        <LoanCertificate
          showName={true}
          loanNumber={loanDetails.loan_id.toString()}
          loanAmount={loanDetails.amount}
          loanEndDate={
            loanDetails.expires_at
              ? loanDetails.expires_at.substring(0, 10)
              : ""
          }
          loanDate={
            loanDetails.active_at
              ? loanDetails.active_at.substring(0, 10)
              : loanDetails.created_at.substring(0, 10)
          }
        />
      )}
      {loanDetails.pledged && (
        <Alert
          message={t("This certificate is pledged")}
          type="warning"
          showIcon
        />
      )}
      {loanDetails.status === "pending" && (
        <>
          <Row justify="space-evenly" style={{ margin: 20 }} align="middle">
            <Typography.Text>{t("New rate")}:</Typography.Text>
            <InputNumber
              min={0}
              max={100}
              value={newRate}
              onChange={setNewRate}
              decimalSeparator="."
              formatter={(value) => {
                if (value === undefined) return "";
                return `${value}`.replace(",", ".");
              }}
              parser={(value) => {
                if (value === undefined) return 0;
                return parseFloat(value.replace(",", "."));
              }}
            />
          </Row>
          <Button key="changeRate" type="primary" onClick={handleRateChange}>
            {t("Change Rate")}
          </Button>
        </>
      )}
      {loanDetails.status === "active" &&
        !loanDetails.used_bonus &&
        !loanDetails.demand &&
        loanDetails.term !== 1 &&
        !loanDetails.pledged && (
          <Flex gap="small">
            {!loanDetails.blocked && (
              <Button
                key="sellLoan"
                color="danger"
                variant="solid"
                onClick={showModal}
                block
              >
                {t("Quick sale")}
              </Button>
            )}
            <Button
              key="sellLoanPrice"
              color="primary"
              variant="outlined"
              onClick={showPriceModal}
              block
            >
              {t("Sell")}
            </Button>
          </Flex>
        )}
      {loanDetails.status === "for_sale" && (
        <Button
          key="removeSellLoan"
          color="danger"
          variant="outlined"
          onClick={handleRemoveSellLoan}
          block
        >
          {t("Remove the certificate from sale")}
        </Button>
      )}
      {Boolean(loanDetails.demand) && loanDetails.status === "active" && (
        <Button
          type="primary"
          block
          style={{ marginTop: 16 }}
          onClick={handleReturnLoan}
        >
          {t("Return back")}
        </Button>
      )}
      {loanDetails.status === "waiting_confirmation" &&
        userData.id === loanDetails.user_id && (
          <Space direction="vertical" style={{ width: "100%", marginTop: 16 }}>
            <MainButton
              text={t("Confirm payment received")}
              onClick={handleConfirmPayment}
              progress={isConfirming}
            />
            <Button
              type="default"
              danger
              style={{ width: "100%" }}
              onClick={handleReportPayment}
              loading={isReporting}
            >
              {t("I haven't received the payment")}
            </Button>
          </Space>
        )}

      <Modal
        title={t("Sell loan")}
        open={openModal}
        onOk={handleSellLoan}
        okText={t("Confirm")}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
      >
        <p>{t("sell_loan_modal_massage")}</p>
      </Modal>

      <Modal
        title={t("Sell loan")}
        open={openPriceModal}
        onOk={handleSellLoan}
        okText={t("Confirm")}
        confirmLoading={confirmLoading}
        onCancel={handlePriceCancel}
        cancelText={t("Cancel")}
      >
        {" "}
        <Row justify="space-evenly" style={{ margin: 20 }} align="middle">
          <Typography.Text>{t("Input Price")}:</Typography.Text>
          <InputNumber
            min={1}
            value={price}
            onChange={setPrice}
            decimalSeparator="."
            formatter={(value) => {
              if (value === undefined) return "";
              return `${value}`.replace(",", ".");
            }}
            parser={(value) => {
              if (value === undefined) return 0;
              return parseFloat(value.replace(",", "."));
            }}
          />
        </Row>
      </Modal>

      <Modal
        title={t("Confirm payment")}
        open={showConfirmModal}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
        okText={t("Confirm")}
        cancelText={t("Cancel")}
        okButtonProps={{ disabled: !confirmChecked }}
      >
        <div style={{ marginBottom: 20 }}>
          <p>
            {t(
              "Please confirm that you have received the payment in full amount."
            )}
          </p>
          <Checkbox
            checked={confirmChecked}
            onChange={(e) => setConfirmChecked(e.target.checked)}
          >
            {t("I confirm that I have received the full payment amount")}
          </Checkbox>
        </div>
      </Modal>

      <Modal
        title={t("Report payment issue")}
        open={showReportModal}
        onOk={handleReportModalOk}
        onCancel={handleReportModalCancel}
        okText={t("Send report")}
        cancelText={t("Cancel")}
      >
        <div style={{ marginBottom: 20 }}>
          <p>
            {t(
              "Please explain why you believe you haven't received the payment:"
            )}
          </p>
          <textarea
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
            style={{
              width: "100%",
              minHeight: "100px",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
            }}
            placeholder={t("Provide details about the issue...")}
          />
          <p style={{ marginTop: 16, fontSize: "13px", color: "#888" }}>
            {t(
              "Your report will be reviewed by administrators who will verify the transaction."
            )}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default LoanPage;
