import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Collapse } from "antd";

const { Title } = Typography;
const { Panel } = Collapse;

const FAQ: FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      label: t("FAQ_Q_1"),
      children: (
        <div>
          <p>{t("FAQ_A_1_Intro")}</p>
          <ol>
            <li>{t("FAQ_A_1_Step_1")}</li>
            <li>{t("FAQ_A_1_Step_2")}</li>
            <li>{t("FAQ_A_1_Step_3")}</li>
          </ol>
          <p>{t("FAQ_A_1_End")}</p>
        </div>
      ),
    },
    {
      key: "2",
      label: t("FAQ_Q_2"),
      children: (
        <div>
          <p>{t("FAQ_A_2_Intro")}</p>
          <ol>
            <li>{t("FAQ_A_2_Step_1")}</li>
            <li>{t("FAQ_A_2_Step_2")}</li>
            <li>{t("FAQ_A_2_Step_3")}</li>
          </ol>
        </div>
      ),
    },
    {
      key: "3",
      label: t("FAQ_Q_3"),
      children: (
        <div>
          <p>{t("FAQ_A_3_Intro")}</p>
          <ol>
            <li>{t("FAQ_A_3_Step_1")}</li>
            <li>{t("FAQ_A_3_Step_2")}</li>
            <li>{t("FAQ_A_3_Step_3")}</li>
          </ol>
          <p>{t("FAQ_A_3_End")}</p>
        </div>
      ),
    },
    {
      key: "4",
      label: t("FAQ_Q_4"),
      children: (
        <div>
          <p>{t("FAQ_A_4_Intro")}</p>
          <ol>
            <li>{t("FAQ_A_4_Step_1")}</li>
            <li>{t("FAQ_A_4_Step_2")}</li>
            <li>{t("FAQ_A_4_Step_3")}</li>
          </ol>
        </div>
      ),
    },
    {
      key: "5",
      label: t("FAQ_Q_5"),
      children: (
        <div>
          <p>{t("FAQ_A_5_Intro")}</p>
          <p>{t("FAQ_A_5_Detail_1")}</p>
          <p>{t("FAQ_A_5_Detail_2")}</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Title level={3} style={{ textAlign: 'center', marginBottom: 24 }}>
        {t("FAQ_Title")}
      </Title>
      <Collapse accordion>
        {items.map(item => (
          <Panel 
            header={item.label} 
            key={item.key} 
            style={{ borderRadius: 8, marginBottom: 8 }}
            className="faq-panel"
          >
            {item.children}
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default FAQ;



